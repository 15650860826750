import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetInternalPagesByType } from "../../services/internal.service";
import { getLocalStorageItems } from "../../utils/functions/functionsUtils";
import { useEffect, useState } from "react";
import { LoadPanel, ScrollView } from "devextreme-react";
import React from "react";

const PageTitle = styled.h1`
  font-size: 25px;
  margin-top: 15px;
`;

const MainContainer = styled.div`
  padding-top: 20px;
  width: 100%;
`;

const SpanText = styled.span`
  font-size: x-large;
`;

function GeneralConditions() {
  //Translation module
  const { t, i18n } = useTranslation();
  const [langChanged, setlangChanged] = useState(false);
  //*******************
  const [internalPage, setInternalPage] = useState<string>("");
  const [isLoading, setisLoading] = useState(false);

  //*******************
  const i18nextLng = getLocalStorageItems("i18nextLng", false);

  const GetInternalPageData = () => {
    setisLoading(true);

    GetInternalPagesByType("conditions")
      .then((response) => {
        setisLoading(false);
        setlangChanged(false);

        const currentLangVAlues = response.find((x) => {
          if (i18nextLng === "GB" && x.language === "EN") {
            return x;
          } else {
            return x.language === i18nextLng;
          }
        })?.content;

        if (currentLangVAlues) {
          setInternalPage(currentLangVAlues);
        }
      })
      .catch(() => {
        setisLoading(false);
        setlangChanged(false);
      });
  };

  useEffect(() => {
    GetInternalPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", () => {
      setlangChanged(true);
    });
    if (langChanged) GetInternalPageData();

    return () => {
      i18n.off("languageChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langChanged]);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <MainContainer className="wysiwyg">
        <Container>
          <Row>
            <Col>
              <PageTitle>{t("GeneralCondition")}</PageTitle>
              <SpanText>
                <div
                  dangerouslySetInnerHTML={{
                    __html: internalPage,
                  }}
                ></div>
              </SpanText>
            </Col>
          </Row>
        </Container>
        <LoadPanel
          visible={isLoading}
          showIndicator={true}
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          showPane={false}
          position={{ of: "#main-container-wrapper" }}
          message={t("LoadingData")!}
        />
      </MainContainer>
    </ScrollView>
  );
}

export default GeneralConditions;
