import styled from "styled-components";
import CategoriesTreeList from "../../components/CategoriesTreeList";
import React from "react";

const AgreementContainer = styled.div``;

function FrontEndAgreement() {
  return (
    <AgreementContainer>
      <CategoriesTreeList categoryCode={"agreements"} />
    </AgreementContainer>
  );
}
export default FrontEndAgreement;
