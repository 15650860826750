import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import variables from "../../utils/constants/variables.json";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import miniLogo from "../../assets/csl-header-elections.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate, Link } from "react-router-dom";
import ScreenTitle from "../../components/ScreenTitle";
import ReactFlagsSelect from "react-flags-select";
import { LanguageContext } from "../../utils/context/LanguageContext";
import { useTranslation } from "react-i18next";
import { StyledHeaderLink } from "../../utils/style/Atom";

import {
  isInternalPage,
  getTranslationInternalPages,
  getTranslationPages,
  loggedUser,
  getMenuItems,
  isUserAdmin,
  isUserLoggedIn,
} from "../../utils/functions/functionsUtils";
import { ThemeSelector } from "../ThemeSwitcher";
import SelectBox from "devextreme-react/select-box";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { TextBox } from "devextreme-react";
import { useTheme } from "../../utils/context/ThemeContext";
import { Button, Modal } from "react-bootstrap";
const DropDownOptions = { wrapperAttr: { class: "selectboxDropdown" } };

const HeaderContainer = styled.header`
  width: 100%;
  border-bottom: 2px solid;
  border-color: black;
  background-color: #141b4d;
`;

const HeaderLogo = styled.img`
  margin: 7px;
  height: 62px;
`;

const Avatar = styled.img`
  height: 50px;
  border-radius: 50%;
`;

const SpanDropdown = styled.span`
  color: white;
`;

const DivUser = styled.div`
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
`;
const CustomActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DivTitle = styled.div`
  display: flex;
  justify-content: center;
`;

const DivLinks = styled.div`
  padding-right: 15px;
  align-items: center;
  display: flex;
`;

const SpanUserRole = styled.span`
  color: white;
`;
const SpanNotLoggedIn = styled.span`
  color: white;
  font-style: italic;
`;

const Selector = styled(ReactFlagsSelect)<{ location: string }>`
  padding-left: ${(prop) =>
    prop.location === "/" ||
    prop.location === "/signUp" ||
    prop.location === "/accessDenied"
      ? "0%"
      : "0%"};
  transition: all 0.3s ease-in-out;

  ul > li:hover {
    background-color: #fff;

    * {
      background-color: #fff;
      color: #141b4d;
    }
  }
`;

function HeaderBar() {
  //States
  const [titleItem, settitleItem] = useState("");
  const [isModalThemeOpen, setisModalThemeOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  //*******************
  const { setTheme } = useTheme();

  //User context
  const fakeAvatar = variables.fake_avatar;
  let user = loggedUser();
  //*******************

  //Lang context
  const { userLang, updateUserLang } = useContext(LanguageContext);
  //*******************

  const [fontSizes] = useState<{ label: string; value: number }[]>([
    { label: "12", value: 12 },
    { label: "16", value: 16 },
    { label: "20", value: 20 },
    { label: "24", value: 24 },
  ]);

  const [fontFamilies] = useState<{ label: string; value: string }[]>([
    { label: "Trebuchet MS", value: "Trebuchet MS" },
    { label: "Helvetica", value: "Helvetica" },
    { label: "Dyslexia", value: "Dyslexia" },
  ]);

  const [activeFontSize, setactiveFontSize] = useState<number | undefined>(
    Number(window.localStorage["themeFont"]) ?? 16
  );

  const [activeFontFamily, setactiveFontFamily] = useState<string | undefined>(
    window.localStorage["themeFamily"] ?? "Trebuchet MS"
  );

  //Variables
  const location = useLocation();
  const menuItems = getMenuItems();
  const navigate = useNavigate();

  //Notifications
  if (Object.keys(menuItems).length < 0) {
    //condition should be < 1
    toast.error(t("NoMenuItems"), {
      position: "top-right",
    });
  }
  //*******************
  if (!isInternalPage(location.pathname)) {
    if (Object.keys(menuItems).length === 0) {
      localStorage.clear();
      navigate("/");
    }
  }

  const onFontChanged = (font: ValueChangedEvent) => {
    window.localStorage["themeFont"] = font.value;
    setactiveFontSize(Number(font.value));
  };

  const onFontFamilyChanged = (font: ValueChangedEvent) => {
    window.localStorage["themeFamily"] = font.value;
    setactiveFontFamily(font.value);
  };

  useEffect(() => {
    updateUserLang(userLang);
    i18n.changeLanguage(userLang);

    if (isInternalPage(location.pathname)) {
      settitleItem(getTranslationInternalPages(location.pathname, t));
    } else {
      settitleItem(getTranslationPages(location.pathname, t));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userLang]);

  useEffect(() => {
    const root = document.documentElement;

    if (activeFontSize)
      root.style.setProperty("--base-font-size", activeFontSize + "px");
    if (activeFontSize !== 12) {
      root.style.setProperty("--base-content-margin", 0 + "px");
    } else {
      root.style.setProperty("--base-content-margin", 50 + "px");
    }
  }, [activeFontSize]);

  useEffect(() => {
    const root = document.documentElement;

    if (activeFontFamily) {
      root.style.setProperty("--base-font-family", activeFontFamily);
    }
  }, [activeFontFamily]);

  const logout = () => {
    localStorage.clear();
    user = null;
    toast.success(t("LogoutSuccess"), {
      position: "top-right",
    });
    setTheme("light");
    window.localStorage["themeFont"] = 16;
    setactiveFontSize(16);
    window.localStorage["themeFamily"] = "Trebuchet MS";
    setactiveFontFamily("Trebuchet MS");
    navigate("/", { replace: true });
  };

  const onSelect = (code: string): void => {
    updateUserLang(code);
    i18n.changeLanguage(code);
  };

  const redirectLogo = (): string => {
    if (isUserLoggedIn()) {
      if (loggedUser().role === "Administrator") {
        return "/dashboard";
      } else {
        return "/services";
      }
    } else {
      return "/";
    }
  };

  return (
    <>
      {["md"].map((expand: any) => (
        <HeaderContainer key={`header-${expand}`}>
          <Navbar expand={expand} className="p-0">
            <Container fluid>
              <CustomActions>
                <Link to={redirectLogo()} style={{ cursor: "pointer" }}>
                  <HeaderLogo
                    src={miniLogo}
                    alt={t("headerLogo")!}
                    aria-label={t("headerLogo")!}
                    title={t("headerLogo")!}
                    role="img"
                  />
                </Link>
                {isUserAdmin() && (
                  <Button
                    variant="primary"
                    aria-description={t("themeConfigurator")!}
                    aria-label={t("themeConfigurator")!}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 15,
                    }}
                    onClick={() => {
                      setisModalThemeOpen(true);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "palette"]}
                      style={{ color: "#141B4D", marginRight: 5 }}
                    />
                    <div>{t("themeConfigurator")}</div>
                  </Button>
                )}
              </CustomActions>
              <div className="d-flex flex-row">
                <DivTitle>
                  <ScreenTitle title={titleItem} />
                </DivTitle>
              </div>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />

              <DivTitle>
                {!localStorage.getItem("user") ? (
                  <Navbar.Offcanvas id="not_logged_in" placement="end">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title
                        id={`offcanvasNavbarLabel-expand-${expand}`}
                      >
                        Menu
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Avatar src={fakeAvatar} />
                        <DivUser>
                          <Row>
                            <Col className="pt-3">
                              <SpanNotLoggedIn>
                                {t("NotLoggedIn")}
                              </SpanNotLoggedIn>
                            </Col>
                          </Row>
                        </DivUser>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                ) : (
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    style={{ color: "white" }}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title
                        id={`offcanvasNavbarLabel-expand-${expand}`}
                      >
                        User Data
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="justify-content-end flex-grow-1 pe-3">
                        <DivLinks>
                          <StyledHeaderLink to="/aboutProject">
                            {t("AboutProject")}
                          </StyledHeaderLink>
                          <StyledHeaderLink to="/generalConditions">
                            {t("GeneralCondition")}
                          </StyledHeaderLink>
                          <StyledHeaderLink to="/gdpr">
                            {t("RGPD")}
                          </StyledHeaderLink>
                        </DivLinks>
                        <Avatar
                          src={user?.avatar}
                          alt={t("headerLogo")!}
                          aria-label={t("headerLogo")!}
                          title={t("headerLogo")!}
                          role="img"
                        />
                        <DivUser>
                          <Row>
                            <Col>
                              <NavDropdown
                                title={
                                  <SpanDropdown>
                                    {user?.firstName} {user?.name}
                                  </SpanDropdown>
                                }
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                align="end"
                              >
                                <NavDropdown.Item
                                  href="/myprofile"
                                  style={{ fontSize: "smaller" }}
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "user-gear"]}
                                    className="pr-3"
                                    style={{ color: "#141B4D" }}
                                  />{" "}
                                  {t("MyProfile")}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                  onClick={logout}
                                  style={{ fontSize: "smaller" }}
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "arrow-right-from-bracket"]}
                                    className="pr-3"
                                    style={{ color: "#141B4D" }}
                                  />{" "}
                                  {t("Logout")}
                                </NavDropdown.Item>
                              </NavDropdown>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <SpanUserRole>{user?.role}</SpanUserRole>
                            </Col>
                          </Row>
                        </DivUser>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                )}
                <CustomActions>
                  <Selector
                    selected={userLang}
                    onSelect={onSelect}
                    countries={["FR", "GB", "DE"]}
                    customLabels={{ GB: "EN", FR: "FR", DE: "DE" }}
                    showSelectedLabel={true}
                    showOptionLabel={true}
                    fullWidth={false}
                    location={location.pathname}
                    className="react-flags-header"
                  />
                </CustomActions>

                <Modal
                  show={isModalThemeOpen}
                  onEscapeKeyDown={() => {
                    setisModalThemeOpen(false);
                  }}
                  animation={true}
                  backdrop
                >
                  <Modal.Header>
                    <Modal.Title>{t("themeConfigurator")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className={"theme-selector"}>
                      <ThemeSelector />
                      <SelectBox
                        className={"theme-selector-item"}
                        items={fontSizes}
                        defaultValue={Number(activeFontSize)}
                        onValueChanged={onFontChanged}
                        stylingMode="outlined"
                        displayExpr="label"
                        valueExpr="value"
                        deferRendering={false}
                        label={t("fontSize")!}
                        // @ts-ignore
                        labelMode="outside"
                        fieldRender={(data: { label: string }) => (
                          <div className={"custom-item"}>
                            <TextBox
                              style={{ padding: 10 }}
                              inputAttr={{
                                class: "dx-theme-text-color theme-textbox",
                              }}
                              value={data?.label}
                              readOnly={true}
                            />
                          </div>
                        )}
                        itemRender={(data: { label: string }) => (
                          <div className={"custom-item"}>
                            <div className={"theme-text"}>{data?.label}</div>
                          </div>
                        )}
                        dropDownOptions={DropDownOptions}
                      ></SelectBox>
                      <SelectBox
                        className={"theme-selector-item"}
                        items={fontFamilies}
                        defaultValue={activeFontFamily}
                        onValueChanged={onFontFamilyChanged}
                        stylingMode="outlined"
                        displayExpr="label"
                        valueExpr="value"
                        label={t("fontFamily")!}
                        // @ts-ignore
                        labelMode="outside"
                        deferRendering={false}
                        fieldRender={(data: { label: string }) => (
                          <div className={"custom-item"}>
                            <TextBox
                              style={{ padding: 10 }}
                              inputAttr={{
                                class: "dx-theme-text-color theme-textbox",
                              }}
                              value={data?.label}
                              readOnly={true}
                            />
                          </div>
                        )}
                        itemRender={(data: { label: string }) => (
                          <div className={"custom-item"}>
                            <div className={"theme-text"}>{data?.label}</div>
                          </div>
                        )}
                        dropDownOptions={DropDownOptions}
                      ></SelectBox>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setisModalThemeOpen(false);
                      }}
                    >
                      {t("close")}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </DivTitle>
            </Container>
          </Navbar>
        </HeaderContainer>
      ))}
    </>
  );
}

export default HeaderBar;
