import React from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";

const CardContainer = styled.div``;
const CslCard = styled(Card)`
  height: 200px;
  transition: all 0.3s ease-in-out;
  @media (min-width: 1025px) {
    height: 180px;
  }
  &:hover {
    box-shadow: 8px 8px 8px light-gray;
    transform: scale(1.1);
  }
`;
const CategoryImage = styled.img`
  padding: 3px;
  height: 60px;
  @media (min-width: 1025px) {
    height: 60px;
  }
`;

const SpanCategory = styled.span`
  padding: 10px;
  font-size: 25px;
  color: white;
  @media (min-width: 1025px) {
    font-size: 20px;
  }
`;

const LinkItem = styled.a`
  color: white;
  text-decoration: none;
`;

function ServicesCard(props: any) {
  const cardBackground = {
    background: props.color,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  };
  return (
    <CardContainer className="p-3">
      <CslCard>
        <Card.Body style={cardBackground}>
          <LinkItem href={props.href} className="stretched-link">
            <CategoryImage src={props.base64Image} />
            <SpanCategory>{props.title}</SpanCategory>
          </LinkItem>
        </Card.Body>
      </CslCard>
    </CardContainer>
  );
}
export default ServicesCard;
