import "./App.css";
import Home from "./pages/Home";
import GlobalStyle from "./utils/style/GlobalStyle";
import Header from "./components/Header";
import CategoryDetails from "./components/CategoryDetails";
import MyProfile from "./pages/MyProfile";
import UserManagement from "./pages/UserManagement";
import SubscriptionRequest from "./pages/SubscriptionRequest";
import SubscriptionsDeletion from "./pages/SubscriptionsDeletion";
import SubscriptionsModification from "./pages/SubscriptionsModification";
import Dashboard from "./pages/Dashboard";
import BackEndAgreements from "./pages/BackEndAgreements";
import BackEndJurisprudence from "./pages/BackEndJurisprudence";
import BackEndLegislation from "./pages/BackEndLegislation";
import BackEndModels from "./pages/BackEndModels";
import BackEndObligation from "./pages/BackEndObligation";
import BackEndPublication from "./pages/BackEndPublication";
import FrontEndJurisprudence from "./pages/FrontEndJurisprudence";
import FrontEndAllCategories from "./pages/FrontEndAllCategories";
import FrontEndObligation from "./pages/FrontEndObligations";
import FrontEndAgreement from "./pages/FrontEndAgreement";
import FrontEndModel from "./pages/FrontEndModel";
import FrontEndPublication from "./pages/FrontEndPublication";
import FrontEndLegislation from "./pages/FrontEndLegislation";
import Categories from "./pages/Categories";
import AccessDenied from "./pages/AccessDenied";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import RequestResetPassword from "./pages/RequestResetPassword";
import Services from "./pages/Services";
import GeneralConditions from "./pages/GeneralConditions";
import Gdpr from "./pages/GDPR";
import PageNotFound from "./pages/NotFound";
import AdminScreen from "./pages/AdminScreen";
import AboutThisProject from "./pages/AboutThisProject";
import UserAuthProvider from "./utils/context/AuthContext";
import LanguageProvider from "./utils/context/LanguageContext";
import { AxiosInterceptor } from "./components/shared/jwtInterceptor";
import { Route, Routes, useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import "./assets/fonts/OpenDyslexic3-Bold.ttf";
import "./assets/fonts/OpenDyslexic3-Regular.ttf";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./components/Sidebar";
import { GuardedRoute } from "./utils/hooks/guardedRoute";
import { useState, useLayoutEffect, useEffect } from "react";
import {
  setColMenuClass,
  setColContainerClass,
} from "./utils/functions/functionsUtils";
import { Row, Col } from "react-bootstrap";
import DocumentStatusGrid from "./components/DocumentStatusGrid";
import { Internal } from "./pages/Internal";
import { ThemeProvider } from "./utils/context/ThemeContext";
import { useTheme } from "./utils/context/ThemeContext";
import React from "react";
const App = () => {
  const [isFullScreen, setFullScreen] = useState(true);
  const location = useLocation();
  const handleFullScreen = (currentState: boolean) => {
    setFullScreen(currentState);
  };
  const { setTheme, getTheme } = useTheme();

  useEffect(() => {
    setTheme(getTheme());
  }, [setTheme, getTheme]);

  useLayoutEffect(() => {
    //Code only to force a refresh on app.tsx and get the current css for menus and container
  }, [location]);

  library.add(fas, far);
  return (
    <>
      <UserAuthProvider>
        <ThemeProvider>
          <LanguageProvider>
            <AxiosInterceptor>
              <GlobalStyle />
              <Header />
              <div className="sidebar-wrap" id="main-container-wrapper">
                <div>
                  <Row>
                    <Col className={setColMenuClass(isFullScreen)}>
                      <nav role="navigation" aria-label="menu navigation">
                        <Sidebar handleFullScreen={handleFullScreen} />
                      </nav>
                    </Col>
                    <Col className={setColContainerClass(isFullScreen)}>
                      <main>
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="*" element={<PageNotFound />} />
                          <Route
                            path="/accessDenied"
                            element={<AccessDenied />}
                          />
                          <Route path="/signUp" element={<SignUp />} />
                          <Route
                            path="/aboutProject"
                            element={<AboutThisProject />}
                          />
                          <Route
                            path="/generalConditions"
                            element={<GeneralConditions />}
                          />
                          <Route path="/gdpr" element={<Gdpr hasScroll />} />
                          <Route
                            path="/requestResetPassword"
                            element={<RequestResetPassword />}
                          />
                          <Route
                            path="/resetPassword/:userToken"
                            element={<ResetPassword />}
                          />
                          <Route
                            path="/userManagement"
                            element={
                              <GuardedRoute>
                                <UserManagement />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/myprofile"
                            element={
                              <GuardedRoute>
                                <MyProfile />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/dashboard"
                            element={
                              <GuardedRoute>
                                <Dashboard />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/services"
                            element={
                              <GuardedRoute>
                                <Services />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/subscriptions"
                            element={
                              <GuardedRoute>
                                <SubscriptionRequest />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/subscriptionsmodification"
                            element={
                              <GuardedRoute>
                                <SubscriptionsModification />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/subscriptionsdeletion"
                            element={
                              <GuardedRoute>
                                <SubscriptionsDeletion />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/categories"
                            element={
                              <GuardedRoute>
                                <Categories />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/internal"
                            element={
                              <GuardedRoute>
                                <Internal />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/legislations"
                            element={
                              <GuardedRoute>
                                <BackEndLegislation />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/agreements"
                            element={
                              <GuardedRoute>
                                <BackEndAgreements />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/obligations"
                            element={
                              <GuardedRoute>
                                <BackEndObligation />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/models"
                            element={
                              <GuardedRoute>
                                <BackEndModels />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/jurisprudences"
                            element={
                              <GuardedRoute>
                                <BackEndJurisprudence />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/publications"
                            element={
                              <GuardedRoute>
                                <BackEndPublication />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/categoryDetails/:categoryCode/:catDocId"
                            element={<CategoryDetails />}
                          />
                          <Route
                            path="/allCategories"
                            element={<FrontEndAllCategories />}
                          />
                          <Route
                            path="/usersjurisprudences"
                            element={
                              <GuardedRoute>
                                <FrontEndJurisprudence />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/usersobligations"
                            element={
                              <GuardedRoute>
                                <FrontEndObligation />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/userspublications"
                            element={
                              <GuardedRoute>
                                <FrontEndPublication />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/userslegislations"
                            element={
                              <GuardedRoute>
                                <FrontEndLegislation />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/usersmodels"
                            element={
                              <GuardedRoute>
                                <FrontEndModel />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/usersagreements"
                            element={
                              <GuardedRoute>
                                <FrontEndAgreement />
                              </GuardedRoute>
                            }
                          />
                          <Route
                            path="/categoryFilesStatus/:categoryCode"
                            element={<DocumentStatusGrid />}
                          />
                          <Route
                            path="/adminScreen"
                            element={
                              <GuardedRoute>
                                <AdminScreen />
                              </GuardedRoute>
                            }
                          />
                        </Routes>
                      </main>
                    </Col>
                  </Row>
                </div>
              </div>
              <ToastContainer />
            </AxiosInterceptor>
          </LanguageProvider>
        </ThemeProvider>
      </UserAuthProvider>
    </>
  );
};

export default App;
