import styled from "styled-components";
import CategoriesTreeList from "../../components/CategoriesTreeList";
import React from "react";

const LegislationContainer = styled.div``;

function FrontEndLegislation() {
  return (
    <LegislationContainer>
      <CategoriesTreeList categoryCode={"legislations"} />
    </LegislationContainer>
  );
}

export default FrontEndLegislation;
