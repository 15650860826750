import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  GroupItem,
  Item,
  Label,
  RequiredRule,
  EmailRule,
  CompareRule,
} from "devextreme-react/form";
import List from "devextreme-react/list";
import ArrayStore from "devextreme/data/array_store";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import styled from "styled-components";
import { Button as Btn, Spinner } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import variables from "../../utils/constants/variables.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import api_keys from "../../utils/constants/api_keys.json";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import Gdpr from "../../pages/GDPR";
import { RepresentativePerson } from "../../models/representativePerson";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { InitializedEvent } from "devextreme/ui/form";
import { UserInfo } from "../../models/user";
//Styled component
const Avatar = styled.img`
  height: 40px;
  margin-left: 25px;
`;
const AvatarContainer = styled.div<{ action: string }>`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const UserFormContainer = styled.div<{ width: string }>`
  justify-content: center;
  max-width: ${(prop) => prop.width};
  @media (max-width: 1500px) {
    max-width: 100%;
  }
`;

const DivFrame = styled.div`
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const AvatarDiv = styled.div`
  max-width: 300px;
`;

const DivRepresentative = styled.div`
  border-style: groove;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 10px;
`;

const FileDownloadedDiv = styled.div`
  min-width: 375px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const DivListContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;
const ButtonText = styled.span`
  padding-left: 5px;
  font-size: small;
`;

const FileLabel = styled.span`
  padding-left: 10px;
`;

const DivSelectOneOption = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SpanSelectOneOption = styled.span`
  color: red;
  padding-right: 10px;
`;

const SpanAcceptGDPR = styled.span`
  color: red;
  margin-left: 10px;
`;

const UserForm = ({
  width = "50%",
  data,
  action,
  title,
  blobFileName,
  hasGdpr = true,
}: {
  width?: string;
  data: UserInfo;
  action: string;
  title: string;
  blobFileName?: string;
  hasGdpr?: boolean;
}) => {
  //States
  const [userData, setUserData] = useState({});
  const [file] = useState();
  const [fileName] = useState();
  const [isFormFullFilled, setIsFormFullFilled] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isSharingDataAccepted, setIsSharingDataAccepted] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [popupGDPRVisible, setPopupGDPRVisible] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [selectedItemKeys, setSelectedItemKeys] = useState<string[]>([]);
  const { i18n, t } = useTranslation();
  const [langChanged, setlangChanged] = useState(false);
  const isDivHidden = action === "ro" ? true : false;
  const navigate = useNavigate();

  const listOptions = [
    {
      id: t("elected_staff_delegate"),
      text: t("elected_staff_delegate"),
    },
    {
      id: t("elected_deputy_delegate"),
      text: t("elected_deputy_delegate"),
    },
    {
      id: t("elected_csl_member"),
      text: t("elected_csl_member"),
    },
    {
      id: t("syndicat_expert"),
      text: t("syndicat_expert"),
    },
  ];

  const [dataSource, setdataSource] = useState<ArrayStore<
    {
      id: string;
      text: string;
    },
    any
  > | null>(null);
  const onFieldDataChanged = async (e: any) => {
    setUserData(e.component.option("formData"));

    let res = e.component.validate();
    if (res.brokenRules.length >= 1) {
      setIsFormFullFilled(false);
    } else {
      setIsFormFullFilled(true);
    }
  };
  //Translation module
  //*******************

  const onInitialized = (e: InitializedEvent) => {};

  const languageOptions = {
    dataSource: ["FR", "EN", "DE"],
  };

  const handleSharingData = (e: any) => {
    setIsSharingDataAccepted(e.value);
  };

  const isSharingDataOptions = {
    value: isSharingDataAccepted,
    onValueChanged: handleSharingData,
    disabled: true,
  };

  const hideGDPRPopup = () => {
    setPopupGDPRVisible(false);
    setScrollToBottom(false);
  };

  const showGDPRPopup = () => {
    setPopupGDPRVisible(true);
  };

  const notAcceptGDPR = () => {
    setIsSharingDataAccepted(false);
    hideGDPRPopup();
  };

  const acceptGDPR = () => {
    setIsSharingDataAccepted(true);
    hideGDPRPopup();
  };

  const hideInfo = useCallback(() => {
    notAcceptGDPR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acceptConditions = useCallback(() => {
    acceptGDPR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeGDPRPopupButtonOptions = useCallback(
    () => ({
      onClick: () => hideInfo(),
      text: t("PopupClose"),
      icon: "close",
      type: "danger",
      elementAttr: {
        class: "see-document-button",
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideInfo]
  );

  const acceptGDPRPopupButtonOptions = useCallback(
    () => ({
      onClick: () => acceptConditions(),
      disabled: !scrollToBottom,
      text: t("PopupGDPRAccept"),
      icon: "check",
      type: "success",
      elementAttr: {
        class: "see-document-button",
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [acceptConditions, scrollToBottom]
  );

  const backToHome = async () => {
    navigate("/");
  };

  const handleSavingState = (st: boolean) => {
    setSaving(st);
  };

  const handleDownloadingState = (st: boolean) => {
    setDownloading(st);
  };

  const showNotifications = (
    data: any,
    statusCode: number,
    showOnSuccess: boolean
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t("CreateUserEventSuccess"), {
          position: "top-right",
        });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const downloadFile = async () => {
    if (blobFileName) {
      handleDownloadingState(true);
      return jwtInterceptor
        .get(
          `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.downloadUserForm}?fileName=${blobFileName}`,
          { responseType: "blob" }
        )
        .then((response: any) => {
          handleDownloadingState(false);
          var url = window.URL.createObjectURL(response.data);
          var a = document.createElement("a");
          a.href = url;
          a.download = blobFileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }
  };

  const signUpUser = async () => {
    handleSavingState(true);
    const formData = new FormData();
    formData.append("File", file!);
    formData.append("UserInfo", JSON.stringify(userData!));
    formData.append("FileName", fileName!);
    formData.append("EventName", "Create");
    formData.append("CreatedBy", "SignUpOption");
    formData.append("RepresentativeEntity", selectedItemKeys.join(", "));

    jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.createUserEvent} `,
        formData
      )
      .then((response) => {
        showNotifications(response.data, response.status, true);
        handleSavingState(false);
        window.location.reload();
      })
      .finally(() => {
        window.location.reload();
        handleSavingState(false);
      });
  };

  const onSelectedItemKeysChange = (e: {
    removedItems: {
      id: string;
      text: string;
    }[];
    addedItems: {
      id: string;
      text: string;
    }[];
  }) => {
    let currentItems = [];

    if (e.addedItems && e.addedItems.length !== 0) {
      var newRepresenativePerson: RepresentativePerson = {
        id: e.addedItems[0].id,
        text: e.addedItems[0].text,
      };
      currentItems.push(newRepresenativePerson);
    }

    if (e.removedItems && e.removedItems.length !== 0) {
      var deleteRepresenativePerson: RepresentativePerson = {
        id: e.removedItems[0].id,
        text: e.removedItems[0].text,
      };
      const index = currentItems
        .map((e) => e.id)
        .indexOf(deleteRepresenativePerson.id);
      if (index > -1) {
        currentItems.splice(index, 1);
      }
    }

    setSelectedItemKeys(currentItems.map((e) => e.id));
  };

  const validateForm = React.useCallback((e: any) => {
    e.component.validate();
  }, []);

  const checkComparison = () => true;

  const handleScroll = (e: any) => {
    const el = document.querySelector(".gdprScroll");
    if (el) {
      const bottom = el?.scrollHeight - el?.scrollTop < el?.clientHeight + 30;
      setScrollToBottom(bottom);
    }
  };

  useEffect(() => {
    if (!popupGDPRVisible) {
      setdataSource(new ArrayStore({ key: "id", data: listOptions }));
      setlangChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langChanged]);

  useEffect(() => {
    if (!popupGDPRVisible) {
      setdataSource(new ArrayStore({ key: "id", data: listOptions }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      i18n.off("languageChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  i18n.on("languageChanged", (data) => {
    setlangChanged(true);
  });

  return (
    <>
      <UserFormContainer width={width}>
        <Row>
          <Col>
            <Form
              id="form"
              formData={data}
              colCount={1}
              onContentReady={validateForm}
              onFieldDataChanged={onFieldDataChanged}
              readOnly={action === "ro" ? true : false}
              onInitialized={onInitialized}
            >
              <GroupItem caption={title} colCount={action === "ro" ? 2 : 1}>
                <Item dataField="name">
                  <Label>{t("Name")}</Label>
                  <RequiredRule message={t("LastNameRequired")!} />
                </Item>
                <Item dataField="firstName">
                  <Label>{t("Firstname")}</Label>
                  <RequiredRule message={t("FirstNameRequired")!} />
                </Item>
                <Item dataField="userName" visible={false}>
                  <Label>{t("Username")}</Label>
                </Item>
                <Item dataField="email">
                  <Label>{t("Email")}</Label>
                  <RequiredRule message={t("EmailRequired")!} />
                  <EmailRule message={t("EmailInvalid")!} />
                </Item>
                <Item dataField="sector">
                  <Label>{t("Sector")}</Label>
                </Item>
                <Item
                  dataField="language"
                  editorType="dxSelectBox"
                  editorOptions={languageOptions}
                >
                  <Label>{t("Language")}</Label>
                  <RequiredRule message={t("Language")!} />
                </Item>
                {hasGdpr && (
                  <Item
                    dataField="isSharingDataAccepted"
                    editorType="dxCheckBox"
                    editorOptions={isSharingDataOptions}
                  >
                    <Label>
                      {t("DataSharing")}

                      <Btn
                        variant="secondary"
                        size="sm"
                        style={{ marginLeft: "7px" }}
                        onClick={showGDPRPopup}
                      >
                        <FontAwesomeIcon icon={["fas", "link"]} />
                        <ButtonText>{t("SeeGDPRButton")}</ButtonText>
                      </Btn>
                      {!isSharingDataAccepted && (
                        <SpanAcceptGDPR>{t("AcceptGDPR")!}</SpanAcceptGDPR>
                      )}
                    </Label>
                    <CompareRule
                      message={t("AcceptGDPR")!}
                      comparisonTarget={checkComparison}
                    />
                  </Item>
                )}
                <Item dataField="deleteReason" visible={action === "ro"}>
                  <Label>{t("DeleteReason")}</Label>
                </Item>
              </GroupItem>
            </Form>
          </Col>
        </Row>

        {action !== "ro" && (
          <DivRepresentative>
            <Row>
              <Col>
                <DivSelectOneOption>
                  <ButtonText>{t("MemberOf")} :</ButtonText>
                  {selectedItemKeys && selectedItemKeys.length === 0 && (
                    <SpanSelectOneOption>
                      (*) {t("SelectOneOption")}
                    </SpanSelectOneOption>
                  )}
                </DivSelectOneOption>
              </Col>
            </Row>
            <Row>
              <Col>
                <DivListContainer>
                  {dataSource && (
                    <List
                      dataSource={dataSource}
                      showSelectionControls={true}
                      selectionMode="multiple"
                      selectAllMode="page"
                      selectedItemKeys={selectedItemKeys}
                      selectByClick={true}
                      onSelectionChanged={onSelectedItemKeysChange}
                    ></List>
                  )}
                </DivListContainer>
              </Col>
            </Row>
          </DivRepresentative>
        )}

        {action === "ro" && (
          <Row>
            <Col>
              <AvatarContainer action={action}>
                <AvatarDiv className="avatarField">
                  <label hidden={action === "ro" ? false : true}>
                    Avatar :{" "}
                  </label>
                  <Avatar
                    hidden={action === "ro" ? false : true}
                    src={
                      data.avatar !== "" ? data.avatar : variables.fake_avatar
                    }
                    className="ms-9"
                  />
                </AvatarDiv>

                <FileDownloadedDiv hidden={action === "ro" ? false : true}>
                  <Btn
                    variant="warning"
                    style={{ minWidth: "120px" }}
                    onClick={downloadFile}
                    disabled={isDownloading || !blobFileName}
                  >
                    {isDownloading && (
                      <Spinner
                        style={{ marginRight: "10px" }}
                        animation="border"
                        role="status"
                        size="sm"
                      >
                        <span className="visually-hidden">
                          {t("Downloading")}
                        </span>
                      </Spinner>
                    )}
                    <FontAwesomeIcon
                      icon={["fas", "download"]}
                      style={{ color: "#141B4D" }}
                    />
                    <ButtonText>{t("DownloadFile")}</ButtonText>
                  </Btn>
                  <FileLabel>{blobFileName}</FileLabel>
                </FileDownloadedDiv>
              </AvatarContainer>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <ButtonsContainer hidden={isDivHidden}>
              <Btn
                variant="success"
                style={{ minWidth: "120px" }}
                onClick={signUpUser}
                disabled={
                  !isFormFullFilled ||
                  !isSharingDataAccepted ||
                  selectedItemKeys.length === 0
                }
                hidden={action === "changepwd" ? true : false}
              >
                {isSaving && (
                  <Spinner
                    style={{ marginRight: "10px" }}
                    animation="border"
                    role="status"
                    size="sm"
                  >
                    <span className="visually-hidden">{t("Saving")}</span>
                  </Spinner>
                )}
                <FontAwesomeIcon
                  icon={["fas", "user-plus"]}
                  style={{ color: "#141B4D" }}
                />
                <ButtonText>{t("CreateButton")}</ButtonText>
              </Btn>
              <Btn
                variant="primary"
                style={{ minWidth: "120px" }}
                onClick={backToHome}
              >
                <FontAwesomeIcon
                  icon={["fas", "home"]}
                  style={{ color: "#141B4D" }}
                />
                <ButtonText>{t("HomeButton")}</ButtonText>
              </Btn>
            </ButtonsContainer>
          </Col>
        </Row>

        <Popup
          id="gdprPage"
          visible={popupGDPRVisible}
          onHiding={hideGDPRPopup}
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={false}
          showTitle={false}
          title={t("PopupGDPRTitle")!}
          container=".dx-viewport"
        >
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={acceptGDPRPopupButtonOptions()}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeGDPRPopupButtonOptions()}
          />

          <DivFrame className="gdprScroll" onScroll={handleScroll}>
            <Gdpr hasScroll={false} />
          </DivFrame>
        </Popup>
      </UserFormContainer>
    </>
  );
};

export default UserForm;
