import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { formatHref } from "../../utils/functions/functionsUtils";
import React, { useEffect, useState } from "react";

const ItemContainer = styled.div<{ open: boolean }>`
  text-align: start;
  margin-bottom: 10px;
  width: ${(prop) => (prop.open ? "225px" : "60px")};
  &:hover {
    background-color: #cccccc;
  }
`;
const ItemDescription = styled.span`
  color: black;
`;
const ItemLink = styled(Link)`
  text-decoration: none;
  color: black;
`;
const CategoryImage = styled.img`
  height: 20px;
`;

function SidebarItem(props: any) {
  //Translation module
  const { t } = useTranslation();
  //*******************
  const location = useLocation();
  const { pathname } = location;
  const [isCurrentViewActive, setisCurrentViewActive] = useState(false);

  const isViewActive = (): boolean => {
    if (
      pathname.toLowerCase() === "/agreements" &&
      props.subItem.translationKey === "MenuAgreements"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/legislations" &&
      props.subItem.translationKey === "MenuLegislations"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/obligations" &&
      props.subItem.translationKey === "MenuObligations"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/models" &&
      props.subItem.translationKey === "MenuModels"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/jurisprudences" &&
      props.subItem.translationKey === "MenuJurisprudence"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/publications" &&
      props.subItem.translationKey === "MenuPublications"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/categories" &&
      props.subItem.translationKey === "MenuCategories"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/usermanagement" &&
      props.subItem.translationKey === "MenuUsers"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/subscriptions" &&
      props.subItem.translationKey === "MenuSubscriptionsRequest"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/subscriptionsmodification" &&
      props.subItem.translationKey === "MenuChangeAccountRequests"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/subscriptionsdeletion" &&
      props.subItem.translationKey === "MenuDeleteAccountRequest"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/adminscreen" &&
      props.subItem.translationKey === "MenuAdminScreen"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/userslegislations" &&
      props.subItem.translationKey === "MenuLegislations"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/usersagreements" &&
      props.subItem.translationKey === "MenuAgreements"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/usersobligations" &&
      props.subItem.translationKey === "MenuObligations"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/usersmodels" &&
      props.subItem.translationKey === "MenuModels"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/usersjurisprudences" &&
      props.subItem.translationKey === "MenuJurisprudence"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/userspublications" &&
      props.subItem.translationKey === "MenuPublications"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/allcategories" &&
      props.subItem.translationKey === "MenuAllCategories"
    ) {
      return true;
    }

    if (
      pathname.toLowerCase() === "/internal" &&
      props.subItem.translationKey === "MenuInternal"
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setisCurrentViewActive(isViewActive());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ItemContainer
      open={props.isOpen}
      style={{
        backgroundColor: isCurrentViewActive
          ? "var(--bs-accordion-active-bg)"
          : "",
      }}
    >
      <ItemDescription className="ms-3 ">
        <ItemLink to={props.subItem.href} onClick={() => props.onClick()}>
          {props.subItem.iconName &&
          props.subItem.iconName.includes("data:") ? (
            <CategoryImage
              className={"side-bar-item-image"}
              src={props.subItem.iconName}
              alt={"icon " + t(props.subItem.translationKey)!}
              aria-label={"icon " + t(props.subItem.translationKey)!}
              title={"icon " + t(props.subItem.translationKey)!}
              role="img"
            />
          ) : (
            <FontAwesomeIcon icon={props.subItem.iconName} />
          )}

          <span className="ms-2">
            {props.isOpen ? formatHref(props.subItem.href, t) : ""}
          </span>
        </ItemLink>
      </ItemDescription>
    </ItemContainer>
  );
}

export default SidebarItem;
