import React, { useEffect, useState } from "react";
import {
  Form,
  GroupItem,
  Item,
  Label,
  CompareRule,
  RequiredRule,
} from "devextreme-react/form";
import eye from "../../assets/eye.png";
import { Row, Col, Button as Btn, Spinner } from "react-bootstrap";
import ScreenTitle from "../../components/ScreenTitle";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../../models/user";
import { useTranslation } from "react-i18next";
import { ScrollView } from "devextreme-react";

const MyProfileContainer = styled.div`
  width: 100%;
  padding: 20px;
  justify-content: center;
`;

const UserFormContainer = styled.div`
  padding-top: 20px;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ButtonText = styled.span`
  padding-left: 5px;
`;

function ResetPassword() {
  const [isFormFullFilled, setIsFormFullFilled] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [userData, setUserData] = useState<UserInfo>({} as UserInfo);
  const { userToken } = useParams();
  const navigate = useNavigate();

  //Translation module
  const { t } = useTranslation();
  //*******************

  let formInstance: any = null;
  const GetUserInfoByToken = () => {
    jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getUserByToken}?userToken=${userToken}`
      )
      .then((response) => {
        console.log(response);
        if (response.data.statusCode === 200) {
          setUserData(response.data.payload);
          console.log(userData)
        } else {
          toast.error(response.data.message, {
            position: "top-right",
          });

          backToHome();
        }
      });
  };

  useEffect(() => {
    GetUserInfoByToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let onInitialized = (e: any) => {
    formInstance = e.component;
  };

  const readOnlyOptions = {
    disabled: true,
  };

  let passwordOptions = {
    mode: "password",
    disabled: false,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: eye,
          type: "default",
          onClick: () => changePasswordMode("password"),
        },
      },
    ],
  };

  const confirmOptions = {
    mode: "password",
    disabled: false,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: eye,
          type: "default",
          onClick: () => changePasswordMode("confirmPassword"),
        },
      },
    ],
  };

  const changePasswordMode = (name: any) => {
    if (!formInstance) return null;
    const editor = formInstance.getEditor(name);
    editor.option(
      "mode",
      editor.option("mode") === "text" ? "password" : "text"
    );
  };

  const passwordComparison = () => {
    return userData.password;
  };

  const onFieldDataChanged = async (e: any) => {
    setUserData(e.component.option("formData"));
    formInstance = e.component;

    let res = e.component.validate();

    if (res.brokenRules.length >= 1) {
      setIsFormFullFilled(false);
    } else {
      setIsFormFullFilled(true);
    }
  };

  const backToHome = async () => {
    navigate("/");
  };

  const handleSavingState = (st: boolean) => {
    setSaving(st);
  };

  const showNotifications = (data: any, showOnSuccess: boolean) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t("ChangePwdSuccess"), { position: "top-right" });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const changePassword = async () => {
    handleSavingState(true);
    userData.password = `${btoa(userData.password!)}${api_keys.cslIdentifier}`;
    userData.confirmPassword = "";

    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.changePassword}`,
        userData
      )
      .then((response) => {
        showNotifications(response.data, true);
        handleSavingState(false);
        backToHome();
        return response.data.payload;
      });
  };

  const validateForm = React.useCallback((e: any) => {
    e.component.validate();
  }, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <MyProfileContainer>
        <Row>
          <Col className="d-flex">
            <ScreenTitle title={t("InternalPagerequestResetPassword")!} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <UserFormContainer>
              <Row>
                <Col>
                  <Form
                    formData={userData}
                    colCount={1}
                    onContentReady={validateForm}
                    onFieldDataChanged={onFieldDataChanged}
                    onInitialized={onInitialized}
                  >
                    <GroupItem caption={t("ManagePassword")!} colCount={2}>
                      <Item
                        dataField="name"
                        editorOptions={readOnlyOptions}
                      ></Item>
                      <Item
                        dataField="firstName"
                        editorOptions={readOnlyOptions}
                      ></Item>
                      <Item
                        dataField="userName"
                        editorOptions={readOnlyOptions}
                      ></Item>
                      <Item
                        dataField="email"
                        editorOptions={readOnlyOptions}
                      ></Item>
                      <Item
                        dataField="password"
                        editorType="dxTextBox"
                        editorOptions={passwordOptions}
                      >
                        <RequiredRule message={t("PwdRequired")!} />
                      </Item>
                      <Item
                        dataField="confirmPassword"
                        editorType="dxTextBox"
                        editorOptions={confirmOptions}
                      >
                        <Label text={t("ConfirmPassword")!} />
                        <RequiredRule message={t("ConfirmPwdRequired")!} />
                        <CompareRule
                          message={t("PwdsNotMatch")!}
                          comparisonTarget={passwordComparison}
                        />
                      </Item>
                    </GroupItem>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ButtonsContainer>
                    <Btn
                      variant="success"
                      style={{ minWidth: "120px" }}
                      onClick={changePassword}
                      disabled={isFormFullFilled === false ? true : false}
                    >
                      {isSaving && (
                        <Spinner
                          style={{ marginRight: "10px" }}
                          animation="border"
                          role="status"
                          size="sm"
                        >
                          <span className="visually-hidden">{t("Saving")}</span>
                        </Spinner>
                      )}
                      <FontAwesomeIcon
                        icon={["fas", "floppy-disk"]}
                        style={{ color: "#141B4D" }}
                      />
                      <ButtonText>{t("Confirm")}</ButtonText>
                    </Btn>
                    <Btn
                      variant="primary"
                      style={{ minWidth: "120px" }}
                      onClick={backToHome}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "home"]}
                        style={{ color: "#141B4D" }}
                      />
                      <ButtonText>{t("HomeButton")}</ButtonText>
                    </Btn>
                  </ButtonsContainer>
                </Col>
              </Row>
            </UserFormContainer>
          </Col>
        </Row>
      </MyProfileContainer>
    </ScrollView>
  );
}

export default ResetPassword;
