import React, { useEffect, useState } from "react";
import { Row, Col, Button as Btn } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import LanguageFlags from "../../components/LanguageFlags";
import ModalPDF from "../../components/ModalPDF";
import { useTranslation } from "react-i18next";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import TreeList, {
  Column,
  Selection,
  Scrolling,
  Button,
} from "devextreme-react/tree-list";
// import "devextreme/dist/css/dx.greenmist.css";
import "whatwg-fetch";
import {
  loggedUser,
  isUserAdmin,
  isUserEditor,
  getLocalStorageItems,
} from "../../utils/functions/functionsUtils";
import { Summary } from "../../models/summary";
import { Popup, ToolbarItem as ToolbarPopup } from "devextreme-react/popup";
import { LoadPanel } from "devextreme-react/load-panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DataGrid,
  Column as ColumnDataGrid,
  Selection as SelectionDataGrid,
  Paging as PagingDataGrid,
  SearchPanel as SearchPanelDataGrid,
  HeaderFilter as HeaderFilterDataGrid,
} from "devextreme-react/data-grid";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import { Form, GroupItem, Item, Label } from "devextreme-react/form";
import { ScrollView } from "devextreme-react";

const TreeListContainer = styled.div`
  width: 100%;
  padding: 20px 10px 20px 0px;
  word-wrap: break-word;
`;

const CategoryImage = styled.img`
  padding: 5px;
  height: 40px;
`;

const DivCategory = styled.div<{ color: string }>`
  background-color: ${(prop) => prop.color};
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

const DivCategoryPopup = styled.div<{ color: string }>`
  background-color: ${(prop) => prop.color};
  border-radius: 4px;
  margin-bottom: 20px;
`;

const SpanCategory = styled.span`
  padding: 10px;
  font-size: 20px;
  color: white;
`;

const ButtonText = styled.span`
  padding-left: 5px;
`;

const ButtonsContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: space-around;
`;

const ButtonFavorite = styled.button<{ color: string }>`
  background-color: ${(prop) => prop.color};
  font-size: 1em;
  margin: 5px 10px;
  padding: 0.25em 1em;
  border: 2px solid #fff;
  border-radius: 3px;
  &:hover {
    box-shadow: 5px 5px 5px light-gray;
    transform: scale(1.1);
  }
`;

const LangSelector = styled(ReactFlagsSelect)`
  padding-right: 5px;
`;

const DivMultiLanguage = styled.div``;

const VersionsText = styled.span`
  color: rgb(20, 27, 77);
  font-size: large;
`;

const DivMultiLanguageInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivInnerHeaderCells = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ButtonMultiLanguage = styled.button`
  font-size: 1em;
  margin: 5px 10px;
  padding: 0.25em 1em;
  border: 2px solid rgb(20, 27, 77) !important;
  border-radius: 4px !important;
  align-items: center !important;
  background-color: rgb(20, 27, 77) !important;
  display: flex !important;
  &:hover {
    box-shadow: 5px 5px 5px light-gray;
    transform: scale(1.1);
  }
`;

const ButtonPopupLink = styled.button`
  font-size: 1em;
  margin: 5px 10px;
  padding: 0.25em 1em;
  border: 2px solid rgb(73, 191, 189) !important;
  border-radius: 4px !important;
  align-items: center !important;
  background-color: rgb(73, 191, 189);!important;
  display: flex !important;
  &:hover {
    box-shadow: 5px 5px 5px light-gray;
    transform: scale(1.1);
  }
`;

const ButtonPopupFavorite = styled.button`
  font-size: 1em;
  margin: 5px 10px;
  padding: 0.25em 1em;
  border: 2px solid rgb(13, 110, 253) !important;
  border-radius: 4px !important;
  align-items: center !important;
  background-color: rgb(13, 110, 253) !important;
  display: flex !important;
  &:hover {
    box-shadow: 5px 5px 5px light-gray;
    transform: scale(1.1);
  }
`;

const ButtonPopupUnFavorite = styled.button`
  font-size: 1em;
  margin: 5px 10px;
  padding: 0.25em 1em;
  border: 2px solid rgb(13, 110, 253) !important;
  border-radius: 4px !important;
  align-items: center !important;
  background-color: rgb(13, 110, 253) !important;
  display: flex !important;
  &:hover {
    box-shadow: 5px 5px 5px light-gray;
    transform: scale(1.1);
  }
`;

function CategoriesTreeList({ categoryCode }: any) {
  //States declaration
  const [selectedCategory, setSelectedCategory] = useState<any>({} as any);
  const [categoryDocumentData, setCategoryDocumentData] = useState<any[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<any>({} as any);
  const [parameters, setParameters] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState([""]);
  const [HTMLDocumentPopupVisible, setHTMLDocumentPopupVisible] =
    useState(false);
  const [popupSummaryVisible, setPopupSummaryVisible] = useState(false);
  const [popupFilterSummaryVisible, setPopupFilterSummaryVisible] =
    useState(false);
  const [loadingTreeList, setLoadingTreeList] = useState(false);
  const [allDocumentsView, setAllDocumentsView] = useState(true);
  const [favoritesCount, setFavoritesCount] = useState(0);
  const [allDocumentsCount, setAllDocuments] = useState(0);
  const [documentSummaries, setDocumentSummaries] = useState<any[]>([]);
  const [selectedSummary, setSelectedSummary] = useState<Summary>(
    {} as Summary
  );
  const [savedSummaries, setSavedSummaries] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [selectedSummaries, setSelectedSummaries] = useState([]);
  const [multiLanguagePopupVisible, setMultiLanguagePopupVisible] =
    useState(false);
  const [selectedDocumentRevisionId, setSelectedDocumentRevisionId] =
    useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const i18nextLng = getLocalStorageItems("i18nextLng", false);
  const [currentLang, setcurrentLang] = useState<string | undefined>(
    i18nextLng
  );

  i18n.on("languageChanged", (data) => {
    setcurrentLang(data);
  });

  //*******************
  let navigate = useNavigate();
  let summaryDataGrid: any;

  //Functions
  const isVisible = (keyValue: string) => {
    let tempCat = parameters.filter(function (ft) {
      return ft.name.includes(keyValue);
    });
    if (keyValue === "RevisionDate" && categoryCode === "agreements") {
      if (!isUserAdmin()) return false;
    }

    return tempCat.length > 0;
  };

  const favoriteDocument = (apiData: any) => {
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.setDocumentAsFavorite}`,
        apiData
      )
      .then((response) => {
        showNotifications(response.data, true, "DocumentFavorite");
        getFrontEndCategoryDocuments(0, false);
      });
  };

  const popupDocumentHandleFavorite = () => {
    const payload = {
      UserId: loggedUser().id,
      DocumentRevisionId: selectedDocument.documentRevisionId,
      IsSettingFavorite: selectedDocument.isSettingFavorite === 1 ? 0 : 1,
    };
    favoriteDocument(payload);
  };

  const handleSetDocAsFav = (e: any) => {
    if (!e.row.data) return;
    const payload = {
      UserId: loggedUser().id,
      DocumentRevisionId: e.row.data.documentRevisionId,
      IsSettingFavorite: e.row.data.isSettingFavorite === 1 ? 0 : 1,
    };
    favoriteDocument(payload);
  };

  const showNotifications = (
    data: any,
    showOnSuccess: boolean,
    message: string
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t(message), {
          position: "top-right",
        });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const onFocusedRowChanged = (e: any) => {
    if (e.row === undefined) return;

    setSelectedDocument(e.row.data);
    setSelectedDocumentRevisionId(e.row.data.documentRevisionId);
  };

  const onTreeListRowClick = (e: any) => {
    if (e.data === undefined) return;

    handleSeeDocumentClick(e);

    if (e.component) {
      //span means the user clicked on the expand/collapse icon
      if (e.event.target.tagName.toLowerCase() !== "span") {
        if (e.row && e.row.isExpanded) {
          e.component.collapseRow(e.key);
        }
        if (e.row && !e.row.isExpanded) {
          e.component.expandRow(e.key);
        }
      }
    }
  };

  const getFrontEndCategoryDocuments = (
    seeFavorite: number,
    isClearSearch: boolean
  ) => {
    setLoadingTreeList(true);
    let searchContent = isClearSearch ? [""] : searchKeyword;
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${
          api_keys.getFrontCategoryFiles
        }?categoryCode=${categoryCode}&userId=${loggedUser().id}&userLanguage=${
          loggedUser().language
        }&seeFavorite=${seeFavorite}&searchWord=${searchContent}`,
        selectedSummaries
      )
      .then((response) => {
        setCategoryDocumentData(response.data.payload.data);
        setFavoritesCount(response.data.payload.totalFavorites);
        setAllDocuments(response.data.payload.totalCount);
        setLoadingTreeList(false);

        if (selectedDocument) {
          let filterDocument = response.data.payload.data.filter(
            (x: any) => x.documentRevisionId === selectedDocumentRevisionId
          );
          if (filterDocument.length) setSelectedDocument(filterDocument[0]);
        }

        return response.data.payload.data;
      })
      .finally(() => {
        setLoadingTreeList(false);
      });
  };

  const getCategoryByCode = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategoryByCode}?categoryCode=${categoryCode}`
      )
      .then((response) => {
        setSelectedCategory(response.data.payload);
        getParametersByCategoryId(response.data.payload.id);
        return response.data.payload;
      });
  };

  const getParametersByCategoryId = (categoryId: number) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getFrontEndPagesParametersByCategoryId}?categoryId=${categoryId}&isVisibleOnFrontEnd=1`
      )
      .then((response) => {
        setParameters(response.data.payload);
        return response.data.payload;
      });
  };

  const isSettingFavoriteOptionVisible = (e: any) => {
    return (
      (e.row.data.blobFilePath ||
        e.row.data.isHtml ||
        e.row.data.blobSecondFileName) &&
      e.row.data.isSettingFavorite === 0
    );
  };

  const isSettingUnfavoriteOptionVisible = (e: any) => {
    return (
      (e.row.data.blobFilePath ||
        e.row.data.isHtml ||
        e.row.data.blobSecondFileName) &&
      e.row.data.isSettingFavorite === 1
    );
  };

  const getSummaries = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getSummaries}`
      )
      .then((response) => {
        setSummaries(response.data.payload);
      });
  };

  const onSummarySelectionChanged = ({ selectedRowsData }: any) => {
    setSelectedSummaries(selectedRowsData);
  };

  useEffect(() => {
    getCategoryByCode();
    getFrontEndCategoryDocuments(0, false);
    getSummaries();

    return () => {
      i18n.off("languageChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDocumentsBySummaryId = (summaryId: number) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getDocumentsBySummaryId}?summaryId=${summaryId}`
      )
      .then((response) => {
        setDocumentSummaries(response.data.payload);
        showSummaryPopup();
        return response.data.payload;
      });
  };

  const handleSearchChange = (e: any) => {
    const searchText: string[] = [e.value];
    setSearchKeyword(searchText);
  };

  const searchDocuments = (isClearSearch: boolean) => {
    let opt = allDocumentsView ? 0 : 1;
    getFrontEndCategoryDocuments(opt, isClearSearch);
  };

  const handleSearchButtonClick = () => {
    searchDocuments(false);
  };

  const handleSeeDocumentClick = (e: any) => {
    var documentIsHtml = e.data.isHtml === 1 && e.data.htmlContent !== "";
    if (documentIsHtml) {
      showHTMLPopup();
    } else if (e.data.categoryDocumentId > 0) {
      showPDFPopup();
    }
  };

  const handlePopupArticleLinkClick = (e: any) => {
    window.open(selectedDocument.articleLink, "_blank", "noreferrer");
  };

  const showHTMLPopup = () => {
    setHTMLDocumentPopupVisible(true);
  };

  const closeHTMLPopup = () => {
    setHTMLDocumentPopupVisible(false);
  };

  const hideSummaryPopup = () => {
    setPopupSummaryVisible(false);
  };

  const hideMultiLanguagePopup = () => {
    setMultiLanguagePopupVisible(false);
  };

  const showSummaryPopup = () => {
    setPopupSummaryVisible(true);
  };

  const hideFilterSummaryPopup = () => {
    setPopupFilterSummaryVisible(false);
  };

  const showFilterSummaryPopup = () => {
    setSelectedRows();
    setPopupFilterSummaryVisible(true);
  };

  const setSelectedRows = () => {
    summaryDataGrid.instance.selectRows(savedSummaries, true);
  };

  const closeHTMLPopupButtonOptions = {
    onClick: closeHTMLPopup,
    text: "Close",
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
  };

  const closeSummaryPopupButtonOptions = {
    onClick: hideSummaryPopup,
    text: t("PopupClose"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
  };

  const closeSummaryPopupFilterButtonOptions = {
    onClick: hideFilterSummaryPopup,
    text: t("PopupClose"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
  };

  const saveFilterSummarySelection = () => {
    setSavedSummaries(selectedSummaries);
    hideFilterSummaryPopup();
    let opt = allDocumentsView ? 0 : 1;
    getFrontEndCategoryDocuments(opt, false);
  };

  const clearSelection = () => {
    summaryDataGrid.instance.clearSelection();
  };

  const buttonSummaryClearAttributes = {
    id: "summaryClearButton",
  };

  const clearSelectionOptions = {
    text: t("PopupCategoriesSummaryClearSelection"),
    icon: "clearformat",
    type: "default",
    disabled: selectedSummaries.length <= 0,
    elementAttr: buttonSummaryClearAttributes,
    onClick: clearSelection,
  };

  const saveSummaryPopupFilterButtonOptions = {
    text: t("PopupFilter"),
    icon: "search",
    elementAttr: {
      class: "see-document-button",
    },
    type: "success",
    onClick: saveFilterSummarySelection,
  };

  const closeMultiLanguagePopupButtonOptions = {
    onClick: hideMultiLanguagePopup,
    text: t("PopupClose"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
  };

  const clearSearchOption = () => {
    setSearchKeyword([""]);
    searchDocuments(true);
  };

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        locateInMenu: "auto",
        widget: "dxButton",
        options: {
          icon: "checklist",
          type: "default",
          elementAttr: {
            id: "summaryButton",
            class: "button-summary",
          },
          text: `${t("FilterBy")} ${t("PopupCategoriesSummary")} (${
            savedSummaries.length
          }) `,
          disabled: false,
          visible: categoryCode === "jurisprudences",
          onClick: showFilterSummaryPopup,
        },
      },
      {
        location: "after",
        locateInMenu: "auto",
        widget: "dxButton",
        options: {
          icon: "clearformat",
          type: "default",
          elementAttr: {
            id: "clearResults",
            class: "see-document-button",
          },
          text: t("ClearSearch"),
          disabled: !searchKeyword.length || searchKeyword[0] === "",
          onClick: clearSearchOption,
        },
      },
      {
        location: "after",
        locateInMenu: "auto",
        widget: "dxTextBox",
        options: {
          icon: "find",
          width: 240,
          value: searchKeyword,
          onValueChanged: handleSearchChange,
          placeholder: t("Search"),
        },
      },
      {
        location: "after",
        locateInMenu: "auto",
        widget: "dxButton",
        options: {
          icon: "find",
          type: "success",
          elementAttr: {
            id: "searchDocuments",
            class: "see-document-button",
          },
          text: t("BtnSearch"),
          disabled: !searchKeyword.length || searchKeyword[0] === "",
          onClick: handleSearchButtonClick,
        },
      }
    );
  };

  const seeFavorite = () => {
    let opt = allDocumentsView ? 1 : 0;
    getFrontEndCategoryDocuments(opt, false);
    setAllDocumentsView(!allDocumentsView);
  };

  const openSumaryPopup = (rowData: any) => {
    setSelectedSummary(rowData);
    getDocumentsBySummaryId(rowData.id);
    return;
  };

  const SummaryButtons = (rowdata: any) => {
    return rowdata.data &&
      rowdata.data.data &&
      rowdata.data.data.summaries &&
      rowdata.data.data.summaries.length
      ? rowdata.data.data.summaries.map((a: any, id: number) => (
          <Btn
            key={id}
            variant="secondary"
            className="badge"
            style={{
              marginRight: "5px",
              color: "black",
              backgroundColor: "white",
            }}
            onClick={() => openSumaryPopup(a)}
          >
            {a.name}
          </Btn>
        ))
      : "";
  };

  const SearchSpan = (rowdata: any) => {
    return <VersionsText>{searchKeyword}</VersionsText>;
  };

  const onDownloadFile = async (e: any) => {
    return downloadFile(e.row.data.blobSecondFileName);
  };

  const downloadFile = async (fileName: string) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.downloadCategoryFile}?fileName=${fileName}&categoryCode=${categoryCode} `,
        { responseType: "blob" }
      )
      .then((response: any) => {
        var url = window.URL.createObjectURL(response.data);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const isDownloadOptionVisible = (e: any) => {
    return (
      e.row.data.blobSecondFileName !== null &&
      e.row.data.blobSecondFileName !== "" &&
      categoryCode === "models"
    );
  };

  const onSelectLanguage = (code: string): void => {
    //do nothing, only because its mandatory
    return;
  };

  const onClickFlagButton = (a: any) => {
    setSelectedDocumentRevisionId(a.documentRevisionId);
    setIsModalOpen(true);
  };

  const showPDFPopup = () => {
    setMultiLanguagePopupVisible(true);
  };

  const onRedirectToCategoryDetails = async (e: any) => {
    navigate(
      `/categoryDetails/${categoryCode}/${e.row.data.categoryDocumentId}`
    );
    return false;
  };

  const onRedirectToExternalLink = async (e: any) => {
    window.open(e.row.data.articleLink, "_blank");
    return false;
  };

  const isRedirectOptionVisible = (e: any) => {
    return (
      e.row.data.categoryDocumentId > 0 && (isUserAdmin() || isUserEditor())
    );
  };

  const isRedirectToExternalLinkVisible = (e: any) => {
    return e.row.data.articleLink;
  };

  const isPopupLinkToArticleOptionVisible = () => {
    return (
      selectedDocument.categoryDocumentId > 0 &&
      selectedDocument.isHtml &&
      selectedDocument.isHtml === 1 &&
      selectedDocument.articleLink !== "" &&
      selectedDocument.articleLink
    );
  };

  const resumeOptions = {
    height: 180,
    readOnly: true,
  };

  const nameHeaderFilterDataSource = [
    {
      text: "A",
      value: ["name", "startswith", "A"],
    },
    {
      text: "B",
      value: ["name", "startswith", "B"],
    },
    {
      text: "C",
      value: ["name", "startswith", "C"],
    },
    {
      text: "D",
      value: ["name", "startswith", "D"],
    },
    {
      text: "E",
      value: ["name", "startswith", "E"],
    },
    {
      text: "F",
      value: ["name", "startswith", "F"],
    },
    {
      text: "G",
      value: ["name", "startswith", "G"],
    },
    {
      text: "H",
      value: ["name", "startswith", "H"],
    },
    {
      text: "I",
      value: ["name", "startswith", "I"],
    },
    {
      text: "J",
      value: ["name", "startswith", "J"],
    },
    {
      text: "K",
      value: ["name", "startswith", "K"],
    },
    {
      text: "L",
      value: ["name", "startswith", "L"],
    },
    {
      text: "M",
      value: ["name", "startswith", "M"],
    },
    {
      text: "N",
      value: ["name", "startswith", "N"],
    },
    {
      text: "O",
      value: ["name", "startswith", "O"],
    },
    {
      text: "P",
      value: ["name", "startswith", "P"],
    },
    {
      text: "Q",
      value: ["name", "startswith", "Q"],
    },
    {
      text: "R",
      value: ["name", "startswith", "R"],
    },
    {
      text: "S",
      value: ["name", "startswith", "S"],
    },
    {
      text: "T",
      value: ["name", "startswith", "T"],
    },
    {
      text: "U",
      value: ["name", "startswith", "U"],
    },
    {
      text: "V",
      value: ["name", "startswith", "V"],
    },
    {
      text: "W",
      value: ["name", "startswith", "W"],
    },
    {
      text: "X",
      value: ["name", "startswith", "X"],
    },
    {
      text: "Y",
      value: ["name", "startswith", "Y"],
    },
    {
      text: "Z",
      value: ["name", "startswith", "Z"],
    },
  ];

  const summaryPopupRowChanged = (e: any) => {
    if (e.data === undefined) {
      return;
    }
    setSelectedDocument(e.data);
    setSelectedDocumentRevisionId(e.data.documentRevisionId);

    showPDFPopup();
  };

  const headerCellWithOrderIcon = (caption: any) => {
    return (
      <DivInnerHeaderCells>
        <span>
          <FontAwesomeIcon icon={["fas", "sort"]} /> {caption}
        </span>
      </DivInnerHeaderCells>
    );
  };

  const isTherePdfDocument = (multiLanguages: any) => {
    var thereIsPdf =
      multiLanguages &&
      multiLanguages.filter((x: any) => x.hasPdfDocument === 1).length > 0;
    return thereIsPdf;
  };

  const popupRedirectToExternalLink = (link: string) => {
    window.open(link, "_blank");
    return false;
  };

  return (
    <TreeListContainer>
      <ScrollView height={window.innerHeight - 150} direction="vertical">
        <Row>
          <Col>
            <DivCategory color={selectedCategory.color}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CategoryImage
                  src={selectedCategory.base64Image}
                  id="imageContainer"
                  alt={t(selectedCategory.name)!}
                  aria-label={t(selectedCategory.name)!}
                  title={t(selectedCategory.name)!}
                  role="img"
                />
                <SpanCategory color={selectedCategory.color}>
                  {t(
                    categoryCode.charAt(0).toUpperCase() + categoryCode.slice(1)
                  )}
                </SpanCategory>
                <SpanCategory color={selectedCategory.color}>
                  {"| " + t("TotalFiles") + ": " + allDocumentsCount}
                </SpanCategory>
              </div>
              <ButtonsContainer>
                <ButtonFavorite
                  color={selectedCategory.color}
                  onClick={seeFavorite}
                >
                  <FontAwesomeIcon icon={["fas", "star"]} />
                  <ButtonText>
                    {allDocumentsView
                      ? t("SeeFavoriteButton") + ` (${favoritesCount})`
                      : t("SeeAllButton") + ` (${allDocumentsCount})`}
                  </ButtonText>
                </ButtonFavorite>
              </ButtonsContainer>
            </DivCategory>
          </Col>
        </Row>
        <Row>
          <LoadPanel
            visible={loadingTreeList}
            showIndicator={true}
            shading={true}
            shadingColor="rgba(0,0,0,0.4)"
            showPane={false}
            message={t("LoadingCategoryFiles")!}
          />
          <Col>
            <TreeList
              id="TreelistCategoryDocuments"
              dataSource={categoryDocumentData}
              keyExpr="categoryId"
              parentIdExpr="parentId"
              showRowLines={true}
              showBorders={true}
              focusedRowEnabled={true}
              autoExpandAll={false}
              columnAutoWidth={true}
              showColumnLines={false}
              showColumnHeaders={true}
              allowColumnReordering={true}
              onToolbarPreparing={onToolbarPreparing}
              onFocusedRowChanged={onFocusedRowChanged}
              onCellClick={onTreeListRowClick}
              noDataText={t("NoDataCategoryFiles")!}
            >
              <Selection mode="single" />
              <Scrolling mode="standard" />
              <Column
                dataField="categoryName"
                caption={t("CategoryName")!}
                visible={currentLang === "FR"}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("CategoryName")!)
                }
              />
              <Column
                dataField="categoryNameEn"
                caption={t("CategoryName")!}
                visible={currentLang === "GB"}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("CategoryName")!)
                }
              />
              <Column
                dataField="categoryNameDe"
                caption={t("CategoryName")!}
                visible={currentLang === "DE"}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("CategoryName")!)
                }
              />

              <Column dataField="categoryId" visible={false} width={10} />
              <Column dataField="parentId" visible={false} width={10} />
              <Column
                dataField="summary"
                caption={t("Summary")!}
                visible={isVisible("Summary")}
                allowSorting={false}
                cellRender={(data) => <SummaryButtons data={data} />}
                alignment={"left"}
              />

              <Column
                dataField="jurisdiction"
                caption={t("PopupCategoriesJurisdiction")!}
                visible={isVisible("Jurisdiction")}
                alignment={"left"}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesJurisdiction")!)
                }
              />
              <Column
                dataField="resume"
                caption={t("PopupCategoriesResume")!}
                visible={false}
                width={400}
              />
              <Column
                dataField="reference"
                caption={t("PopupCategoriesReference")!}
                visible={isVisible("Reference")}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesReference")!)
                }
                alignment={"left"}
              />
              <Column
                dataField="status"
                caption={t("PopupCategoriesStatus")!}
                visible={isVisible("Status") && isUserAdmin()}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesStatus")!)
                }
                alignment={"center"}
              />
              <Column
                dataField="number"
                caption={t("PopupCategoriesRevisionNumber")!}
                visible={false}
                alignment={"center"}
              />
              <Column
                dataField="language"
                caption={t("Language")!}
                visible={false}
                alignment={"center"}
              />
              <Column
                dataField="publicationTitle"
                caption={t("PopupCategoriesPublicationTitle")!}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesPublicationTitle")!)
                }
                visible={isVisible("PublicationTitle")}
                alignment={"left"}
              />
              <Column
                dataField="publicationNumber"
                caption={t("PopupCategoriesPublicationNumber")!}
                headerCellRender={() =>
                  headerCellWithOrderIcon(
                    t("PopupCategoriesPublicationNumber")!
                  )
                }
                visible={isVisible("PublicationNumber")}
                alignment={"left"}
              />
              <Column
                dataField="articleNumber"
                caption={t("PopupCategoriesArticleNumber")!}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesArticleNumber")!)
                }
                visible={isVisible("ArticleNumber")}
                alignment={"left"}
              />
              <Column
                dataField="revisionDate"
                caption={t("PopupCategoriesRevisionDate")!}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesRevisionDate")!)
                }
                visible={isVisible("RevisionDate")}
                dataType="date"
                format="dd/MM/yyyy"
                alignment={"center"}
              />
              <Column
                dataField="decisionDate"
                caption={t("PopupCategoriesDecisionDate")!}
                visible={isVisible("DecisionDate")}
                dataType="date"
                format="dd/MM/yyyy"
                alignment={"center"}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesDecisionDate")!)
                }
                sortIndex={0}
                sortOrder="desc"
              />
              <Column
                dataField="referenceNumber"
                caption={t("PopupCategoriesReferenceNumber")!}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("referenceNumber")!)
                }
                visible={isVisible("ReferenceNumber")}
                alignment={"left"}
              />
              <Column
                dataField="source"
                caption={t("PopupCategoriesSource")!}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesSource")!)
                }
                visible={isVisible("Source")}
                alignment={"left"}
              />
              <Column
                dataField="sourceExact"
                caption={t("PopupCategoriesSourceExact")!}
                headerCellRender={() =>
                  headerCellWithOrderIcon(t("PopupCategoriesSourceExact")!)
                }
                visible={isVisible("SourceExact")}
                alignment={"left"}
              />
              <Column
                dataField="revisionNumber"
                caption={t("PopupCategoriesRevisionNumber")!}
                visible={false}
                alignment={"left"}
              />
              <Column
                dataField="docFormat"
                caption={t("DocFormat")!}
                visible={isVisible("DocFormat")}
                width={100}
              />
              <Column dataField="blobFilePath" visible={false} />
              <Column dataField="blobSecondFilePath" visible={false} />
              <Column dataField="isHtml" visible={false} />
              <Column dataField="htmlContent" visible={false} />
              <Column dataField="createdBy" visible={false} />
              <Column dataField="createdOn" visible={false} />

              <Column
                dataField="multiLanguage"
                caption={t("Language")!}
                visible={true}
                allowSorting={false}
                cellRender={(data) => <LanguageFlags data={data} />}
                alignment={"center"}
              />

              <Column type="buttons" caption={t("Actions")!}>
                <Button
                  hint={t("FavoriteHint")!}
                  icon="favorites"
                  cssClass="favorite-button-treelist"
                  onClick={handleSetDocAsFav}
                  visible={isSettingFavoriteOptionVisible}
                />
                <Button
                  hint={t("UnFavoriteHint")!}
                  icon="favorites"
                  cssClass="unfavorite-button-treelist"
                  onClick={handleSetDocAsFav}
                  visible={isSettingUnfavoriteOptionVisible}
                />
                <Button
                  hint={t("HintCategoryDetails")!}
                  icon="link"
                  cssClass="favorite-button-treelist"
                  onClick={onRedirectToCategoryDetails}
                  visible={isRedirectOptionVisible}
                />
                <Button
                  hint={t("PopupCategoriesArticleLink")!}
                  icon="link"
                  cssClass="link-to-article-button-treelist "
                  onClick={onRedirectToExternalLink}
                  visible={isRedirectToExternalLinkVisible}
                />
              </Column>

              <Column
                name="button-column-1"
                type="buttons"
                caption={t("DownloadWordTemplate")!}
                visible={categoryCode === "models"}
              >
                <Button
                  hint={t("HintDownload")!}
                  icon="download"
                  onClick={onDownloadFile}
                  visible={isDownloadOptionVisible}
                />
              </Column>
            </TreeList>
          </Col>
        </Row>
      </ScrollView>

      {/* PopupArea */}
      {/* PDF Popup */}
      {isModalOpen && (
        <ModalPDF
          setIsModalOpen={setIsModalOpen}
          documentReference={selectedDocument.reference}
          documentRevisionId={selectedDocumentRevisionId}
          categoryCode={categoryCode}
          searchParam={
            searchKeyword && searchKeyword[0] ? searchKeyword[0] : "all"
          }
        />
      )}

      {/* HTML Popup */}
      <Popup
        id="seeHTMLDocPopup"
        visible={HTMLDocumentPopupVisible}
        onHiding={closeHTMLPopup}
        dragEnabled={false}
        height={"90vh"}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title={selectedDocument.articleNumber!}
        container=".dx-viewport"
      >
        <Row>
          <Col>
            <DivCategoryPopup color={selectedCategory.color}>
              <CategoryImage src={selectedCategory.base64Image} />
              <SpanCategory color={selectedCategory.color}>
                {t(
                  categoryCode.charAt(0).toUpperCase() + categoryCode.slice(1)
                ) +
                  " - " +
                  selectedDocument.articleNumber}
              </SpanCategory>
            </DivCategoryPopup>
          </Col>
        </Row>
        <ToolbarPopup
          widget="dxButton"
          toolbar="top"
          location="after"
          options={closeHTMLPopupButtonOptions}
        />
        <Row
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Col md="auto">
            {selectedDocument && !selectedDocument.isSettingFavorite ? (
              <DivMultiLanguageInner>
                <ButtonPopupFavorite onClick={popupDocumentHandleFavorite}>
                  <FontAwesomeIcon icon={["far", "star"]} />
                  <ButtonText>{t("FavoriteHint")!}</ButtonText>
                </ButtonPopupFavorite>
              </DivMultiLanguageInner>
            ) : (
              <DivMultiLanguageInner>
                <ButtonPopupUnFavorite onClick={popupDocumentHandleFavorite}>
                  <FontAwesomeIcon icon={["fas", "star"]} />
                  <ButtonText>{t("UnFavoriteHint")!}</ButtonText>
                </ButtonPopupUnFavorite>
              </DivMultiLanguageInner>
            )}
          </Col>

          <Col md="auto">
            {selectedDocument && isPopupLinkToArticleOptionVisible() && (
              <DivMultiLanguageInner>
                <ButtonPopupFavorite onClick={handlePopupArticleLinkClick}>
                  <FontAwesomeIcon icon={["fas", "link"]} />
                  <ButtonText>{t("PopupCategoriesArticleLink")!}</ButtonText>
                </ButtonPopupFavorite>
              </DivMultiLanguageInner>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="auto">
            {HTMLDocumentPopupVisible && (
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedDocument.htmlContent,
                }}
              ></div>
            )}
          </Col>
        </Row>
      </Popup>

      {/* Documents Summary Popup */}
      <Popup
        id="documentsSummaryPopup"
        visible={popupSummaryVisible}
        onHiding={hideSummaryPopup}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={false}
        showTitle={true}
        title={t("PopupDocumentsSummary")!}
        container=".dx-viewport"
      >
        <ToolbarPopup
          widget="dxButton"
          toolbar="top"
          location="after"
          options={closeSummaryPopupButtonOptions}
        />
        <Row>
          <Col>
            <DivCategoryPopup color={selectedCategory.color}>
              <CategoryImage src={selectedCategory.base64Image} />
              <SpanCategory color={selectedCategory.color}>
                {t(
                  categoryCode.charAt(0).toUpperCase() + categoryCode.slice(1)
                ) +
                  ` - ${t("PopupDocumentsSummary")} - ${selectedSummary.name}`}
              </SpanCategory>
            </DivCategoryPopup>
          </Col>
        </Row>
        <DataGrid
          id="gridSummaryPopup"
          dataSource={documentSummaries}
          keyExpr="documentRevisionId"
          showRowLines={true}
          showColumnLines={false}
          focusedRowEnabled={true}
          onRowClick={summaryPopupRowChanged}
          showBorders={true}
          noDataText={t("NoDataCategoryFiles")!}
          rowAlternationEnabled={true}
        >
          <PagingDataGrid defaultPageSize={10} />
          <SearchPanelDataGrid
            visible={true}
            width={240}
            placeholder={t("Search")!}
          />

          <ColumnDataGrid
            dataField="documentRevisionId"
            visible={false}
            width={10}
          />
          <ColumnDataGrid
            dataField="jurisdiction"
            caption={t("PopupCategoriesJurisdiction")!}
            visible={isVisible("Jurisdiction")}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="resume"
            caption={t("PopupCategoriesResume")!}
            visible={isVisible("Resume")}
            width={400}
          />
          <ColumnDataGrid
            dataField="reference"
            caption={t("PopupCategoriesReference")!}
            visible={isVisible("Reference")}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="status"
            caption={t("PopupCategoriesStatus")!}
            visible={isVisible("Status")}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="number"
            caption={t("PopupCategoriesRevisionNumber")!}
            visible={false}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="language"
            caption={t("Language")!}
            visible={false}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="publicationTitle"
            caption={t("PopupCategoriesPublicationTitle")!}
            visible={isVisible("PublicationTitle")}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="publicationNumber"
            caption={t("PopupCategoriesPublicationNumber")!}
            visible={isVisible("PublicationNumber")}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="revisionDate"
            caption={t("PopupCategoriesRevisionDate")!}
            visible={isVisible("RevisionDate")}
            dataType="date"
            format="dd/MM/yyyy"
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="decisionDate"
            caption={t("PopupCategoriesDecisionDate")!}
            visible={isVisible("DecisionDate")}
            dataType="date"
            format="dd/MM/yyyy"
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="referenceNumber"
            caption={t("PopupCategoriesReferenceNumber")!}
            visible={isVisible("ReferenceNumber")}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="source"
            caption={t("PopupCategoriesSource")!}
            visible={isVisible("Source")}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="sourceExact"
            caption={t("PopupCategoriesSourceExact")!}
            visible={isVisible("SourceExact")}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="revisionNumber"
            caption={t("PopupCategoriesRevisionNumber")!}
            visible={false}
            alignment={"center"}
          />
          <ColumnDataGrid
            dataField="articleNumber"
            caption={t("PopupCategoriesArticleNumber")!}
            visible={isVisible("ArticleNumber")}
            alignment={"center"}
          />
        </DataGrid>
      </Popup>

      {/* Multi Language Selection Popup */}
      <Popup
        id="multiLanguageSelectionPopup"
        height={560}
        visible={multiLanguagePopupVisible}
        onHiding={hideMultiLanguagePopup}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={false}
        showTitle={true}
        title={
          isTherePdfDocument(selectedDocument.multiLanguage)
            ? t("PopupMultiLanguage")!
            : t("PopupNoFiles")!
        }
        container=".dx-viewport"
      >
        <ToolbarPopup
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={closeMultiLanguagePopupButtonOptions}
        />
        <Row>
          <Col>
            <Form formData={selectedDocument} colCount={1}>
              <GroupItem colCount={1} visible={isVisible("Resume")}>
                <Item
                  dataField="resume"
                  editorType="dxTextArea"
                  editorOptions={resumeOptions}
                >
                  <Label>{t("PopupCategoriesResume")}</Label>
                </Item>
              </GroupItem>
            </Form>
          </Col>
          <Col md="auto">
            <DivMultiLanguage>
              <Row>
                <Col>
                  {isTherePdfDocument(selectedDocument.multiLanguage) ? (
                    <VersionsText>{t("AvailableLanguages")}:</VersionsText>
                  ) : null}
                </Col>
              </Row>

              <Row className="mt-2">
                {isTherePdfDocument(selectedDocument.multiLanguage) &&
                  selectedDocument.multiLanguage &&
                  selectedDocument.multiLanguage.map((a: any, id: number) => (
                    <Col md="auto" key={id}>
                      <DivMultiLanguageInner>
                        <span className="langContainer">
                          <LangSelector
                            selected={a.language}
                            onSelect={onSelectLanguage}
                            countries={["FR", "GB", "DE"]}
                            showSelectedLabel={false}
                            showOptionLabel={false}
                            fullWidth={false}
                            className="react-flags-popup-multi-language"
                            disabled
                          />
                        </span>
                        <ButtonMultiLanguage
                          onClick={() => onClickFlagButton(a)}
                          disabled={
                            !(
                              (selectedDocument.blobFileName &&
                                selectedDocument.blobFileName.includes(
                                  "pdf"
                                )) ||
                              (selectedDocument.blobSecondFileName &&
                                selectedDocument.blobSecondFileName.includes(
                                  "pdf"
                                ))
                            )
                          }
                        >
                          <FontAwesomeIcon icon={["fas", "eye"]} />
                          <ButtonText>
                            {a.language === "GB" ? "EN" : a.language}
                          </ButtonText>
                        </ButtonMultiLanguage>
                      </DivMultiLanguageInner>
                    </Col>
                  ))}
              </Row>

              <Row className="mt-2">
                <Col md="auto">
                  <DivMultiLanguageInner>
                    {selectedDocument && !selectedDocument.isSettingFavorite ? (
                      <ButtonPopupFavorite
                        onClick={popupDocumentHandleFavorite}
                      >
                        <FontAwesomeIcon icon={["far", "star"]} />
                        <ButtonText>{t("FavoriteHint")!}</ButtonText>
                      </ButtonPopupFavorite>
                    ) : (
                      <ButtonPopupUnFavorite
                        onClick={popupDocumentHandleFavorite}
                      >
                        <FontAwesomeIcon icon={["fas", "star"]} />
                        <ButtonText>{t("UnFavoriteHint")!}</ButtonText>
                      </ButtonPopupUnFavorite>
                    )}
                  </DivMultiLanguageInner>
                </Col>
              </Row>

              {selectedDocument && selectedDocument.articleLink && (
                <Row className="mt-2">
                  <Col md="auto">
                    <DivMultiLanguageInner>
                      <ButtonPopupLink
                        onClick={() =>
                          popupRedirectToExternalLink(
                            selectedDocument.articleLink
                          )
                        }
                      >
                        <FontAwesomeIcon icon={["fas", "link"]} />
                        <ButtonText>
                          {t("PopupCategoriesArticleLink")!}
                        </ButtonText>
                      </ButtonPopupLink>
                    </DivMultiLanguageInner>
                  </Col>
                </Row>
              )}

              {selectedDocument &&
                selectedDocument.blobSecondFileName !== null &&
                selectedDocument.blobSecondFileName !== "" &&
                categoryCode === "models" && (
                  <Row className="mt-2">
                    <Col md="auto">
                      <DivMultiLanguageInner>
                        <ButtonPopupLink
                          onClick={() =>
                            downloadFile(selectedDocument.blobSecondFileName)
                          }
                        >
                          <FontAwesomeIcon icon={["fas", "download"]} />
                          <ButtonText>{t("DownloadWordTemplate")!}</ButtonText>
                        </ButtonPopupLink>
                      </DivMultiLanguageInner>
                    </Col>
                  </Row>
                )}
            </DivMultiLanguage>
          </Col>
        </Row>

        <Row className="pt-5">
          <Col>
            <DataGrid
              id="popupDocumentDetails"
              keyExpr="documentRevisionId"
              dataSource={[selectedDocument]}
              columnAutoWidth={true}
              showBorders={true}
            >
              <ColumnDataGrid
                dataField="documentRevisionId"
                allowEditing={false}
                visible={false}
              />
              <ColumnDataGrid
                dataField="searchKeyword"
                caption={t("SearchingKeyword")!}
                visible={
                  searchKeyword && searchKeyword[0] && searchKeyword[0] !== ""
                    ? true
                    : false
                }
                allowSorting={false}
                cellRender={(data) => <SearchSpan data={data} />}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="summary"
                caption={t("Summary")!}
                visible={isVisible("Summary")}
                allowSorting={false}
                cellRender={(data) => <SummaryButtons data={data} />}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="jurisdiction"
                caption={t("PopupCategoriesJurisdiction")!}
                visible={isVisible("Jurisdiction")}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="reference"
                caption={t("PopupCategoriesReference")!}
                visible={isVisible("Reference")}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="status"
                caption={t("PopupCategoriesStatus")!}
                visible={
                  isVisible("Status") &&
                  categoryCode !== "obligations" &&
                  categoryCode !== "agreements"
                }
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="publicationTitle"
                caption={t("PopupCategoriesPublicationTitle")!}
                visible={isVisible("PublicationTitle")}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="publicationNumber"
                caption={t("PopupCategoriesPublicationNumber")!}
                visible={isVisible("PublicationNumber")}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="revisionDate"
                caption={t("PopupCategoriesRevisionDate")!}
                visible={
                  isVisible("RevisionDate") &&
                  categoryCode !== "obligations" &&
                  categoryCode !== "agreements"
                }
                dataType="date"
                format="dd/MM/yyyy"
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="decisionDate"
                caption={t("PopupCategoriesDecisionDate")!}
                visible={isVisible("DecisionDate")}
                dataType="date"
                format="dd/MM/yyyy"
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="referenceNumber"
                caption={t("PopupCategoriesReferenceNumber")!}
                visible={isVisible("ReferenceNumber")}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="source"
                caption={t("PopupCategoriesSource")!}
                visible={isVisible("Source")}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="sourceExact"
                caption={t("PopupCategoriesSourceExact")!}
                visible={isVisible("SourceExact")}
                alignment={"center"}
              />
              <ColumnDataGrid
                dataField="articleNumber"
                caption={t("PopupCategoriesArticleNumber")!}
                visible={isVisible("ArticleNumber")}
                alignment={"center"}
              />

              <ColumnDataGrid
                dataField="multiLanguage"
                caption={t("Language")!}
                visible={
                  categoryCode !== "obligations" &&
                  categoryCode !== "agreements"
                }
                allowSorting={false}
                cellRender={(data) => <LanguageFlags data={data} />}
                alignment={"center"}
              />
            </DataGrid>
          </Col>
        </Row>
      </Popup>

      {/* Summary Filtering Popup */}
      <Popup
        id="summaryFilterPopup"
        visible={popupFilterSummaryVisible}
        onHiding={hideFilterSummaryPopup}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={false}
        showTitle={true}
        title={t("PopupCategoriesSummary")!}
        container=".dx-viewport"
      >
        <ToolbarPopup
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={closeSummaryPopupFilterButtonOptions}
        />
        <ToolbarPopup
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={saveSummaryPopupFilterButtonOptions}
        />
        <ToolbarPopup
          toolbar="top"
          location="after"
          widget="dxButton"
          options={clearSelectionOptions}
        />
        <DataGrid
          id="gridSummaryPopup"
          dataSource={summaries}
          showRowLines={true}
          showColumnLines={false}
          onSelectionChanged={onSummarySelectionChanged}
          noDataText={t("DashboardSummaryNoResults")!}
          showBorders={true}
          rowAlternationEnabled={true}
          ref={(ref) => {
            summaryDataGrid = ref;
          }}
        >
          <PagingDataGrid defaultPageSize={10} />
          <HeaderFilterDataGrid visible={true} />
          <SearchPanelDataGrid
            visible={true}
            width={240}
            placeholder={t("Search")!}
          />
          <SelectionDataGrid
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="onClick"
          />
          <ColumnDataGrid
            dataField="id"
            width={40}
            allowEditing={false}
            allowHeaderFiltering={false}
          />
          <ColumnDataGrid dataField="name">
            <HeaderFilterDataGrid dataSource={nameHeaderFilterDataSource} />
          </ColumnDataGrid>
          <ColumnDataGrid
            dataField="createdBy"
            caption={t("CreatedBy")!}
            allowEditing={false}
            alignment={"center"}
            allowHeaderFiltering={false}
            visible={isUserAdmin()}
          />
          <ColumnDataGrid
            dataField="createdOn"
            dataType="date"
            format="dd/MM/yyyy"
            caption={t("CreatedOn")!}
            alignment={"center"}
            allowEditing={false}
            allowHeaderFiltering={false}
            visible={isUserAdmin()}
          />
          <ColumnDataGrid
            dataField="updatedBy"
            caption={t("UpdatedBy")!}
            allowEditing={false}
            alignment={"center"}
            allowHeaderFiltering={false}
            visible={isUserAdmin()}
          />
          <ColumnDataGrid
            dataField="updatedOn"
            dataType="date"
            alignment={"center"}
            allowEditing={false}
            format="dd/MM/yyyy"
            caption={t("UpdatedOn")!}
            allowHeaderFiltering={false}
            visible={isUserAdmin()}
          />
        </DataGrid>
        <div className="selected-data pt-2">
          <span className="caption">
            {t("PopupCategoriesSummaryTotalSelection") +
              " (" +
              selectedSummaries.length +
              ") " +
              ": "}{" "}
          </span>

          {selectedSummaries.length ? (
            selectedSummaries.map((a: any, id: number) => (
              <span className="p-1" key={id}>
                <Badge bg="info" style={{ fontSize: 12 }}>
                  {a.name}
                </Badge>
              </span>
            ))
          ) : (
            <Badge bg="info" style={{ fontSize: 12 }}>
              {t("PopupCategoriesSummaryNoSelection")}
            </Badge>
          )}
        </div>
      </Popup>

      {/* End of PopupArea */}
    </TreeListContainer>
  );
}

export default CategoriesTreeList;
