import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  padding: 10px;
  border: 6px solid black;
  border-bottom-color: transparent;
  border-radius: 22px;
  animation: ${rotate} 1s infinite linear;
  height: 0;
  width: 0;
`;

export const StyledLink = styled(Link)`
  padding: 10px 15px;
  color: black;
  text-decoration: none;
  text-align: center;
`;

export const StyledHeaderLink = styled(Link)`
  padding: 10px 10px;
  color: white !important;
  text-align: center;
`;
