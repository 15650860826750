import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import api_keys from "../../utils/constants/api_keys.json";
import { useTranslation } from "react-i18next";
import { Category } from "../../models/category";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ModalPDF.module.css";
import { LoadPanel } from "devextreme-react/load-panel";

const CategoryImage = styled.img`
  padding: 5px;
  height: 40px;
`;

const DivCategory = styled.div<{ color: string }>`
  background-color: ${(prop) => prop.color};
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const SpanCategory = styled.span`
  padding: 10px;
  font-size: 20px;
  color: white;
`;

const PDFContainer = styled.div`
  width: 100%;
  padding: 20px 10px 20px 10px;
  word-wrap: break-word;
`;

const ButtonText = styled.span`
  padding-left: 5px;
`;

const ButtonsContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: space-around;
`;

const ButtonFavorite = styled.button<{ color: string }>`
  background-color: ${(prop) => prop.color};
  font-size: 1em;
  margin: 5px 10px;
  padding: 0.25em 1em;
  border: 2px solid #fff;
  border-radius: 3px;
  &:hover {
    box-shadow: 5px 5px 5px light-gray;
    transform: scale(1.1);
  }
`;

const ModalPDF = ({
  setIsModalOpen,
  documentReference,
  documentRevisionId,
  categoryCode,
  searchParam,
}: any) => {
  const [fileUrl, setFileURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<Category>(
    {} as Category
  );

  //Translation module
  const { t } = useTranslation();
  //*******************
  const position = { of: "#main-container-wrapper" };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      searchPlugin: {
        keyword: [searchParam && searchParam !== "all" ? searchParam : ""],
      },
    },
  });

  const base64toBlob = (base64: string) => {
    const bytes = atob(base64);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  const setFileToHook = (base64: string) => {
    const blobContent = base64toBlob(base64);
    const url = URL.createObjectURL(blobContent);
    setFileURL(url);
  };

  const getDocumentRevisionById = () => {
    setIsLoading(true);
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getDocumentRevisionById}?documentRevisionId=${documentRevisionId}&categoryCode=${categoryCode}`
      )
      .then((response) => {
        if (response.data.payload)
          setFileToHook(response.data.payload.blobBase64);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCategoryByCode = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategoryByCode}?categoryCode=${categoryCode}`
      )
      .then((response) => {
        setSelectedCategory(response.data.payload);
        return response.data.payload;
      });
  };

  useEffect(() => {
    //api call to get all documents related to the targeted category props
    getCategoryByCode();
    getDocumentRevisionById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsModalOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <LoadPanel
            visible={isLoading}
            showIndicator={true}
            shading={true}
            shadingColor="rgba(0,0,0,0.4)"
            showPane={false}
            position={position}
            message={t("LoadingData")!}
          />
          <PDFContainer id="pdfContainer">
            <Row>
              <Col>
                {selectedCategory && categoryCode && (
                  <DivCategory color={selectedCategory.color}>
                    <div>
                      <CategoryImage src={selectedCategory.base64Image} />
                      <SpanCategory color={selectedCategory.color}>
                        {t(
                          categoryCode.charAt(0).toUpperCase() +
                            categoryCode.slice(1)
                        ) +
                          " - " +
                          t("PdfPreview") +
                          (!documentReference ? "" : " - " + documentReference)}
                      </SpanCategory>
                    </div>
                    <ButtonsContainer>
                      <ButtonFavorite
                        color={selectedCategory.color}
                        onClick={() => setIsModalOpen(false)}
                      >
                        <FontAwesomeIcon icon={["fas", "close"]} />
                        <ButtonText>{t("PopupClose")}</ButtonText>
                      </ButtonFavorite>
                    </ButtonsContainer>
                  </DivCategory>
                )}
              </Col>
            </Row>
            {fileUrl && (
              <div
                className="rpv-core__viewer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  color: "black !important",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    color: "black",
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.js">
                    <div
                      style={{
                        height: "85vh",
                        width: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Viewer
                        fileUrl={fileUrl}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                  </Worker>
                </div>
              </div>
            )}
          </PDFContainer>
        </div>
      </div>
    </>
  );
};

export default ModalPDF;
