import React from "react";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import { Navigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation, WithTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import {
  loggedUser,
  isUserAdmin,
  isUserEditor,
} from "../../utils/functions/functionsUtils";
import { LoadPanel } from "devextreme-react/load-panel";
import { getLocalStorageItems } from "../../utils/functions/functionsUtils";
import { ScrollView } from "devextreme-react";

const DashboardContainer = styled.div`
  width: 100%;
  padding: 20px 10px 20px 0px;
`;

const CategoryImage = styled.img`
  padding: 3px;
  height: 40px;
`;

const SpanDashboard = styled.span`
  color: #141b4d;
  font-size: large;
`;

const SpanFooter = styled.span`
  font-size: medium;
`;

const SpanCategoryContent = styled.span`
  color: white;
  font-size: medium;
`;

const SpanCategoryTitle = styled.span`
  color: black;
  font-size: medium;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ManageButtonText = styled.span`
  padding-left: 5px;
`;

const SpanTitle = styled.span`
  font-size: 27px;
  color: #141b4d;
`;

interface IProps extends WithTranslation {
  prop: any;
}

class Dashboard extends React.Component<IProps, any> {
  i18nextLng = getLocalStorageItems("i18nextLng", false);

  constructor(props: any) {
    super(props);
    this.state = {
      dashboardData: {},
      loadingDashboarData: true,
      currentLang: this.i18nextLng,
    };
    this.getDashboardData();
  }

  getDashboardData() {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getDashboardData_URL}`
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (isUserAdmin() || isUserEditor()) {
            toast.success(this.props.t("GetDashbrdDataSuccess"), {
              position: "top-right",
            });
          }
        } else {
          toast.error(response.data.message, {
            position: "top-right",
          });
        }
        this.setState({
          dashboardData: response.data.payload,
          loadingDashboarData: false,
        });
      })
      .catch(() => {
        this.setState({ loadingDashboarData: false });
      });
  }

  setCategoryCode(categoryCode: string) {
    const path = `/categoryFilesStatus/${categoryCode}`;
    return path;
  }

  componentDidMount() {
    this.props.i18n.on("languageChanged", (data: string) => {
      this.setState({ currentLang: data });
    });
  }

  render() {
    return (
      <ScrollView height={window.innerHeight - 150} direction="vertical">
        <DashboardContainer>
          <LoadPanel
            visible={this.state.loadingDashboarData}
            showIndicator={true}
            shading={true}
            shadingColor="rgba(0,0,0,0.4)"
            showPane={false}
            message={this.props.t("LoadingDashboard")!}
          />
          <Row>
            <Col className="d-flex">
              <SpanTitle className="themedText">
                {this.props.t("MenuDashboard")}{" "}
              </SpanTitle>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="d-flex">
              <SpanDashboard className="themedText">
                {this.props.t("Welcome")}, {loggedUser()?.firstName}{" "}
                {loggedUser()?.name}
              </SpanDashboard>
            </Col>
          </Row>
          <Row className="g-4 pt-4">
            <Col>
              <Card
                border="dark"
                className="text-center"
                style={{ width: "30rem", height: "18rem" }}
              >
                <Card.Header>
                  <SpanDashboard>
                    {this.props.t("ManageRequest_title")}
                  </SpanDashboard>
                </Card.Header>
                <Card.Body>
                  <Card.Title>
                    {this.state.dashboardData.totalSubscriptionRequest > 0
                      ? this.props.t("SubscriptionRequest_interp", {
                          count:
                            this.state.dashboardData.totalSubscriptionRequest,
                        })
                      : this.props.t("SubscriptionRequest_no_interp")}
                  </Card.Title>
                  <Row className="text-end mt-5">
                    <Col>
                      <Button variant="primary">
                        <StyledLink to="/subscriptions">
                          <FontAwesomeIcon
                            icon={["fas", "gears"]}
                            style={{ color: "#141B4D" }}
                          />
                          <ManageButtonText>
                            {this.props.t("HandleRequests")}
                          </ManageButtonText>
                        </StyledLink>
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
                {this.state.dashboardData.totalSubscriptionRequest > 0 ? (
                  <Card.Footer className="text-muted">
                    <SpanFooter>
                      {this.props.t("LastRequest")}
                      {
                        this.state.dashboardData.lastSubscriptionRequestName
                      }{" "}
                      {this.props.t("On_date")}
                      {this.state.dashboardData.lastSubscriptionRequestDate}
                    </SpanFooter>
                  </Card.Footer>
                ) : (
                  <Card.Footer className="text-muted">
                    <SpanFooter>
                      {this.props.t("UpdateRequest_no_interp")}
                    </SpanFooter>
                  </Card.Footer>
                )}
              </Card>
            </Col>

            <Col>
              <Card
                border="dark"
                className="text-center"
                style={{ width: "30rem", height: "18rem" }}
              >
                <Card.Header>
                  <SpanDashboard>
                    {this.props.t("UpdateRequest_title")}
                  </SpanDashboard>
                </Card.Header>
                <Card.Body>
                  <Card.Title>
                    {this.state.dashboardData.totalUserChangesRequest > 0
                      ? this.props.t("UpdateRequest_interp", {
                          count:
                            this.state.dashboardData.totalUserChangesRequest,
                        })
                      : this.props.t("UpdateRequest_no_interp")}
                  </Card.Title>
                  <Row className="text-end mt-5">
                    <Col>
                      <Button variant="primary">
                        <StyledLink to="/subscriptionsmodification">
                          <FontAwesomeIcon
                            icon={["fas", "gears"]}
                            style={{ color: "#141B4D" }}
                          />
                          <ManageButtonText>
                            {this.props.t("HandleRequests")}
                          </ManageButtonText>
                        </StyledLink>
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
                {this.state.dashboardData.totalUserChangesRequest > 0 ? (
                  <Card.Footer className="text-muted">
                    <SpanFooter>
                      {this.props.t("LastRequest")}
                      {this.state.dashboardData.lastUserChangesRequestName}{" "}
                      {this.props.t("On_date")}
                      {this.state.dashboardData.lastUserChangesRequestDate}
                    </SpanFooter>
                  </Card.Footer>
                ) : (
                  <Card.Footer className="text-muted">
                    <SpanFooter>
                      {this.props.t("UpdateRequest_no_interp")}
                    </SpanFooter>
                  </Card.Footer>
                )}
              </Card>
            </Col>

            <Col>
              <Card
                border="dark"
                className="text-center"
                style={{ width: "30rem", height: "18rem" }}
              >
                <Card.Header>
                  <SpanDashboard>
                    {this.props.t("DeleteRequest_title")}
                  </SpanDashboard>
                </Card.Header>
                <Card.Body>
                  <Card.Title>
                    {this.state.dashboardData.totalDeleteAccountRequest > 0
                      ? this.props.t("DeleteRequest_interp", {
                          count:
                            this.state.dashboardData.totalDeleteAccountRequest,
                        })
                      : this.props.t("DeleteRequest_no_interp")}
                  </Card.Title>
                  <Row className="text-end mt-5">
                    <Col>
                      <Button variant="primary">
                        <StyledLink
                          to="/subscriptionsdeletion"
                          aria-label={this.props.t("HandleRequests")}
                          aria-describedby={this.props.t("HandleRequests")}
                          role={"link"}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "gears"]}
                            style={{ color: "#141B4D" }}
                          />
                          <ManageButtonText>
                            {this.props.t("HandleRequests")}
                          </ManageButtonText>
                        </StyledLink>
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
                {this.state.dashboardData.totalDeleteAccountRequest > 0 ? (
                  <Card.Footer className="text-muted">
                    <SpanFooter>
                      {this.props.t("LastRequest")}
                      {
                        this.state.dashboardData.lastDeleteAccountRequestName
                      }{" "}
                      {this.props.t("On_date")}
                      {this.state.dashboardData.lastDeleteAccountRequestDate}
                    </SpanFooter>
                  </Card.Footer>
                ) : (
                  <Card.Footer className="text-muted">
                    <SpanFooter>
                      {this.props.t("UpdateRequest_no_interp")}
                    </SpanFooter>
                  </Card.Footer>
                )}
              </Card>
            </Col>
          </Row>
          {this.state.submitted && (
            <Navigate to={"/subscriptions"} state={this.state} />
          )}
          <Row className="mt-5">
            <Col className="d-flex justify-content-center align-items-center">
              <Card
                border="dark"
                className="text-center"
                style={{ width: "50rem" }}
              >
                <Card.Header>
                  <SpanDashboard>
                    {this.props.t("DashboardSummary")}
                  </SpanDashboard>
                </Card.Header>
                <Card.Body style={{ padding: "30px" }}>
                  <Row className="text-end mt-6">
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <SpanCategoryTitle className="p-1">
                              {this.props.t("icon")}
                            </SpanCategoryTitle>
                          </th>
                          <th>
                            <SpanCategoryTitle className="p-1">
                              {this.props.t("CategoryName")}
                            </SpanCategoryTitle>
                          </th>
                          <th>
                            <SpanCategoryTitle className="p-1">
                              {this.props.t("CategoriesStatus1")}
                            </SpanCategoryTitle>
                          </th>
                          <th>
                            <SpanCategoryTitle className="p-1">
                              {this.props.t("CategoriesStatus2")}
                            </SpanCategoryTitle>
                          </th>
                          <th>
                            <SpanCategoryTitle className="p-1">
                              {this.props.t("CategoriesStatus3")}
                            </SpanCategoryTitle>
                          </th>
                          <th>
                            <SpanCategoryTitle className="p-1">
                              {this.props.t("link")}
                            </SpanCategoryTitle>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dashboardData.categories &&
                        this.state.dashboardData.categories.length ? (
                          this.state.dashboardData.categories.map((a: any) => (
                            <tr
                              key={a.categoryCode}
                              style={{
                                backgroundColor: a.categoryColor,
                                verticalAlign: "middle",
                              }}
                            >
                              <td
                                style={{
                                  backgroundColor: a.categoryColor,
                                }}
                              >
                                <CategoryImage
                                  src={a.categoryIcon}
                                  aria-label={a.categoryNameEn!}
                                  alt={a.categoryNameEn!}
                                  title={a.categoryNameEn!}
                                  aria-labelledby={a.categoryNameEn!}
                                  role="img"
                                />
                              </td>
                              {this.state.currentLang === "GB" && (
                                <td
                                  style={{
                                    backgroundColor: a.categoryColor,
                                  }}
                                >
                                  <SpanCategoryContent className="p-1">
                                    {a.categoryNameEn}
                                  </SpanCategoryContent>
                                </td>
                              )}
                              {this.state.currentLang === "DE" && (
                                <td
                                  style={{
                                    backgroundColor: a.categoryColor,
                                  }}
                                >
                                  <SpanCategoryContent className="p-1">
                                    {a.categoryNameDe}
                                  </SpanCategoryContent>
                                </td>
                              )}
                              {this.state.currentLang === "FR" && (
                                <td
                                  style={{
                                    backgroundColor: a.categoryColor,
                                  }}
                                >
                                  <SpanCategoryContent className="p-1">
                                    {a.categoryName}
                                  </SpanCategoryContent>
                                </td>
                              )}
                              <td
                                style={{
                                  backgroundColor: a.categoryColor,
                                }}
                              >
                                <SpanCategoryContent className="p-1">
                                  {a.totalDraft}
                                </SpanCategoryContent>
                              </td>
                              <td
                                style={{
                                  backgroundColor: a.categoryColor,
                                }}
                              >
                                <SpanCategoryContent className="p-1">
                                  {a.totalPublished}
                                </SpanCategoryContent>
                              </td>
                              <td
                                style={{
                                  backgroundColor: a.categoryColor,
                                }}
                              >
                                <SpanCategoryContent className="p-1">
                                  {a.totalArchived}
                                </SpanCategoryContent>
                              </td>
                              <td
                                style={{
                                  backgroundColor: a.categoryColor,
                                }}
                              >
                                <Button
                                  variant="primary"
                                  aria-label={a.categoryNameEn!}
                                  aria-labelledby={a.categoryNameEn!}
                                  title={a.categoryNameEn!}
                                  role="link"
                                  style={{
                                    marginTop: "4px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  <StyledLink
                                    to={this.setCategoryCode(a.categoryCode)}
                                    aria-label={a.categoryNameEn!}
                                    aria-labelledby={a.categoryNameEn!}
                                    title={a.categoryNameEn!}
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "link"]}
                                      style={{ color: "white !important" }}
                                      className="icon-color-white"
                                    />
                                  </StyledLink>
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={9}>
                              {this.props.t("DashboardSummaryNoResults")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </DashboardContainer>
      </ScrollView>
    );
  }
}

export default withTranslation()(Dashboard);
