import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React from "react";

function gridCellData(gridData: any) {
  return gridData.data;
}

export default function LightButton(cellData: any) {
  //Translation module
  const { t } = useTranslation();
  //*******************
  return gridCellData(cellData).data.eventName.toLowerCase() === "create" ? (
    <Button variant="dark" className="badge" size="sm" disabled={true}>
      {t("CreatedEvent")}
    </Button>
  ) : gridCellData(cellData).data.eventName.toLowerCase() === "edit" ? (
    <Button variant="primary" className="badge" size="sm" disabled={true}>
      {t("EditEvent")}
    </Button>
  ) : (
    <Button variant="danger" className="badge" size="sm" disabled={true}>
      {t("DeleteEvent")}
    </Button>
  );
}
