import React, { useEffect, useState } from "react";
import {
  DataGrid,
  Column,
  Editing,
  Lookup,
  HeaderFilter,
  SearchPanel,
  Paging,
  Form,
  Button,
  RequiredRule,
  EmailRule,
  Selection,
  Texts,
  Popup as PopupGrid,
  Pager,
  StringLengthRule,
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { custom } from "devextreme/ui/dialog";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import "whatwg-fetch";
import api_keys from "../../utils/constants/api_keys.json";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ScrollView } from "devextreme-react";

const HomeContainer = styled.div`
  padding: 20px 10px 20px 0px;
`;

const SpanTitle = styled.span`
  font-size: 20px;
  color: #141b4d;
`;

const SpanTotalCount = styled.span`
  padding-left: 20px;
  color: #141b4d;
  font-size: 20px;
`;

function UserManagement() {
  const [showPageSizeSelector] = useState(true);
  const [showInfo] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentLoginHistory, setCurrentLoginHistory] = useState({});
  const [updatedRow, setUpdatedRow] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [showNavButtons] = useState(true);
  const [usersData, setUsersData] = useState([]);

  //Translation module
  const { t } = useTranslation();
  //*******************

  const hidePopup = () => {
    setPopupVisible(false);
  };

  //Variables
  const allowedPageSizes = [15];
  let dataGrid: any;
  const saveButtonOptions = {
    text: t("PopupSave"),
    icon: "save",
    elementAttr: {
      class: "see-document-button",
    },
    type: "success",
    onClick: (e: any) => {
      dataGrid.instance.saveEditData();
    },
  };
  const cancelButtonOptions = {
    text: t("PopupCancel"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
    onClick: (e: any) => {
      dataGrid.instance.cancelEditData();
    },
  };
  const closePopupButtonOptions = {
    onClick: hidePopup,
    text: t("PopupClose"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
  };
  //*******************

  useEffect(() => {
    getRoles();
    getUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDeleteOptionDisabled = (e: any) => {
    return e.row.data.isDeleted;
  };

  const languageOptions = {
    dataSource: ["FR", "EN", "DE"],
  };

  const refreshCount = (e: any) => {
    if (!dataGrid) return e.component.getDataSource()._totalCount;
    return dataGrid.instance.getDataSource()._totalCount;
  };

  const onContentReady = (e: any) => {
    const count: number = refreshCount(e);
    setTotalCount(count);
  };

  const getRoles = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getRoles_URL}`
      )
      .then((response) => {
        showNotifications(response.data, false, "");
        setRoleList(response.data.payload);
        return response.data.payload;
      });
  };

  const getUsersData = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getUsersData_URL}`
      )
      .then((response) => {
        showNotifications(response.data, false, "");
        setUsersData(response.data.payload.data);
      });
  };

  const getUserLoginHistory = (userId: number) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getLoginHistory_URL}?userId=${userId}`
      )
      .then((response) => {
        showNotifications(response.data, false, "");
        setCurrentLoginHistory(response.data.payload);
        showPopup();
      });
  };

  const onRowUpdating = (options: any) => {
    options.newData = Object.assign(options.oldData, options.newData);
    setUpdatedRow(options.newData);
  };

  const onRowUpdated = (options: any) => {
    return jwtInterceptor
      .put(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.updateUser_URL}`,
        updatedRow
      )
      .then((response) => {
        showNotifications(response.data, true, "UserManagemenetUpdateUser");
        getUsersData();
        return response.data.payload;
      });
  };

  const deleteUsersData = (e: any) => {
    const userData = e.row.data;

    let confirmDialog = custom({
      title: `${t("DeleteProfile")}: ${userData.userName} ?`,
      messageHtml: `<b>${t("DeleteUserConfirm")}</b>`,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Cancel")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    confirmDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        return jwtInterceptor
          .post(
            `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.deleteUser_URL}`,
            userData
          )
          .then((response) => {
            showNotifications(response.data, true, "UserManagemenetDeleteUser");
            e.event.preventDefault();

            dataGrid.instance.refresh();
            return response.data.payload;
          });
      }
    });
  };

  const showUserDetails = (e: any) => {
    const userData = e.row.data;

    getUserLoginHistory(userData.id);

    e.event.preventDefault();
  };

  const showNotifications = (
    data: any,
    showOnSuccess: boolean,
    option: string
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess) {
        toast.success(t(option), {
          position: "top-right",
        });
      }
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const showPopup = () => {
    setPopupVisible(true);
  };

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <HomeContainer>
        <Row>
          <Col className="d-flex">
            <SpanTitle className="screenTitle">
              {" "}
              {t("Usermanagement")}{" "}
            </SpanTitle>
            <SpanTotalCount className="screenTitle">
              {t("TotalUsers")} ({totalCount})
            </SpanTotalCount>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataGrid
              id="grid"
              keyExpr="id"
              columnAutoWidth={true}
              showBorders={true}
              dataSource={usersData}
              onContentReady={onContentReady}
              onRowUpdating={onRowUpdating}
              onRowUpdated={onRowUpdated}
              focusedRowEnabled={true}
              rowAlternationEnabled={true}
              noDataText={t("NoUserList")!}
              autoNavigateToFocusedRow={true}
              remoteOperations={false}
              ref={(ref) => {
                dataGrid = ref;
              }}
            >
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} />
              <Paging
                defaultPageSize={15}
                pageSize={15}
                defaultPageIndex={0}
                enabled={true}
              />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode="full"
                showPageSizeSelector={showPageSizeSelector}
                showInfo={showInfo}
                showNavigationButtons={showNavButtons}
              />

              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />

              <Selection mode="single" />

              <Editing
                refreshMode="reshape"
                mode="popup"
                useIcons={true}
                allowUpdating={true}
              >
                <Texts confirmDeleteMessage={t("PopupDeleteMessage")!} />
                <PopupGrid
                  title="User Information"
                  showTitle={false}
                  width={850}
                  height={750}
                >
                  <ToolbarItem
                    toolbar="bottom"
                    location="after"
                    widget="dxButton"
                    options={saveButtonOptions}
                  />
                  <ToolbarItem
                    toolbar="bottom"
                    location="after"
                    widget="dxButton"
                    options={cancelButtonOptions}
                  />
                </PopupGrid>
                <Form>
                  <Item
                    itemType="group"
                    caption={t("Userinfo")!}
                    colCount={2}
                    colSpan={2}
                  >
                    <Item dataField="id" />
                    <Item dataField="userName" />
                    <Item dataField="roleId" />
                    <Item dataField="firstName" />
                    <Item dataField="name" />
                    <Item dataField="email" />
                    <Item dataField="employer" />
                    <Item dataField="sector" />
                    <Item dataField="union" />
                    <Item dataField="phoneNumber" />
                    <Item
                      dataField="language"
                      editorType="dxSelectBox"
                      editorOptions={languageOptions}
                    ></Item>
                  </Item>
                  <Item
                    itemType="group"
                    caption={t("UserinfoAdmin")!}
                    colCount={2}
                    colSpan={2}
                  >
                    <Item dataField="createdOn" />
                    <Item dataField="dueDate" />
                    <Item dataField="isUserConfirmed" />
                    <Item dataField="userConfirmedBy" />
                    <Item dataField="isDeleted" />
                    <Item dataField="deleteReason" />
                    <Item dataField="isPasswordReset" />
                  </Item>
                  <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={closePopupButtonOptions}
                  />
                </Form>
              </Editing>

              <Column type="buttons" caption={t("Actions")!} width={150}>
                <Button name="edit" hint={t("HintEdit")!} />
                <Button
                  hint={t("HintInnactivateUser")!}
                  icon="trash"
                  onClick={deleteUsersData}
                  disabled={isDeleteOptionDisabled}
                />
                <Button
                  hint={t("HintLoginHistory")!}
                  icon="detailslayout"
                  onClick={showUserDetails}
                />
              </Column>
              <Column dataField="id" caption={t("UserId")!}></Column>
              <Column dataField="userName" caption={t("Username")!}></Column>
              <Column dataField="roleId" caption={t("Role")!}>
                <RequiredRule />
                <Lookup
                  dataSource={roleList}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
              <Column dataField="firstName" caption={t("Firstname")!}>
                <RequiredRule />
              </Column>
              <Column dataField="name" caption={t("Name")!}>
                <RequiredRule />
              </Column>
              <Column dataField="email" caption={t("Email")!}>
                <RequiredRule />
                <EmailRule message="Invalid Email" />
              </Column>
              <Column
                dataField="representativePerson"
                caption={t("RepresentativePerson")!}
              ></Column>
              <Column dataField="employer" caption={t("Employer")!}>
                <RequiredRule />
              </Column>
              <Column dataField="sector" caption={t("Sector")!}>
                <RequiredRule />
              </Column>
              <Column dataField="union" caption={t("Union")!}></Column>
              <Column
                dataField="phoneNumber"
                dataType="number"
                caption={t("Phone")!}
              >
                <StringLengthRule message={t("MaxLengthPhone")!} max={12} />
              </Column>
              <Column dataField="language" caption={t("Language")!}>
                <RequiredRule />
              </Column>
              <Column
                dataField="createdOn"
                caption={t("CreatedOn")!}
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
              ></Column>
              <Column
                dataField="dueDate"
                caption={t("DueDate")!}
                dataType="date"
                format="dd/MM/yyyy HH:mm:ss"
              ></Column>
              <Column
                dataField="isUserConfirmed"
                caption={t("UserConfirmed")!}
                dataType="boolean"
              ></Column>
              <Column
                dataField="userConfirmedBy"
                caption={t("UserConfirmedBy")!}
              ></Column>
              <Column
                dataField="isDeleted"
                caption={t("UserDeleted")!}
                dataType="boolean"
              ></Column>
              <Column
                dataField="deleteReason"
                caption={t("DeleteReason")!}
              ></Column>
              <Column
                dataField="isPasswordReset"
                caption={t("PwdReset")!}
                dataType="boolean"
              ></Column>
              <Column
                dataField="resetTokenURL"
                caption={t("ResetTokenURL")!}
              ></Column>
            </DataGrid>
          </Col>
        </Row>

        <Popup
          visible={popupVisible}
          onHiding={hidePopup}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          title={t("PopupLoginHistory")!}
          container=".dx-viewport"
          width={550}
          height={650}
        >
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closePopupButtonOptions}
          />
          <DataGrid
            id="gridPopup"
            keyExpr="id"
            dataSource={currentLoginHistory}
            noDataText={t("NoLoginHistory")!}
            focusedRowEnabled={true}
            rowAlternationEnabled={true}
            showRowLines={true}
            showColumnLines={false}
            showBorders={true}
          >
            <Paging defaultPageSize={10} />
            <Column dataField="id" />
            <Column
              dataField="createdOn"
              dataType="date"
              format="dd/MM/yyyy HH:mm:ss"
            />
            <Column dataField="userName" />
          </DataGrid>
        </Popup>
      </HomeContainer>
    </ScrollView>
  );
}

export default UserManagement;
