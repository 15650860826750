import { FC, createContext, ReactNode } from "react";
import api_keys from "../constants/api_keys.json";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import { MenuPermission } from "../../models/MenuPermission";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../../models/user";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  setLocalStorageItems,
  loggedUser,
  isUserAdmin,
  isUserEditor,
} from "../../utils/functions/functionsUtils";
import React from "react";
type AuthTestType = {
  getUserInfo: () => UserInfo;
  login: (arg: any) => void;
};

export const UserAuthContext = createContext<AuthTestType | null>(null);

type Props = {
  children?: ReactNode;
};

const UserAuthProvider: FC<Props> = ({ children }) => {
  //hooks
  const navigate = useNavigate();

  //Translation module
  const { t } = useTranslation();
  //*******************

  //*******************
  //functions
  const getUserInfo = () => {
    var ouser = loggedUser();
    if (!ouser) {
      navigate("/");
    }
    return ouser;
  };

  const addUser = (user: UserInfo) => {
    setLocalStorageItems("user", user, true);
  };

  const addMenus = (menuItems: MenuPermission[]) => {
    setLocalStorageItems("menus", menuItems, true);
  };

  const login = async (payload: any) => {
    payload.Password = `${btoa(payload.Password)}${api_keys.cslIdentifier}`;
    await axios
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.login_URL}`,
        payload,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": api_keys["subsriptionKey"],
            "Content-Type": "application/json",
            "Access-control-Allow-Origin":
              "https://searchplatformapim.azure-api.net, http://localhost:3000, https://csl-cdd-dev-apim.azure-api.net",
            "Access-Control-Allow-Headers":
              "Content-Type, Authorization,X-Requested-With",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          },
        }
      )
      .then((apiResponse) => {
        if (apiResponse.data.payload !== null) {
          setLocalStorageItems("tokens", apiResponse.data.payload.token, true);
          addUser(apiResponse.data.payload as UserInfo);
        }

        //Notifications
        if (apiResponse.data.statusCode === 200) {
          jwtInterceptor
            .get(
              `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getMenus_URL}?roleId=${apiResponse.data.payload.roleId}`
            )
            .then((response) => {
              if (response.data.payload !== null) {
                let menuItemst: MenuPermission[] = response.data.payload;
                addMenus(menuItemst);
                if (isUserAdmin() || isUserEditor()) {
                  toast.success(t("LoginSuccess"), {
                    position: "top-right",
                  });
                }
                let user = getUserInfo();
                navigate(
                  user.role === "Administrator" ? "/dashboard" : "/services"
                );
              }
            });
        } else {
          toast.error(t("WrongCredentials") + t("TryAgain"), {
            position: "top-right",
          });
        }

        return false;
      });
  };
  return (
    <UserAuthContext.Provider value={{ login, getUserInfo }}>
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthProvider;
