import ReactFlagsSelect from "react-flags-select";
import styled from "styled-components";
import React from "react";

const LangSelector = styled(ReactFlagsSelect)`
  padding-right: 5px;
  button {
    background: none;
    border: none;
    padding: 2px;
  }
  button::after {
    display: none !important;
  }
`;

const onSelectLanguage = (code: string): void => {
  //do nothing, only because its mandatory
  return;
};

function LanguageFlags(rowdata: any) {
  if (
    rowdata.data &&
    rowdata.data.data &&
    rowdata.data.data.multiLanguage &&
    rowdata.data.data.multiLanguage.length
  ) {
    return rowdata.data.data.multiLanguage.map((a: any) => (
      <LangSelector
        selected={a.language}
        onSelect={onSelectLanguage}
        countries={["FR", "GB", "DE"]}
        customLabels={{ GB: "EN", FR: "FR", DE: "DE" }}
        showSelectedLabel={false}
        showOptionLabel={false}
        fullWidth={false}
        disabled
      />
    ));
  } else if (
    rowdata.data &&
    rowdata.data.data &&
    (rowdata.data.data.lastLanguage || rowdata.data.data.language)
  ) {
    return (
      <LangSelector
        selected={rowdata.data.data.lastLanguage || rowdata.data.data.language}
        onSelect={onSelectLanguage}
        countries={["FR", "GB", "DE"]}
        customLabels={{ GB: "EN", FR: "FR", DE: "DE" }}
        showSelectedLabel={false}
        showOptionLabel={false}
        fullWidth={false}
        disabled
      />
    );
  }
  return <span></span>;
}

export default LanguageFlags;
