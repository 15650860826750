import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

const SECRET_KEY = "CSL_LocalStorage_SecretKey";
const unnecessaryAuthenticationPages = [
  "/",
  "/signUp",
  "/aboutProject",
  "/generalConditions",
  "/gdpr",
  "/requestResetPassword",
  "resetpassword",
  "categoryfilesstatus",
  "view",
];
const childPages = ["categoryDetails"];
const resetPasswordLink = "resetpassword";
const categoryStatus = "categoryfilesstatus";
const alLCategories = "allcategories";
const previewDocument = "view";

export const isInternalPage = (page: any) => {
  const checkInternalPage = unnecessaryAuthenticationPages.find((x) =>
    x.toLowerCase().includes(page.toLowerCase())
  );
  const isResetPassword = page.toLowerCase().includes(resetPasswordLink);
  const isCategoryStatus = page.toLowerCase().includes(categoryStatus);
  const isPreviewDocument = page.toLowerCase().includes(previewDocument);
  const isAlLCategories = page.toLowerCase().includes(alLCategories);

  return (
    checkInternalPage !== undefined ||
    isResetPassword ||
    isCategoryStatus ||
    isPreviewDocument ||
    isAlLCategories
  );
};

export const getTranslationInternalPages = (page: any, t: any) => {
  var translation =
    page === "/"
      ? t("InternalPageLogin")
      : t(`InternalPage${page.replace("/", "")}`);

  translation = page.toLowerCase().includes(resetPasswordLink)
    ? t(`InternalPage${resetPasswordLink}`)
    : translation;

  translation = page.toLowerCase().includes(categoryStatus)
    ? t(`InternalPage${categoryStatus}`)
    : translation;

  translation = page.toLowerCase().includes(previewDocument)
    ? t(`InternalPage${previewDocument}`)
    : translation;

  translation = page.toLowerCase().includes(alLCategories)
    ? t(`InternalPage${alLCategories}`)
    : translation;

  return translation;
};

export const getTranslationPages = (page: any, t: any) => {
  const isChild = childPages.find((x) =>
    page.toLowerCase().includes(x.toLowerCase())
  );

  var pageTranslate = isChild ? isChild : page.replace("/", "");

  if (
    pageTranslate.includes("usersjurisprudences") ||
    pageTranslate.includes("services")
  ) {
    return "";
  }

  pageTranslate =
    pageTranslate.charAt(0).toUpperCase() + pageTranslate.slice(1);
  var translation = t(pageTranslate);

  return translation;
};

export const isChildPage = (page: any) => {
  const isChild = childPages.find((x) =>
    page.toLowerCase().includes(x.toLowerCase())
  );

  return isChild;
};

export const formatHref = (link: string, t: any) => {
  const noSlash = link.replace("/", "");
  return t(noSlash.charAt(0).toUpperCase() + noSlash.slice(1));
};

export const setColMenuClass = (isFullScreen: boolean) => {
  let className = "col-menu-nologged";

  if (!isUserLoggedIn()) return className;

  if (!isFullScreen) className = "col-menu-loggedin-menu-collapsed";

  if (isFullScreen) className = "col-menu-loggedin-menu-not-collapsed";

  return className;
};

export const isUserLoggedIn = () => {
  let user = getLocalStorageItems("user", true);
  return user?.userName !== undefined;
};

export const isUserAdmin = () => {
  let user = getLocalStorageItems("user", true);
  return user?.role === "Administrator";
};

export const isUserEditor = () => {
  let user = getLocalStorageItems("user", true);
  return user?.role === "Editor";
};

export const loggedUser = () => {
  let user = getLocalStorageItems("user", true);
  return user;
};

export const setColContainerClass = (isFullScreen: boolean) => {
  let className = "col-container-nologged";

  if (!isUserLoggedIn()) return className;

  if (!isFullScreen) className = "col-container-loggedin-menu-collapsed";

  if (isFullScreen) className = "col-container-loggedin-menu-not-collapsed";

  return className;
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const isMenuItemsEmpty = () => {
  return Object.keys(getMenuItems()).length > 0 ? false : true;
};

export const getMenuItems = () => {
  let menuItems = getLocalStorageItems("menus", true);
  return menuItems;
};

export const setLocalStorageItems = (
  item: string,
  value: any,
  isCrypto: boolean
) => {
  if (isCrypto) {
    localStorage.setItem(
      item,
      CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString()
    );
  } else {
    localStorage.setItem(item, JSON.stringify(value));
  }
};

export const getLocalStorageItems = (item: string, isCrypto: boolean) => {
  const localStorageValue = localStorage.getItem(item);
  if (isCrypto && localStorageValue) {
    const decrypt = CryptoJS.AES.decrypt(
      localStorageValue,
      SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypt);
  }

  return localStorageValue ? localStorageValue : {};
};

export const displaySuccessToast = (message: string) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};

export const displayErrorToast = (message: string) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });
};
