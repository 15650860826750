import React, { useRef, useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserAuthContext } from "../../utils/context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  loggedUser,
  isUserLoggedIn,
  getMenuItems,
} from "../../utils/functions/functionsUtils";
import { ScrollView } from "devextreme-react";

const HomeContainer = styled.div`
  padding-top: 20px;
  width: 100%;
`;

const HomeTitle = styled.h1`
  font-size: 25px;
  margin-top: 15px;
`;

const DescriptionText = styled.p`
  font-size: 16px;
  text-align: justify;
`;

const BoxTitle = styled.h2`
  font-size: 18px;
`;

const LoginButtonDiv = styled.div`
  margin-top: 35px;
  max-height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const LoginButtonText = styled.span`
  padding-left: 5px;
`;

const FontSizeSpan = styled.span`
  font-size: 15px;
`;

const FontSizeDemanderReinit = styled.span`
  font-size: 12px;
`;

const AskSignUpDiv = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`;

const AskSignUpButton = styled(Button)``;

const TogglePasswordButton = styled(Button)`
  margin-left: 5px;
  height: 36px;
`;

const PasswordDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoginButton = styled(Button)`
  margin-left: 30px;
  min-width: 120px;
`;

const NeedAccessText = styled(Form.Text)`
  color: black;
`;

const FormContainer = styled(Form)`
  border: 1px solid;
  padding: 10px 25px;
  border-color: black;
  border-radius: 10px;
  min-height: 300px;
  display: block;
  margin-bottom: 40px;
`;

const FormLabel = styled(Form.Label)`
  font-size: 15px;
  display: block;
  padding-bottom: 5px;
  font-weight: 600;
`;

const FormInput = styled(Form.Control)`
  display: block;
  margin-bottom: 15px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px transparent;
    border-color: #ced4da;
  }
`;

const ForgetPassword = styled(Form.Text)`
  padding-left: 5px;
  font-size: 15px;
`;

function Home() {
  //payload prep
  const navigate = useNavigate();
  const username = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const user = useContext(UserAuthContext);
  const [icon, setIcon] = useState("eye");
  const [isLoggingIn, setLoggingIn] = useState(false);
  const handleLoginState = (st: boolean) => {
    setLoggingIn(st);
  };
  const menuItems = getMenuItems();

  //Translation module
  const { t } = useTranslation();
  //*******************

  const handleLoggedInRedirect = () => {
    if (isUserLoggedIn() && Object.keys(menuItems).length > 0) {
      switch (loggedUser().role) {
        case "Administrator": {
          navigate("/dashboard");
          break;
        }

        case "Editor":
        case "User": {
          navigate("/services");
          break;
        }
        default: {
          navigate("/");
        }
      }
    }
  };

  const loginSubmit = async (e: any) => {
    e.preventDefault();

    if (password != null && username != null) {
      handleLoginState(true);
      let payload = {
        UserName: username.current?.value,
        Password: password.current?.value,
      };
      await user?.login?.(payload);

      handleLoginState(false);
    }
    return false;
  };

  const signUp = async () => {
    navigate("/signUp");
  };

  const resetPassword = async () => {
    navigate("/requestResetPassword");
  };

  const togglePasswordVisibility = () => {
    if (password.current && password.current.type === "password") {
      password.current.type = "text";
      setIcon("eye-slash");
    } else if (password.current && password.current.type === "text") {
      password.current.type = "password";
      setIcon("eye");
    }
  };

  useEffect(() => {
    handleLoggedInRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <HomeContainer className="home">
        <Container>
          <Row>
            <Col>
              <HomeTitle>{t("DocCenter")}</HomeTitle>
              <DescriptionText>{t("Csl_intro")}</DescriptionText>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col>
              <FormContainer>
                <BoxTitle>{t("Connection")}</BoxTitle>
                <Form.Group controlId="username">
                  <FormLabel>{t("LoginPageUserName")}</FormLabel>
                  <FormInput type="text" ref={username}></FormInput>
                </Form.Group>
                <Form.Group controlId="password">
                  <FormLabel>{t("Password")}</FormLabel>
                  <PasswordDiv>
                    <FormInput type="password" ref={password} />
                    <TogglePasswordButton
                      variant="secondary"
                      size="sm"
                      onClick={togglePasswordVisibility}
                    >
                      {icon === "eye" && (
                        <FontAwesomeIcon icon={["fas", "eye"]} />
                      )}
                      {icon === "eye-slash" && (
                        <FontAwesomeIcon icon={["fas", "eye-slash"]} />
                      )}
                    </TogglePasswordButton>
                  </PasswordDiv>
                </Form.Group>
                <Form.Group id="forgetPassword">
                  <LoginButtonDiv>
                    <Form.Text muted>
                      <FontSizeDemanderReinit>
                        {t("ForgetPassword")}
                      </FontSizeDemanderReinit>{" "}
                    </Form.Text>
                    <Button variant="link" onClick={resetPassword}>
                      <FontAwesomeIcon
                        icon={["fas", "key"]}
                        style={{ color: "#141B4D" }}
                      />
                      <ForgetPassword>{t("ResetPassword")}</ForgetPassword>
                    </Button>

                    <LoginButton
                      variant="primary"
                      disabled={isLoggingIn}
                      onClick={loginSubmit}
                      type="submit"
                    >
                      {isLoggingIn && (
                        <Spinner
                          style={{ marginRight: "10px" }}
                          animation="border"
                          role="status"
                          size="sm"
                        >
                          <span className="visually-hidden">
                            {t("Loading")}
                          </span>
                        </Spinner>
                      )}
                      <FontAwesomeIcon
                        icon={["fas", "user"]}
                        style={{ color: "#141B4D" }}
                      />
                      <LoginButtonText>{t("Login")}</LoginButtonText>
                    </LoginButton>
                    <ToastContainer />
                  </LoginButtonDiv>
                </Form.Group>
              </FormContainer>
            </Col>
            <Col>
              <FormContainer>
                <BoxTitle>{t("Registration")}</BoxTitle>
                <Form.Group controlId="SignUpInfo">
                  <NeedAccessText>
                    <FontSizeSpan>{t("NeedAccess")}</FontSizeSpan>
                    <br />
                    <br />
                    <FontSizeSpan>{t("NeedAccess2")}</FontSizeSpan>
                  </NeedAccessText>
                  <AskSignUpDiv>
                    <AskSignUpButton variant="primary" onClick={signUp}>
                      <FontAwesomeIcon
                        icon={["fas", "user-plus"]}
                        style={{ color: "#141B4D" }}
                      />
                      <LoginButtonText>{t("SubRequest")}</LoginButtonText>
                    </AskSignUpButton>
                  </AskSignUpDiv>
                </Form.Group>
              </FormContainer>
            </Col>
          </Row>
        </Container>
      </HomeContainer>
    </ScrollView>
  );
}

export default Home;
