import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";

import themes from "devextreme/ui/themes";
import { currentTheme, refreshTheme } from "devextreme/viz/themes";

const getThemeData = () => [
  {
    text: "Light",
    value: "light",
  },
  {
    text: "Dark",
    value: "dark",
  },
  {
    text: "Carmin",
    value: "carmin",
  },
];

interface IProps {
  children: JSX.Element;
}
interface ContextProps {
  readonly setTheme: React.Dispatch<React.SetStateAction<string>>;
  readonly getTheme: () => any;
  readonly getThemeData: () => {
    text: string;
    value: string;
  }[];
}
const ThemeContext = createContext<ContextProps>({
  getTheme: () => null,
  getThemeData: () => [],
  setTheme: () => null,
});

const useTheme = () => useContext(ThemeContext);

const ThemeProvider = ({ children }: IProps) => {
  const activeTheme = window.localStorage["themeViewer"] || "light";
  const [_theme, setTheme] = useState(activeTheme);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTheme = useCallback(() => _theme || activeTheme, [_theme]);

  const applyTheme = useCallback(
    (theme: string) => {
      theme = getTheme();
      window.localStorage["themeViewer"] = theme;
      themes.current("generic." + theme);
      currentTheme("generic." + theme);
      refreshTheme();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getTheme]
  );

  useEffect(() => {
    applyTheme(_theme);
  }, [_theme, applyTheme]);

  return (
    <ThemeContext.Provider
      value={{ getThemeData, getTheme, setTheme }}
      children={children}
    />
  );
};

export { ThemeProvider, useTheme };
