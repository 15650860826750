import React, { useEffect, useState } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import { useTranslation } from "react-i18next";
import api_keys from "../../utils/constants/api_keys.json";
import {
  DataGrid,
  Column,
  Button,
  Selection,
  SearchPanel,
  Paging,
  Pager,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import "whatwg-fetch";
import { DeletionRequest } from "../../models/deletionRequest";
import { InvalidResetPwdRequest } from "../../models/invalidResetPwdRequest";
import { DeletedBlob } from "../../models/deletedBlob";
import { CognitiveServiceHistory } from "../../models/cognitiveServiceHistory";
import { custom } from "devextreme/ui/dialog";
import { loggedUser } from "../../utils/functions/functionsUtils";
import { toast } from "react-toastify";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { ScrollView } from "devextreme-react";

//Styled
const GridContainer = styled.div`
  width: 100%;
  padding: 20px 10px 20px 0px;
`;

//*******************

function AdminScreen() {
  //States
  const [deletedBlobs, setDeletedBlobs] = useState<DeletedBlob[]>([]);
  const [cogntiveServiceStatus, setCogntiveServiceStatus] = useState<
    CognitiveServiceHistory[]
  >([]);
  const [deletionRequests, setDeletionRequests] = useState<DeletionRequest[]>(
    []
  );
  const [invalidPasswords, setInvalidPasswords] = useState<
    InvalidResetPwdRequest[]
  >([]);
  const [selectedDeletedBlobRowKeys, setSelectedDeletedBlobRowKeys] = useState<
    DeletedBlob[]
  >([]);
  const [selectedDeletionRequestRow, setSelectedDeletionRequestRow] =
    useState<DeletionRequest>({} as DeletionRequest);
  const [loadingData, setLoadingData] = useState(false);
  const [approveButtonEnabled, setApproveButtonEnabled] = useState(false);
  const [deleteBlobButtonEnabled, setDeleteBlobButtonEnabled] = useState(false);
  const [cognitivePopupData, setCognitivePopupData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [optionPopup, setOptionPopup] = useState(1);

  //*******************

  //Variables
  let deletedBlobsGrid: any;
  //*******************
  const position = { of: "#main-container-wrapper" };
  //*******************

  //Translation module
  const { t } = useTranslation();
  //*******************

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const refreshDeletionRequests = () => {
    getRequestsForDeletion();
  };

  const refreshDeletedBlobs = () => {
    getDeleteBlobs();
  };

  const refreshInvalidResets = () => {
    getInvalidPasswordResetRequests();
  };

  const refreshCognitiveOverall = () => {
    getCognitiveServiceStatus();
  };

  //Custom Tool Bars
  const onDeletionRequestsToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxTextBox",
        options: {
          elementAttr: {
            id: "deletionrequeststextcaption",
            class: "admin-datagrid-captions",
          },
          width: 375,
          readOnly: true,
          value: t("AdminRequestDeletionLabel"),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "check",
          type: "default",
          disabled: !approveButtonEnabled,
          onClick: approveDeletionRequest,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "success",
          elementAttr: {
            id: "refreshCategoryDataGrid",
            class: "see-document-button",
          },
          onClick: refreshDeletionRequests,
        },
      }
    );
  };
  const onDeletedBlobsToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxTextBox",
        options: {
          elementAttr: {
            id: "deleteblobtextcaption",
            class: "admin-datagrid-captions",
          },
          width: 375,
          readOnly: true,
          value: t("AdminDeleteBlobsLabel"),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "trash",
          type: "success",
          disabled: !deleteBlobButtonEnabled,
          onClick: handlePurgeBlobs,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "clearformat",
          type: "default",
          onClick: clearSelection,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "success",
          elementAttr: {
            id: "refreshCategoryDataGrid",
            class: "see-document-button",
          },
          onClick: refreshDeletedBlobs,
        },
      }
    );
  };
  const onInvalidRequestsToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxTextBox",
        options: {
          elementAttr: {
            id: "invalidrequeststextcaption",
            class: "admin-datagrid-captions",
          },
          width: 375,
          readOnly: true,
          value: t("AdminInvalidResetPasswordRequestsLabel"),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "success",
          elementAttr: {
            id: "refreshCategoryDataGrid",
            class: "see-document-button",
          },
          onClick: refreshInvalidResets,
        },
      }
    );
  };
  const onCognitiveOverallToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxTextBox",
        options: {
          elementAttr: {
            id: "cognitivetextcaption",
            class: "admin-datagrid-captions",
          },
          width: 375,
          readOnly: true,
          value: t("AdminCognitiveOverallStatus"),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "tableproperties",
          type: "default",
          disabled: false,
          onClick: RecreateCognitiveServices,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "success",
          elementAttr: {
            id: "refreshCategoryDataGrid",
            class: "see-document-button",
          },
          onClick: refreshCognitiveOverall,
        },
      }
    );
  };
  const closePopupButtonOptions = {
    onClick: hidePopup,
    text: t("PopupClose"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
  };
  //*******************

  //Functions
  const getDeleteBlobs = () => {
    setLoadingData(true);

    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getDeletedBlobs}`
      )
      .then((response) => {
        setDeletedBlobs(response.data.payload);
        setLoadingData(false);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const getRequestsForDeletion = () => {
    setLoadingData(true);
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getRequestsForDeletion}`
      )
      .then((response) => {
        setDeletionRequests(response.data.payload);
        setLoadingData(false);
        return response.data.payload;
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const getInvalidPasswordResetRequests = () => {
    setLoadingData(true);
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getInvalidResetPasswordRequests}`
      )
      .then((response) => {
        setInvalidPasswords(response.data.payload);
        setLoadingData(false);
        return response.data.payload;
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const getCognitiveServiceStatus = () => {
    setLoadingData(true);

    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCoginitiveServicesStatus}`
      )
      .then((response) => {
        setCogntiveServiceStatus(response.data.payload.result);
        setLoadingData(false);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const clearSelection = () => {
    setDeleteBlobButtonEnabled(false);
    deletedBlobsGrid.instance.clearSelection();
  };

  const onDeletedBlobsSelectionChanged = (e: any) => {
    setDeleteBlobButtonEnabled(e.selectedRowsData.length > 0);
    setSelectedDeletedBlobRowKeys(e.selectedRowsData);
  };

  const onDeletionRequestSelectionChanged = (e: any) => {
    let selectedRowValue = e.selectedRowsData
      ? e.selectedRowsData[0]
      : undefined;
    setApproveButtonEnabled(
      selectedRowValue && selectedRowValue.requestCompleted === 0
    );
    setSelectedDeletionRequestRow(selectedRowValue);
  };

  const showNotifications = (
    data: any,
    showOnSuccess: boolean,
    message: string
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t(message), {
          position: "top-right",
        });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const purgeBlobs = (blobs: DeletedBlob[]) => {
    let payload = blobs;
    let stringTag = blobs
      .map((a: DeletedBlob) => `<p>${a.blobFileName}</p>`)
      .join("");

    let confirmDialog = custom({
      title: `${t("ApproveDeletion")} : ${blobs.length} Blobs ?`,
      messageHtml: `<p><b>${t("ApprovalDeletionRequest")}</b></p>
      ${stringTag}`,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Confirm")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    confirmDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        return jwtInterceptor
          .post(
            `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.deleteBlobs}`,
            payload
          )
          .then((response) => {
            showNotifications(
              response.data,
              true,
              "CategoryApproveDeleteBlobs"
            );
            getDeleteBlobs();
            return response.data.payload;
          });
      }
    });
  };

  const handlePurgeBlobs = () => {
    purgeBlobs(selectedDeletedBlobRowKeys);
  };

  const approveDeletionRequest = () => {
    const payload = selectedDeletionRequestRow;
    payload.UpdatedBy = loggedUser().userName;

    let confirmDialog = custom({
      title: `${t("ApproveFileDeletionRequest")} : ${
        selectedDeletionRequestRow.id
      } ?`,
      messageHtml: `<b>${t("ApproveFileDeletionQuestion")} ${
        selectedDeletionRequestRow.description
      } ?</b>`,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Cancel")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    confirmDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        return jwtInterceptor
          .post(
            `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.approveDeletionRequest}`,
            payload
          )
          .then((response) => {
            showNotifications(
              response.data,
              true,
              "CategoryApproveRequestDeletion"
            );
            getRequestsForDeletion();
            return response.data.payload;
          });
      }
    });
  };

  const RequestCompletedBadge = (rowdata: any) => {
    return rowdata.data && rowdata.data.data ? (
      <Badge bg="info">
        {rowdata.data.data.requestCompleted === 0
          ? t("RequestNotCompleted")
          : t("RequestCompleted")}
      </Badge>
    ) : (
      <span></span>
    );
  };

  const IndexerStatusBadge = (rowdata: any) => {
    if (rowdata.data && rowdata.data.data && rowdata.data.data.status) {
      if (rowdata.data.data.status === "Running")
        return <Badge bg="success">{rowdata.data.data.status}</Badge>;
      if (rowdata.data.data.status === "Error")
        return <Badge bg="error">{rowdata.data.data.status}</Badge>;
      if (rowdata.data.data.status === "Unknown")
        return <Badge bg="info">{rowdata.data.data.status}</Badge>;
      return <Badge bg="warning">No Info</Badge>;
    } else {
      return <span></span>;
    }
  };

  const RecreateCognitiveServices = () => {
    let confirmDialog = custom({
      title: `${t("ApproveCognitiveComponentsReCreation")}?`,
      messageHtml: `<b>${t("ApproveCognitiveComponentsQuestion")}?</b>`,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Cancel")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    confirmDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        setLoadingData(true);

        return jwtInterceptor
          .get(
            `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.generateCoginitiveServicesComponents}`
          )
          .then((response) => {
            showNotifications(response.data, true, "CognitiveRecreation");
            setLoadingData(false);

            return response.data.payload;
          })
          .finally(() => {
            setLoadingData(false);
          });
      }
    });
  };

  const showCognitiveServiceWarnings = (e: any) => {
    setCognitivePopupData(e.row.data.warnings);
    setOptionPopup(1);
    setPopupVisible(true);
    e.event.preventDefault();
  };

  const showCognitiveServiceErrors = (e: any) => {
    setCognitivePopupData(e.row.data.errors);
    setOptionPopup(2);
    setPopupVisible(true);
    e.event.preventDefault();
  };

  useEffect(() => {
    getDeleteBlobs();
    getRequestsForDeletion();
    getInvalidPasswordResetRequests();
    getCognitiveServiceStatus();
  }, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <GridContainer id="AdminContainerId">
        <LoadPanel
          visible={loadingData}
          showIndicator={true}
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          showPane={false}
          position={position}
          message={t("LoadingData")!}
        />

        <Row>
          <Col>
            <DataGrid
              id="overallCognitiveStatus"
              keyExpr="id"
              dataSource={cogntiveServiceStatus}
              onToolbarPreparing={onCognitiveOverallToolbarPreparing}
              showBorders={true}
              focusedRowEnabled={true}
              noDataText={t("NoOverallCognitiveStatus")!}
              rowAlternationEnabled={true}
              columnAutoWidth={true}
            >
              <Paging defaultPageSize={5} />
              <SearchPanel visible={true} />
              <HeaderFilter visible={true} />
              <Pager
                visible={true}
                allowedPageSizes={[5, 10]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
              />

              <Selection mode="single" />
              <Column dataField="id" visible={false} />
              <Column dataField="indexerName" visible={true} />
              <Column
                dataField="status"
                visible={true}
                cellRender={(data) => <IndexerStatusBadge data={data} />}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="itemCount"
                visible={true}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="failedItemCount"
                visible={true}
                allowHeaderFiltering={false}
              />
              <Column dataField="runStatus" visible={true} />
              <Column
                dataField="startTime"
                alignment={"center"}
                allowHeaderFiltering={false}
                sortIndex={0}
                sortOrder="desc"
              />
              <Column
                dataField="endTime"
                alignment={"center"}
                allowHeaderFiltering={false}
              />

              <Column type="buttons" caption={t("Actions")!} width={150}>
                <Button
                  hint={t("HintShowErrors")!}
                  icon="deletetable"
                  onClick={showCognitiveServiceErrors}
                />
                <Button
                  hint={t("HintShowWarnings")!}
                  icon="detailslayout"
                  onClick={showCognitiveServiceWarnings}
                />
              </Column>
            </DataGrid>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <DataGrid
              id="deletionRequestGrid"
              keyExpr="id"
              dataSource={deletionRequests}
              onToolbarPreparing={onDeletionRequestsToolbarPreparing}
              showBorders={true}
              focusedRowEnabled={true}
              rowAlternationEnabled={true}
              columnAutoWidth={true}
              noDataText={t("NoDeleteRequest")!}
              onSelectionChanged={onDeletionRequestSelectionChanged}
            >
              <SearchPanel visible={true} />
              <Paging defaultPageSize={5} />
              <Pager
                visible={true}
                allowedPageSizes={[5, 10]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
              />

              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />

              <Selection mode="single" />
              <Column dataField="id" visible={false} />
              <Column dataField="entity" />
              <Column dataField="entityId" />
              <Column
                dataField="requestCompleted"
                cellRender={(data) => <RequestCompletedBadge data={data} />}
              />
              <Column dataField="description" />
              <Column dataField="createdBy" />
              <Column
                dataField="createdOn"
                dataType="date"
                alignment={"center"}
                format="dd/MM/yyyy"
                caption={t("CreatedOn")!}
                allowHeaderFiltering={false}
              />
              <Column dataField="updatedBy" />
              <Column
                dataField="updatedOn"
                dataType="date"
                alignment={"center"}
                format="dd/MM/yyyy"
                caption={t("UpdatedOn")!}
                allowHeaderFiltering={false}
              />
            </DataGrid>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <DataGrid
              id="deletedBlobsGrid"
              keyExpr="blobFileName"
              dataSource={deletedBlobs}
              showBorders={true}
              noDataText={t("NoBlobsToDeleteRequest")!}
              focusedRowEnabled={true}
              columnAutoWidth={true}
              showRowLines={true}
              onSelectionChanged={onDeletedBlobsSelectionChanged}
              rowAlternationEnabled={true}
              onToolbarPreparing={onDeletedBlobsToolbarPreparing}
            >
              <Selection
                mode="multiple"
                allowSelectAll={true}
                showCheckBoxesMode="always"
              />
              <SearchPanel visible={true} />

              <Paging defaultPageSize={5} />
              <Pager
                visible={true}
                allowedPageSizes={[5, 10]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
              />

              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />
              <Column dataField="blobFileName" />
              <Column dataField="containerName" />
            </DataGrid>
          </Col>{" "}
          <Col>
            <DataGrid
              id="invalidRequestsGrid"
              keyExpr="id"
              dataSource={invalidPasswords}
              focusedRowEnabled={true}
              rowAlternationEnabled={true}
              onToolbarPreparing={onInvalidRequestsToolbarPreparing}
              columnAutoWidth={true}
              showBorders={true}
            >
              <SearchPanel visible={true} />

              <Paging defaultPageSize={5} />
              <Pager
                visible={true}
                allowedPageSizes={[5, 10]}
                showPageSizeSelector={true}
                showNavigationButtons={true}
              />

              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />

              <Column dataField="id" visible={false} />
              <Column dataField="email" />
              <Column
                dataField="createdOn"
                dataType="date"
                alignment={"center"}
                format="dd/MM/yyyy"
                caption={t("CreatedOn")!}
                allowHeaderFiltering={false}
              />
            </DataGrid>
          </Col>
        </Row>

        <Popup
          visible={popupVisible}
          onHiding={hidePopup}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          title={t("CognitivePopupTitle")!}
          container=".dx-viewport"
        >
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closePopupButtonOptions}
          />
          <DataGrid
            id="gridCognitivePopup"
            dataSource={cognitivePopupData}
            keyExpr="key"
            showRowLines={true}
            showColumnLines={false}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            rowAlternationEnabled={true}
            showBorders={true}
          >
            <Paging defaultPageSize={15} />
            <Pager
              visible={true}
              allowedPageSizes={[15, 30]}
              showPageSizeSelector={true}
              showNavigationButtons={true}
            />

            <Selection mode="single" />
            <Column dataField="key" width={60} />
            <Column dataField="errorMessage" visible={optionPopup === 2} />
            <Column dataField="message" visible={optionPopup === 1} />
            <Column dataField="statusCode" visible={optionPopup === 2} />
            <Column dataField="name" />
            <Column dataField="details" />
            <Column dataField="documentationLink" />
          </DataGrid>
        </Popup>
      </GridContainer>
    </ScrollView>
  );
}
export default AdminScreen;
