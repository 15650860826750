import styled from "styled-components";
import SubscriptionOperationsGrid from "../../components/SubscriptionOperationsGrid";
import React from "react";
import { ScrollView } from "devextreme-react";

const SubListContainer = styled.div``;

function SubscriptionsModification() {
  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <SubListContainer>
        <SubscriptionOperationsGrid operation={"Edit"} />
      </SubListContainer>
    </ScrollView>
  );
}

export default SubscriptionsModification;
