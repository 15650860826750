import jwtInterceptor from "../components/shared/jwtInterceptor";
import api_keys from "../utils/constants/api_keys.json";
export interface IDataInternalPage extends IDataIntervalValues {
  id?: string;
  page?: "about" | "conditions" | "gdpr";
}

export interface IDataIntervalValues {
  language: string;
  content: string;
}

export const GetInternalPages = (): Promise<IDataInternalPage[]> => {
  return jwtInterceptor
    .get(
      `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.GetInternalPages}`
    )
    .then((response) => {
      return response.data.payload;
    });
};

export const GetInternalPagesByType = (
  page: "about" | "conditions" | "gdpr"
): Promise<IDataInternalPage[]> => {
  return jwtInterceptor
    .get(
      `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.GetInternalPage}?page=${page}`
    )
    .then((response) => {
      return response.data.payload;
    });
};

export const PutInternalPagesByType = (
  page: "about" | "conditions" | "gdpr",
  data: IDataIntervalValues[]
): Promise<IDataInternalPage> => {
  return jwtInterceptor
    .put(
      `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.SaveInternalPages}?page=${page}`,
      data
    )
    .then((response) => {
      return response.data.payload;
    });
};
