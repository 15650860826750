import variables from "../utils/constants/variables.json";

export interface UserInfo {
    id: number;
    avatar: string;
    createdOn: Date;
    dueDate: Date;
    email: string;
    employer: string;
    exp: number
    firstName: string;
    name: string;
    isDeleted: boolean;
    isPasswordReset: boolean;
    isUserConfirmed: boolean;
    password?: string;
    confirmPassword?: string;
    phoneNumber: number;
    role: string;
    roleId: number;
    sector: string;
    userName: string;
    token: string;
    deleteReason: string;
    language: string;
    userConfirmedBy: string;

    getUserInfo?: () => UserInfo;
    saveUserInfo?: (userInfo: UserInfo) => void;
    login?: (payload: any) => {}
}

export type UserAuthType = {
    userInfo: UserInfo;
    getUserInfo: () => UserInfo;
    saveUserInfo: (userInfo: UserInfo) => void;
    login: (payload: any) => {}
}

export const DefaultUserInfo: UserInfo = {
    id: 0,
    avatar: variables.fake_avatar,
    firstName: "",
    name: "",
    createdOn: new Date(),
    dueDate: new Date(),
    email: "",
    employer: "Chambre des salariés",
    exp: 0,
    isDeleted: false,
    isPasswordReset: false,
    isUserConfirmed: false,
    phoneNumber: 0,
    password: "",
    role: "",
    roleId: 0,
    sector: "",
    userName: "",
    deleteReason: "",
    token: "",
    language: "",
    userConfirmedBy: ""

}
