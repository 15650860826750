import * as React from "react";
import { getLocalStorageItems } from "../functions/functionsUtils";

interface LanguageProviderPros {
  children?: React.ReactNode;
}

export type LanguageContextType = {
  userLang: string;
  updateUserLang(code: string): void;
};

let defaultLang = "FR";

//Create context
export const LanguageContext = React.createContext<LanguageContextType>({
  userLang: defaultLang,
  updateUserLang: (lang: string) => {
    defaultLang = lang;
  },
});

//Use context in a provider
export const LanguageProvider = ({ children }: LanguageProviderPros) => {
  const i18nextLng = getLocalStorageItems("i18nextLng", false);
  const [userLanguage, setUserLanguage] = React.useState<string>(
    i18nextLng.toString() !== "{}" ? i18nextLng : defaultLang
  );

  return (
    <LanguageContext.Provider
      value={{
        userLang: userLanguage,
        updateUserLang: (code: string) => {
          // if (code === "en-US") {
          //   setUserLanguage("GB");
          // } else if (code === "fr-FR") {
          //   setUserLanguage("FR");
          // } else {
          // }
          setUserLanguage(code);
        },
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
export default LanguageProvider;
