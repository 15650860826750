import React, { useEffect, useState } from "react";
import { Row, Col, Button as Btn, Spinner } from "react-bootstrap";
import LightButton from "../../components/LightButton";
import StatusButton from "../../components/StatusButton";
import styled from "styled-components";
import { toast } from "react-toastify";
import api_keys from "../../utils/constants/api_keys.json";
import UserForm from "../../components/UserForm";
import { applyUserEvent } from "../../models/applyUserEvent";
import { custom } from "devextreme/ui/dialog";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataGrid, {
  Column,
  SearchPanel,
  Selection,
  Pager,
  Paging,
  HeaderFilter,
} from "devextreme-react/data-grid";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import { UserInfo } from "../../models/user";
import { UserEvent } from "../../models/userEvent";
import { loggedUser } from "../../utils/functions/functionsUtils";
const SubListContainer = styled.div`
  padding: 20px 10px 20px 0px;
`;

const FocusRowFormContainer = styled.div``;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
`;

const ButtonText = styled.span`
  padding-left: 5px;
`;

const SpanTitle = styled.span`
  font-size: 20px;
  color: #141b4d;
`;

function SubscriptionOperationsGrid({ operation }: any) {
  //states
  const [userDetails, setUserDetails] = useState<UserInfo>({} as UserInfo);
  const [fileName, setFileName] = useState("");
  const [rowData, setRowData] = useState<UserEvent>({} as UserEvent);
  const [subsciptionList, setSubscriptionList] = useState([]);
  const [showPageSizeSelectorm] = useState(true);
  const [showInfo] = useState(true);
  const [showNavButtons] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isRejecting, setRejecting] = useState(false);
  //*******************

  //Variables
  const allowedPageSizes = [10];
  //*******************

  //Translation module
  const { t } = useTranslation();
  //*******************

  //Functions
  const handleApprovalButtonsDisabled = () => {
    return rowData.status === "Created" ? false : true;
  };

  const getSubscriptionData = (op: any) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.GetUserEventByReqType}?reqType=${op}`
      )
      .then((response) => {
        setSubscriptionList(response.data.payload);
        return response.data.payload;
      });
  };

  const approveUserPopup = (e: any) => {
    //Create popup
    let confirmDialogBox = custom({
      title: t("ApprovalPopupHeader", {
        Firstname: userDetails.firstName,
        Lastname: userDetails.name,
      })!,
      messageHtml: t(`ApprovalText${operation}`, {
        Firstname: userDetails.firstName,
        Lastname: userDetails.name,
      })!,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Cancel")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    //Display the box then save data
    confirmDialogBox.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        handleApprove(e);
      }
    });
  };

  const denyUserPopup = (e: any) => {
    //Create popup
    let denyDialogBox = custom({
      title: t("DenyHeader", {
        Firstname: userDetails.firstName,
        Lastname: userDetails.name,
      })!,
      messageHtml: t(`DenyText${operation}`, {
        Firstname: userDetails.firstName,
        Lastname: userDetails.name,
      })!,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Cancel")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    denyDialogBox.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        handleDeny(e);
      }
    });
  };

  const handleDeny = (e: any) => {
    const payload: applyUserEvent = {
      Action: "Denied",
      EventId: rowData?.id,
      UpdatedBy: loggedUser().userName,
    };
    setRejecting(true);
    //Submit approval
    submitDecision(payload);
  };

  const handleApprove = (e: any) => {
    const payload: applyUserEvent = {
      Action: "Approved",
      EventId: rowData.id,
      UpdatedBy: loggedUser().userName,
    };
    setSaving(true);
    //Submit approval
    submitDecision(payload);
  };

  const submitDecision = (payload: applyUserEvent) => {
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.SubmitUserEvent}`,
        payload
      )
      .then((response) => {
        showNotification(response.data, true);
        getSubscriptionData(operation);
        setRejecting(false);
        setSaving(false);
        return response.data.payload;
      });
  };

  const showNotification = (data: any, showOnSuccess: boolean) => {
    if (data.statusCode === 200) {
      if (showOnSuccess) toast.success(data.message, { position: "top-right" });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const onRowUpdating = (options: any) => {
    options.newData = Object.assign(options.oldData, options.newData);
  };

  const onContentReady = (options: any) => {
    var grid = options.component;
    var selection = grid.getSelectedRowKeys();

    if (selection.length === 0) {
      grid.selectRowsByIndexes([0]);
    }
  };

  const onSelectionChanged = (e: any) => {
    const dataRow = e.selectedRowsData[0];
    setFileName(dataRow.blobFileName);
    setUserDetails(dataRow.user);
    setRowData(dataRow);
  };

  useEffect(() => {
    getSubscriptionData(operation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //*******************

  return (
    <>
      <SubListContainer>
        <Row>
          <Col className="d-flex">
            <SpanTitle className="screenTitle">
              {t(`Request${operation}ScreenTitle`)}
            </SpanTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataGrid
              id="subListGrid"
              keyExpr="id"
              dataSource={subsciptionList}
              noDataText={t("NoSubscriptionData")!}
              columnAutoWidth={true}
              showBorders={true}
              focusedRowEnabled={true}
              onSelectionChanged={onSelectionChanged}
              onRowUpdating={onRowUpdating}
              rowAlternationEnabled={true}
              onContentReady={onContentReady}
            >
              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />
              <HeaderFilter visible={true} />
              <Selection mode="single" />
              <Paging
                defaultPageSize={10}
                pageSize={10}
                defaultPageIndex={0}
                enabled={true}
              />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode="full"
                showPageSizeSelector={showPageSizeSelectorm}
                showInfo={showInfo}
                showNavigationButtons={showNavButtons}
              />
              <Column
                dataField="id"
                caption={t("EventId")!}
                allowEditing={false}
                alignment={"center"}
              ></Column>
              <Column
                dataField="nameDisplay"
                caption={t("Name")!}
                allowEditing={false}
                alignment={"center"}
              ></Column>
              <Column
                dataField="status"
                caption={t("Status")!}
                allowEditing={false}
                alignment={"center"}
                cellRender={(data) => <StatusButton data={data} />}
              ></Column>
              <Column
                dataField="eventName"
                caption={t("Event")!}
                allowEditing={false}
                alignment={"center"}
                cellRender={(data) => <LightButton data={data} />}
              ></Column>
              <Column
                dataField="representativePerson"
                caption={t("RepresentativePerson")!}
                allowEditing={false}
                alignment={"center"}
              ></Column>
              <Column
                dataField="createdBy"
                caption={t("CreatedBy")!}
                allowEditing={false}
                alignment={"left"}
              ></Column>
              <Column
                dataField="createdOn"
                caption={t("CreatedOn")!}
                allowEditing={false}
                dataType="date"
                format="dd/MM/yyyy"
                alignment={"center"}
              ></Column>
              <Column
                dataField="updatedBy"
                caption={t("ApprovedBy")!}
                allowEditing={false}
                alignment={"left"}
              ></Column>
              <Column
                dataField="updatedOn"
                caption={t("ApprovedOn")!}
                allowEditing={false}
                dataType="date"
                format="dd/MM/yyyy"
                alignment={"center"}
              ></Column>
            </DataGrid>
          </Col>
          <Col>
            <FocusRowFormContainer>
              <UserForm
                width="100%"
                action="ro"
                data={userDetails}
                title={t("SubscriptionUserinfo")!}
                blobFileName={fileName}
                hasGdpr={false}
              />
            </FocusRowFormContainer>
            <Row>
              <Col>
                <ButtonsContainer>
                  <Btn
                    variant="success"
                    style={{ minWidth: "120px" }}
                    onClick={approveUserPopup}
                    disabled={handleApprovalButtonsDisabled()}
                  >
                    {isSaving && (
                      <Spinner
                        style={{ marginRight: "10px" }}
                        animation="border"
                        role="status"
                        size="sm"
                      >
                        <span className="visually-hidden">{t("Saving")}</span>
                      </Spinner>
                    )}
                    <FontAwesomeIcon
                      icon={["fas", "check"]}
                      style={{ color: "#141B4D" }}
                    />
                    <ButtonText>{t("Confirm")}</ButtonText>
                  </Btn>
                  <Btn
                    variant="warning"
                    style={{ minWidth: "120px" }}
                    disabled={handleApprovalButtonsDisabled()}
                    onClick={denyUserPopup}
                  >
                    {isRejecting && (
                      <Spinner
                        style={{ marginRight: "10px" }}
                        animation="border"
                        role="status"
                        size="sm"
                      >
                        <span className="visually-hidden">{t("Saving")}</span>
                      </Spinner>
                    )}
                    <FontAwesomeIcon
                      icon={["fas", "close"]}
                      style={{ color: "#141B4D" }}
                    />
                    <ButtonText>{t("RejectButton")}</ButtonText>
                  </Btn>
                </ButtonsContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </SubListContainer>
    </>
  );
}

export default SubscriptionOperationsGrid;
