import styled from "styled-components";
import CategoriesDataGrid from "../../components/CategoriesDataGrid";
import React from "react";
import { ScrollView } from "devextreme-react";

const BackEndContainer = styled.div``;

function BackEndLegislation() {
  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <BackEndContainer>
        <CategoriesDataGrid categoryCode={"obligations"} />
      </BackEndContainer>
    </ScrollView>
  );
}

export default BackEndLegislation;
