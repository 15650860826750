import styled from "styled-components";
import CategoriesTreeList from "../../components/CategoriesTreeList";
import React from "react";

const ObligationContainer = styled.div``;

function FrontEndObligation() {
  return (
    <ObligationContainer>
      <CategoriesTreeList categoryCode={"obligations"} />
    </ObligationContainer>
  );
}

export default FrontEndObligation;
