import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  GroupItem,
  Item,
  Label,
  CompareRule,
  RequiredRule,
  PatternRule,
  StringLengthRule,
  EmptyItem,
} from "devextreme-react/form";
import eye from "../../assets/eye.png";
import { Row, Col, Button as Btn, Spinner } from "react-bootstrap";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../../models/user";
import { useTranslation } from "react-i18next";
import {
  isUserLoggedIn,
  getMenuItems,
  loggedUser,
} from "../../utils/functions/functionsUtils";
import "devextreme-react/text-area";
import dxForm, {
  ContentReadyEvent,
  FieldDataChangedEvent,
  InitializedEvent,
} from "devextreme/ui/form";
import { ScrollView } from "devextreme-react";

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MyProfileContainer = styled.div`
  justify-content: center;
  margin-top: 25px;
  max-width: 1000px;
  display: block;
  margin-bottom: 40px;
  padding: 20px;
`;

const UserFormContainer = styled.div`
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
`;

const PwdButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ButtonText = styled.span`
  padding-left: 5px;
`;

const SubtitleText = styled.span``;

function MyProfile() {
  const [file] = useState();
  const [fileName] = useState();
  const [isFormFullFilled, setIsFormFullFilled] = useState(false);
  const [isPwdFormFullFilled, setIsPwdFormFullFilled] = useState(false);
  const [isDeleteFormFullFilled, setIsDeleteFormFullFilled] = useState(false);

  const [isSaving, setSaving] = useState(false);
  const [isChangingPassword, setChangingPassword] = useState(false);
  const [isDeletingAccount, setDeletingAccount] = useState(false);
  const [userData, setUserData] = useState<UserInfo>({} as UserInfo);
  const navigate = useNavigate();

  //Translation module
  const { t } = useTranslation();
  //*******************

  const formInstance = useRef<dxForm | undefined>(undefined);
  useEffect(() => {
    initUser(loggedUser());
  }, []);

  let onInitialized = (e: InitializedEvent) => {
    formInstance.current = e.component;
  };

  const initUser = (user: any) => {
    setUserData(user);
  };

  const readOnlyOptions = {
    disabled: false,
  };

  const languageOptions = {
    dataSource: ["FR", "EN", "DE"],
  };

  let passwordOptions = {
    mode: "password",
    disabled: false,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: eye,
          type: "default",
          onClick: () => changePasswordMode("password"),
        },
      },
    ],
  };

  const confirmOptions = {
    mode: "password",
    disabled: false,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: eye,
          type: "default",
          onClick: () => changePasswordMode("confirmPassword"),
        },
      },
    ],
  };

  const changePasswordMode = (name: any) => {
    if (!formInstance) return null;
    const editor = formInstance?.current?.getEditor(name);
    editor?.option(
      "mode",
      editor.option("mode") === "text" ? "password" : "text"
    );
  };

  const passwordComparison = () => {
    return userData.password;
  };

  const onFieldDataChanged = async (e: FieldDataChangedEvent) => {
    setUserData(e.component.option("formData"));
    formInstance.current = e.component;

    let res = e.component.validate();

    if (res?.brokenRules && res.brokenRules.length >= 1) {
      setIsFormFullFilled(false);
    } else {
      setIsFormFullFilled(true);
    }
  };

  const onPwdFieldDataChanged = async (e: any) => {
    setUserData(e.component.option("formData"));
    formInstance.current = e.component;

    let res = e.component.validate();

    if (res.brokenRules.length >= 1) {
      setIsPwdFormFullFilled(false);
    } else {
      setIsPwdFormFullFilled(true);
    }
  };

  const onDeleteFieldDataChanged = async (e: any) => {
    setUserData(e.component.option("formData"));
    formInstance.current = e.component;

    let res = e.component.validate();

    if (res.brokenRules.length >= 1) {
      setIsDeleteFormFullFilled(false);
    } else {
      setIsDeleteFormFullFilled(true);
    }
  };

  const backToHome = async () => {
    if (isUserLoggedIn() && Object.keys(getMenuItems()).length > 0) {
      switch (loggedUser().role) {
        case "Administrator": {
          navigate("/dashboard");
          break;
        }
        default: {
          navigate("/services");
        }
      }
    }
  };

  const handleSavingState = (st: boolean) => {
    setSaving(st);
  };

  const showNotifications = (
    data: any,
    showOnSuccess: boolean,
    option: string
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess) {
        if (option === "ChangePwd")
          toast.success(t("ChangePwdSuccess"), {
            position: "top-right",
          });
        if (option === "UpdateUser")
          toast.success(t("UpdateUserSuccess"), {
            position: "top-right",
          });
        if (option === "DeleteUser")
          toast.success(t("DeleteUserEventSuccess"), {
            position: "top-right",
          });
      } else {
        toast.error(data.message, { position: "top-right" });
      }
    }
  };

  const changePassword = async () => {
    setChangingPassword(true);
    userData.password = `${btoa(userData.password!)}${api_keys.cslIdentifier}`;
    userData.confirmPassword = "";

    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.changePassword}`,
        userData
      )
      .then((response) => {
        showNotifications(response.data, true, "ChangePwd");
        setChangingPassword(false);
        return response.data.payload;
      });
  };

  const deleteUserInfo = async () => {
    setDeletingAccount(true);
    const formData = new FormData();
    formData.append("File", file!);
    formData.append("UserInfo", JSON.stringify(userData!));
    formData.append("FileName", fileName!);
    formData.append("EventName", "Delete");
    formData.append("CreatedBy", "DeleteAccountOption");
    formData.append("RepresentativeEntity", "");

    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.createUserEvent} `,
        formData
      )
      .then((response) => {
        showNotifications(response.data, true, "DeleteUser");
        setDeletingAccount(false);
        return response.data.payload;
      });
  };

  const changeUserInfo = async () => {
    handleSavingState(true);
    const formData = new FormData();
    formData.append("File", file!);
    formData.append("UserInfo", JSON.stringify(userData!));
    formData.append("FileName", fileName!);
    formData.append("EventName", "Edit");
    formData.append("CreatedBy", "MyProfileOption");
    formData.append("RepresentativeEntity", "");

    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.createUserEvent} `,
        formData
      )
      .then((response) => {
        showNotifications(response.data, true, "UpdateUser");
        handleSavingState(false);
        return response.data.payload;
      });
  };

  const validateForm = React.useCallback((e: ContentReadyEvent) => {
    formInstance.current = e.component;
    e.component.validate();
  }, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <MainContainer>
        <MyProfileContainer>
          <UserFormContainer>
            <Row>
              <Col>
                <Form
                  formData={userData}
                  colCount={1}
                  onContentReady={validateForm}
                  onFieldDataChanged={onFieldDataChanged}
                  onInitialized={onInitialized}
                >
                  <GroupItem caption={t("UserProfile")!} colCount={2}>
                    <Item dataField="name" editorOptions={readOnlyOptions}>
                      <Label>{t("Name")!}</Label>
                      <RequiredRule message={t("LastNameRequired")!} />
                    </Item>
                    <Item dataField="firstName" editorOptions={readOnlyOptions}>
                      <Label>{t("Firstname")!}</Label>
                      <RequiredRule message={t("FirstNameRequired")!} />
                    </Item>
                    <Item dataField="userName" disabled={true}>
                      <Label>{t("Username")!}</Label>
                      <RequiredRule message={t("UserNameRequired")!} />
                    </Item>
                    <Item dataField="email" disabled={true}>
                      <Label>{t("Email")!}</Label>
                    </Item>
                    <Item dataField="phoneNumber" editorType="dxNumberBox">
                      <StringLengthRule
                        message={t("MaxLengthPhone")!}
                        max={12}
                      />
                      <Label>{t("Phone")!}</Label>
                    </Item>
                    <Item dataField="sector" editorOptions={readOnlyOptions}>
                      <Label>{t("Sector")!}</Label>
                    </Item>
                    <Item dataField="employer">
                      <Label>{t("Employer")!}</Label>
                      <RequiredRule message={t("EmployerRequired")!} />
                    </Item>
                    <Item
                      dataField="language"
                      editorType="dxSelectBox"
                      editorOptions={languageOptions}
                    >
                      <Label>{t("Language")!}</Label>
                      <RequiredRule message={t("Language")!} />
                    </Item>
                  </GroupItem>
                </Form>
                <Row>
                  <Col>
                    <ButtonsContainer>
                      <Btn
                        variant="success"
                        style={{ minWidth: "120px" }}
                        onClick={changeUserInfo}
                        disabled={isFormFullFilled === false ? true : false}
                      >
                        {isSaving && (
                          <Spinner
                            style={{ marginRight: "10px" }}
                            animation="border"
                            role="status"
                            size="sm"
                          >
                            <span className="visually-hidden">
                              {t("Saving")}
                            </span>
                          </Spinner>
                        )}
                        <FontAwesomeIcon
                          icon={["fas", "floppy-disk"]}
                          style={{ color: "#141B4D" }}
                        />
                        <ButtonText>{t("Confirm")}</ButtonText>
                      </Btn>
                      <Btn
                        variant="primary"
                        style={{ minWidth: "120px" }}
                        onClick={backToHome}
                      >
                        <FontAwesomeIcon
                          icon={["fas", "home"]}
                          style={{ color: "#141B4D" }}
                        />
                        <ButtonText>{t("HomeButton")}</ButtonText>
                      </Btn>
                    </ButtonsContainer>
                  </Col>
                </Row>
                <Form
                  formData={userData}
                  colCount={1}
                  onContentReady={validateForm}
                  onFieldDataChanged={onPwdFieldDataChanged}
                  onInitialized={onInitialized}
                >
                  <EmptyItem></EmptyItem>
                  <GroupItem caption={t("ChangePwd")!} colCount={2}>
                    <Item
                      dataField="password"
                      editorType="dxTextBox"
                      editorOptions={passwordOptions}
                    >
                      <Label>{t("Password")!}</Label>
                      <RequiredRule message={t("PwdRequired")!} />
                      <PatternRule
                        pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                        message={t("PasswordPattern")!}
                      />
                    </Item>
                    <Item
                      dataField="confirmPassword"
                      editorType="dxTextBox"
                      editorOptions={confirmOptions}
                    >
                      <Label>{t("ConfirmPassword")!}</Label>
                      <RequiredRule message={t("ConfirmPwdRequired")!} />
                      <PatternRule
                        pattern="(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                        message={t("PasswordPattern")!}
                      />
                      <CompareRule
                        message={t("PwdsNotMatch")!}
                        comparisonTarget={passwordComparison}
                      />
                    </Item>
                  </GroupItem>
                </Form>
                <Row>
                  <Col>
                    <PwdButtonsContainer>
                      <Btn
                        variant="success"
                        style={{ minWidth: "120px" }}
                        onClick={changePassword}
                        disabled={isPwdFormFullFilled === false ? true : false}
                      >
                        {isChangingPassword && (
                          <Spinner
                            style={{ marginRight: "10px" }}
                            animation="border"
                            role="status"
                            size="sm"
                          >
                            <span className="visually-hidden">
                              {t("Saving")}
                            </span>
                          </Spinner>
                        )}
                        <FontAwesomeIcon
                          icon={["fas", "floppy-disk"]}
                          style={{ color: "#141B4D" }}
                        />
                        <ButtonText>{t("ChangePwd")}</ButtonText>
                      </Btn>
                    </PwdButtonsContainer>
                  </Col>
                </Row>

                <Form
                  formData={userData}
                  colCount={1}
                  onContentReady={validateForm}
                  onFieldDataChanged={onDeleteFieldDataChanged}
                  onInitialized={onInitialized}
                >
                  <GroupItem caption={t("DeleteAccountTitle")!} colCount={1}>
                    <Item dataField="deleteReason" editorType="dxTextArea">
                      <Label>{t("DeleteAccountReason")!}</Label>
                    </Item>
                  </GroupItem>
                </Form>
                <Row>
                  <Col>
                    <SubtitleText>{t("DeleteAccountSubTitle")!}</SubtitleText>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ButtonsContainer>
                      <Btn
                        variant="success"
                        style={{ minWidth: "120px" }}
                        onClick={deleteUserInfo}
                        disabled={
                          isDeleteFormFullFilled === false ? true : false
                        }
                      >
                        {isDeletingAccount && (
                          <Spinner
                            style={{ marginRight: "10px" }}
                            animation="border"
                            role="status"
                            size="sm"
                          >
                            <span className="visually-hidden">
                              {t("Saving")}
                            </span>
                          </Spinner>
                        )}
                        <FontAwesomeIcon
                          icon={["fas", "floppy-disk"]}
                          style={{ color: "#141B4D" }}
                        />
                        <ButtonText>{t("Confirm")}</ButtonText>
                      </Btn>
                      <Btn
                        variant="primary"
                        style={{ minWidth: "120px" }}
                        onClick={backToHome}
                      >
                        <FontAwesomeIcon
                          icon={["fas", "home"]}
                          style={{ color: "#141B4D" }}
                        />
                        <ButtonText>{t("HomeButton")}</ButtonText>
                      </Btn>
                    </ButtonsContainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </UserFormContainer>
        </MyProfileContainer>
      </MainContainer>
    </ScrollView>
  );
}

export default MyProfile;
