import styled from "styled-components";
import GlobalSearchTreeList from "../../components/GlobalSearchTreeList";
import React from "react";
import { ScrollView } from "devextreme-react";

const AllCategoriesContainer = styled.div``;

function FrontEndAllCategories() {
  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <AllCategoriesContainer>
        <GlobalSearchTreeList />
      </AllCategoriesContainer>
    </ScrollView>
  );
}
export default FrontEndAllCategories;
