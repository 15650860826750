import React from "react";
import styled from "styled-components";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import accessDeniedLogo from "../../assets/accessdenied.png";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ScrollView } from "devextreme-react";

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

const FormContainer = styled(Form)`
  min-height: 300px;
  display: block;
  margin-bottom: 40px;
  text-align: center;
`;

const HomeButtonDiv = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`;

const HomeButton = styled(Button)`
  margin-left: 30px;
  min-width: 120px;
  max-height: 40px;
  margin-top: 105px;
`;

const HomeButtonText = styled.span`
  padding-left: 5px;
`;

const BoxTitle = styled.h2`
  font-size: 18px;
`;

const BoxSubTitle = styled.h4`
  font-size: 12px;
`;

const AccessDeniedLogo = styled.img`
    margin 15px;
    height:130px;
`;

function PageNotFound() {
  const navigate = useNavigate();
  const location = useLocation();
  //Translation module
  const { t } = useTranslation();
  //*******************

  const backToHome = async () => {
    navigate("/");
  };

  if (location.pathname !== "/" && location.pathname !== undefined) {
    toast.warning(`${t("PageNotFound")} : ${location.pathname}`, {
      position: "top-right",
    });
  }

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <NotFoundContainer>
        <Row>
          <Col className="d-flex">
            <FormContainer>
              <BoxTitle>{t("PageNotFound")}</BoxTitle>
              <BoxSubTitle>
                {t("Page")}
                {location.pathname}
              </BoxSubTitle>
              <Form.Group id="grpAccessDenied">
                <HomeButtonDiv>
                  <AccessDeniedLogo src={accessDeniedLogo} />
                  <HomeButton variant="primary" onClick={backToHome}>
                    <FontAwesomeIcon
                      icon={["fas", "home"]}
                      style={{ color: "#141B4D" }}
                    />
                    <HomeButtonText>Home</HomeButtonText>
                  </HomeButton>
                  <ToastContainer />
                </HomeButtonDiv>
              </Form.Group>
            </FormContainer>
          </Col>
        </Row>
      </NotFoundContainer>
    </ScrollView>
  );
}

export default PageNotFound;
