import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import { Category } from "../../models/category";
import { CategoryDocument } from "../../models/categoryDocument";
import { useTranslation } from "react-i18next";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import LanguageFlags from "../../components/LanguageFlags";
import { custom } from "devextreme/ui/dialog";
import {
  DataGrid,
  Column,
  SearchPanel,
  Selection,
  Editing,
  Texts,
  Paging,
  Button,
  Pager,
} from "devextreme-react/data-grid";
import "whatwg-fetch";
import {
  loggedUser,
  isUserAdmin,
  isUserEditor,
  getLocalStorageItems,
} from "../../utils/functions/functionsUtils";
import { UserInfo } from "../../models/user";
import { OrderForm } from "../../models/orderForm";
import CustomStore from "devextreme/data/custom_store";
import { useNavigate } from "react-router-dom";
import { Popup, ToolbarItem } from "devextreme-react/popup";

import NumberBox from "devextreme-react/number-box";
import TextBox from "devextreme-react/text-box";
import { ToolbarPreparingEvent } from "devextreme/ui/data_grid";

const MyProfileContainer = styled.div`
  width: 100%;
  padding: 20px 10px 20px 0px;
`;

const GridContainer = styled.div`
  overflow: hidden;
`;

const CategoryImage = styled.img`
  padding: 5px;
  height: 40px;
`;

const DivCategory = styled.div<{ color: string }>`
  background-color: ${(prop) => prop.color};
  border-radius: 4px;
`;

const SpanCategory = styled.span`
  padding: 10px;
  font-size: 20px;
  color: white;
`;

function CategoriesDataGrid({ categoryCode }: any) {
  const [selectedCategory, setSelectedCategory] = useState<Category>(
    {} as Category
  );
  const [selectedCategoryDocument, setSelectedCategoryDocument] =
    useState<CategoryDocument>({} as CategoryDocument);
  const [userDetails, setUserDetails] = useState<UserInfo>({} as UserInfo);
  const [detailsButtonDisabled, setDetailsButtonDisabled] = useState(true);
  const allowedPageSizes = [10, 20];
  const [popupVisible, setPopupVisible] = useState(false);
  const [formInfoData, setFormInfoData] = useState<OrderForm>({} as OrderForm);
  const [orderValue, setOrderValue] = useState(0);
  const [selectedRowUpdateOrder, setSelectedRowUpdateOrder] =
    useState<CategoryDocument>({} as CategoryDocument);

  //Translation module
  const { t, i18n } = useTranslation();

  const i18nextLng = getLocalStorageItems("i18nextLng", false);
  const [currentLang, setcurrentLang] = useState<string | undefined>(
    i18nextLng
  );

  i18n.on("languageChanged", (data) => {
    setcurrentLang(data);
  });

  //*******************
  let navigate = useNavigate();
  let dataGrid: any;

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const updateOrder = () => {
    const payload = {
      id: selectedRowUpdateOrder.id,
      newOrder: orderValue,
      updatedBy: userDetails?.userName,
    };
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.dragAndDropDocuments}`,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesUpdate");
        return response.data.payload;
      });
  };

  const saveSelection = () => {
    hidePopup();
    updateOrder();
    if (dataGrid) {
      dataGrid.instance.refresh();
    }
  };

  const savePopupButtonOptions = {
    onClick: saveSelection,
    text: t("PopupSave"),
    icon: "save",
    disabled: orderValue < 1,
    elementAttr: {
      class: "see-document-button",
    },
    type: "success",
  };

  const closePopupButtonOptions = {
    onClick: hidePopup,
    text: t("PopupClose"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
  };
  //*******************
  const [gridDataSource, setgridDataSource] = useState<CustomStore<any, any>>();

  useEffect(() => {
    setUserDetails(loggedUser());
    getCategoryByCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setgridDataSource(
      new CustomStore({
        key: "id",
        load(loadOptions: any) {
          let params = "?";
          ["skip", "take", "filter"].forEach((i: any) => {
            if (i in loadOptions) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });
          params = params.slice(0, -1);
          return getCategoriesFiles(params);
        },
        remove: (key) => {
          return onRowRemoved(key);
        },
      })
    );

    return () => {
      i18n.off("languageChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategoryByCode = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategoryByCode}?categoryCode=${categoryCode}`
      )
      .then((response) => {
        setSelectedCategory(response.data.payload);
        return response.data.payload;
      });
  };

  const getCategoriesFiles = (params: any) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategoryFiles}${params}&categoryCode=${categoryCode}&language=${userDetails.language}`
      )
      .then((response) => {
        return {
          data: response.data.payload.data,
          totalCount: response.data.payload.totalCount,
        };
      });
  };

  const showNotifications = (
    data: any,
    showOnSuccess: boolean,
    message: string
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t(message), {
          position: "top-right",
        });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const onRowRemoved = (options: any) => {
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.deleteCategoryDocuments}?categoryDocumentId=${options}`,
        options
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesRemove");
      });
  };

  const redirectPage = () => {
    navigate(`/categoryDetails/${categoryCode}/${selectedCategoryDocument.id}`);
  };

  const redirectPageMaster = () => {
    navigate(`/categoryDetails/${categoryCode}/0`);
  };

  const onFocusedRowChanged = (e: any) => {
    if (e.row === undefined) return;

    setDetailsButtonDisabled(false);
    setSelectedCategoryDocument(e.row.data);
  };

  const onToolbarPreparing = (e: ToolbarPreparingEvent<any, any>) => {
    let dataGrid = e.component;
    e.toolbarOptions.items?.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "add",
          type: "success",
          onClick: redirectPageMaster,
          elementAttr: {
            class: "see-document-button",
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "edit",
          type: "default",
          onClick: redirectPage,
          disabled: detailsButtonDisabled,
          elementAttr: {
            class: "see-document-button",
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "success",
          elementAttr: {
            id: "refreshCategoryDataGrid",
            class: "see-document-button",
          },
          onClick: function () {
            dataGrid.refresh();
          },
        },
      }
    );
  };

  const isColumnVisible = (shouldBeVisibleOnCategory: string[]) => {
    let isVisibleCol = shouldBeVisibleOnCategory.filter(function (ft) {
      return ft.includes(categoryCode);
    });
    return isVisibleCol.length > 0;
  };

  const isDeletionRequestOptionVisible = (e: any) => {
    return isUserEditor();
  };

  const isDeletionRequestOptionDisabled = (e: any) => {
    return e.row.data.isDeletionRequested === 1;
  };

  const onDeletionRequest = (options: any) => {
    const payload = options.row.data;
    payload.entity = "CategoryDocument";
    payload.entityId = options.row.data.id;
    payload.createdBy = loggedUser().userName;
    payload.description = `User : ${
      loggedUser().userName
    } has requested the deletion for Category : ${
      options.row.data.childCategory
    } and Reference/ArticleNumber : ${
      options.row.data.articleNumber
        ? options.row.data.articleNumber
        : options.row.data.reference
    }`;

    let confirmDialog = custom({
      title: `${t("HintRequestDeletion")} : ${
        options.row.data.childCategory
      } ?`,
      messageHtml: `<b>${t("DeletionRequestConfirm")} : ${
        options.row.data.articleNumber
          ? options.row.data.articleNumber
          : options.row.data.reference
      }?</b>`,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Cancel")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    confirmDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        return jwtInterceptor
          .post(
            `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.createDeletionRequest}`,
            payload
          )
          .then((response) => {
            showNotifications(response.data, true, "CategoryRequestDeletion");
            if (dataGrid) {
              dataGrid.instance.refresh();
            }
            return response.data.payload;
          });
      }
    });
  };

  const showPopupOrder = (e: any) => {
    const fileData = e.row.data;
    let formInfoOrder = {
      order: fileData.order,
      neworder: fileData.order,
    };
    setOrderValue(fileData.order);
    setFormInfoData(formInfoOrder);
    setSelectedRowUpdateOrder(fileData);
    setPopupVisible(true);
    e.event.preventDefault();
  };

  const numberBoxValueChanged = (e: any) => {
    setOrderValue(e.value);
  };

  return (
    <MyProfileContainer>
      <Row>
        <Col>
          <DivCategory color={selectedCategory.color}>
            <CategoryImage
              src={selectedCategory.base64Image}
              alt={t(selectedCategory.name)!}
              aria-label={t(selectedCategory.name)!}
              title={t(selectedCategory.name)!}
              role="img"
            />
            <SpanCategory color={selectedCategory.color}>
              {t(categoryCode.charAt(0).toUpperCase() + categoryCode.slice(1))}
            </SpanCategory>
          </DivCategory>
        </Col>
      </Row>
      <Row className="pt-2">
        <Col className="d-flex">
          <GridContainer role="group">
            <DataGrid
              id="categoryFiles"
              dataSource={gridDataSource}
              showRowLines={true}
              showBorders={true}
              focusedRowEnabled={true}
              rowAlternationEnabled={true}
              onRowClick={(e) => {
                e.event?.stopPropagation();
              }}
              remoteOperations={true}
              onToolbarPreparing={onToolbarPreparing}
              onFocusedRowChanged={onFocusedRowChanged}
              noDataText={t("NoDataCategoryFiles")!}
              columnAutoWidth={true}
              ref={(ref) => {
                dataGrid = ref;
              }}
            >
              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />
              <Selection mode="single" />
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Editing allowDeleting={true} useIcons={true} mode="popup">
                <Texts confirmDeleteMessage={t("PopupDeleteMessage")!} />
              </Editing>

              <Column type="buttons" caption={t("Actions")!}>
                <Button name="edit" hint={t("HintEdit")!} />
                <Button
                  name="delete"
                  hint={t("HintDelete")!}
                  visible={isUserAdmin()}
                />
                <Button
                  hint={t("HintRequestDeletion")!}
                  icon="deleterow"
                  onClick={onDeletionRequest}
                  visible={isDeletionRequestOptionVisible}
                  disabled={isDeletionRequestOptionDisabled}
                />
                <Button
                  hint={t("HintChangeOrder")!}
                  icon="orderedlist"
                  onClick={showPopupOrder}
                />
              </Column>
              <Column dataField="id" visible={true} />
              <Column dataField="categoryId" visible={false} />
              <Column
                dataField="order"
                caption={t("CategoryOrder")!}
                visible={false}
              />
              <Column
                dataField="childCategory"
                caption={t("CategoryName")!}
                visible={currentLang === "FR"}
                allowSorting={false}
              />
              <Column
                dataField="childCategoryEn"
                caption={t("CategoryName")!}
                visible={currentLang === "GB"}
                allowSorting={false}
              />
              <Column
                dataField="childCategoryDe"
                caption={t("CategoryName")!}
                visible={currentLang === "DE"}
                allowSorting={false}
              />
              <Column
                dataField="reference"
                caption={t("PopupCategoriesReference")!}
                visible={isColumnVisible([
                  "jurisprudences",
                  "models",
                  "publications",
                ])}
                allowSorting={false}
              />
              <Column
                dataField="status"
                caption={t("PopupCategoriesStatus")!}
                visible={true}
                allowSorting={false}
              />
              <Column
                dataField="revisionDate"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("PopupCategoriesRevisionDate")!}
                visible={false}
                allowSorting={false}
              />
              <Column
                dataField="publicationDate"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("PopupCategoriesPublicationDate")!}
                visible={true}
                allowSorting={false}
              />
              <Column
                dataField="source"
                caption={t("PopupCategoriesSource")!}
                visible={isColumnVisible(["jurisprudences"])}
                allowSorting={false}
              />
              <Column
                dataField="sourceExact"
                caption={t("PopupCategoriesSourceExact")!}
                visible={isColumnVisible(["jurisprudences"])}
                allowSorting={false}
              />
              <Column
                dataField="jurisdiction"
                caption={t("PopupCategoriesJurisdiction")!}
                visible={isColumnVisible(["jurisprudences"])}
                allowSorting={false}
              />
              <Column
                dataField="publicationTitle"
                caption={t("PopupCategoriesPublicationTitle")!}
                visible={isColumnVisible(["publications"])}
                allowSorting={false}
              />
              <Column
                dataField="publicationNumber"
                caption={t("PopupCategoriesPublicationNumber")!}
                visible={isColumnVisible(["publications"])}
                allowSorting={false}
              />
              <Column
                dataField="articleNumber"
                caption={t("PopupCategoriesArticleNumber")!}
                visible={isColumnVisible([
                  "legislations",
                  "agreements",
                  "obligations",
                ])}
                allowSorting={false}
              />
              <Column
                dataField="lastFileUploaded"
                caption={t("CategoryLastFileUploaded")!}
                visible={true}
                allowSorting={false}
              />
              <Column
                dataField="lastRevisionNumber"
                caption={t("CategoryLastRevisionNumber")!}
                visible={true}
                allowSorting={false}
              />
              <Column
                dataField="lastLanguage"
                caption={t("CategoryLastLanguage")!}
                visible={true}
                cellRender={(data) => <LanguageFlags data={data} />}
                alignment={"center"}
                allowSorting={false}
              />
              <Column
                dataField="totalFilesRevisions"
                caption={t("CategoryTotalFilesRevisions")!}
                visible={true}
                allowSorting={false}
              />
              <Column
                dataField="createdBy"
                caption={t("CreatedBy")!}
                allowHeaderFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField="createdOn"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("CreatedOn")!}
                allowHeaderFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField="updatedBy"
                caption={t("UpdatedBy")!}
                allowHeaderFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField="updatedOn"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("UpdatedOn")!}
                allowHeaderFiltering={false}
                allowSorting={false}
              />
            </DataGrid>
          </GridContainer>
        </Col>
      </Row>

      <Popup
        visible={popupVisible}
        onHiding={hidePopup}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title={t("PopupChangeOrder")!}
        container=".dx-viewport"
        width={500}
        height={400}
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={closePopupButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={savePopupButtonOptions}
        />

        <Row>
          <Col>
            <div className="dx-fieldset">
              <div className="dx-field">
                <div className="dx-field-label">{t("PopupCurrentOrder")!}</div>
                <div className="dx-field-value">
                  <TextBox
                    placeholder={t("PopupCurrentOrder")!}
                    stylingMode="filled"
                    value={formInfoData.order}
                    readOnly={true}
                  ></TextBox>
                </div>
              </div>
            </div>

            <div className="dx-fieldset">
              <div className="dx-field">
                <div className="dx-field-label">{t("PopupNewOrder")!}</div>
                <div className="dx-field-value">
                  <NumberBox
                    format="#"
                    min={1}
                    showSpinButtons={true}
                    value={orderValue}
                    onValueChanged={numberBoxValueChanged}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Popup>
    </MyProfileContainer>
  );
}

export default CategoriesDataGrid;
