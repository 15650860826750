import styled from "styled-components";
import CategoriesTreeList from "../../components/CategoriesTreeList";
import React from "react";

const ModelsContainer = styled.div``;

function FrontEndModel() {
  return (
    <ModelsContainer>
      <CategoriesTreeList categoryCode={"models"} />
    </ModelsContainer>
  );
}
export default FrontEndModel;
