import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import { getLocalStorageItems } from "../../utils/functions/functionsUtils";
import { useTranslation } from "react-i18next";

const jwtInterceptor = axios.create({});

const AxiosInterceptor = ({ children }: any) => {
  const navigate = useNavigate();
  const [isSet, setIsSet] = useState(false);
  //Translation module
  const { t } = useTranslation();
  useEffect(() => {
    const respInterceptor = (response: any) => {
      return response;
    };

    const errInterceptor = (error: any) => {
      if (error && error.response && error.response.status === 401) {
        toast.warning(t("TokenExpired"), {
          position: "top-right",
        });
        localStorage.clear();
        navigate("/");
      } else {
        toast.error(t("ServerError"), {
          position: "top-right",
        });
      }

      return Promise.reject(error);
    };

    const reqInterceptor = (config: any) => {
      var token = getLocalStorageItems("tokens", true);

      if (Object.keys(token).length) {
        config.headers["Authorization"] = `bearer ${
          token && token.replace('"', "").replace('"', "")
        }`;
      }
      config.headers["Ocp-Apim-Subscription-Key"] = api_keys.subsriptionKey;
      config.headers["Access-Control-Allow-Origin"] =
        "https://searchplatformapim.azure-api.net, http://localhost:3000, https://csl-cdd-dev-apim.azure-api.net";
      config.headers["Access-Control-Allow-Headers"] =
        "Content-Type, Authorization,X-Requested-With";
      config.headers["Access-Control-Allow-Methods"] =
        "DELETE, POST, PUT, GET, OPTIONS";
      return config;
    };

    const requestInterceptor = jwtInterceptor.interceptors.request.use(
      reqInterceptor,
      errInterceptor
    );
    const responseInterceptor = jwtInterceptor.interceptors.response.use(
      respInterceptor,
      errInterceptor
    );

    setIsSet(true);
    return () => {
      jwtInterceptor.interceptors.request.eject(requestInterceptor);
      jwtInterceptor.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isSet && children;
};

export default jwtInterceptor;
export { AxiosInterceptor };
