import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HtmlEditor, LoadPanel, ScrollView } from "devextreme-react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  GetInternalPages,
  IDataIntervalValues,
  PutInternalPagesByType,
} from "../../services/internal.service";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../utils/functions/functionsUtils";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const headerValues = [false, 1, 2, 3, 4, 5];
const fontSizeOptions = {
  inputAttr: {
    "aria-label": "Font size",
  },
};
const fontFamilyOptions = {
  inputAttr: {
    "aria-label": "Font family",
  },
};
const headerOptions = {
  inputAttr: {
    "aria-label": "Font family",
  },
};

const FormWizard = styled.form`
  padding: 20px;
`;

const Wysiwyg = styled.div`
  display: flex;
  flex-direction: column;
`;

const WysiwygContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const LangSelector = styled(ReactFlagsSelect)`
  padding-left:  "0%"};
  transition: all 0.3s ease-in-out;

  ul > li:hover {
    background-color: #fff;

    * {
      background-color: #fff;
      color: #141b4d;
    }
  }
`;
interface IProps {}

export const Internal = (props: IProps) => {
  const { t } = useTranslation();
  const [selectedTab, setselectedTab] = useState<number>(0);
  const [isLoading, setisLoading] = useState(false);
  const toolBarHtmlEditor = useMemo(() => {
    return {
      multiline: true,
      items: [
        {
          name: "header",
          acceptedValues: headerValues,
          options: headerOptions,
        },
        {
          name: "size",
          acceptedValues: sizeValues,
          options: fontSizeOptions,
        },
        {
          name: "font",
          acceptedValues: fontValues,
          options: fontFamilyOptions,
        },
        { name: "undo" },
        { name: "redo" },
        { name: "separator" },
        { name: "bold" },
        { name: "italic" },
        { name: "strike" },
        { name: "underline" },
        { name: "separator" },
        { name: "alignLeft" },
        { name: "alignCenter" },
        { name: "alignRight" },
        { name: "alignJustify" },
        { name: "separator" },
        { name: "orderedList" },
        { name: "bulletList" },
        { name: "separator" },
        { name: "color" },
        { name: "background", options: {} },
        { name: "separator" },
        { name: "link" },
        { name: "separator" },
        { name: "clear" },
        { name: "codeBlock" },
        { name: "blockquote" },
        { name: "separator" },
        { name: "insertTable" },
        { name: "deleteTable" },
        { name: "insertRowAbove" },
        { name: "insertRowBelow" },
        { name: "deleteRow" },
        { name: "insertColumnLeft" },
        { name: "insertColumnRight" },
        { name: "deleteColumn" },
        { name: "subscript" },
        { name: "superscript" },
      ],
    };
  }, []);

  const [about, setAbout] = useState<IDataIntervalValues[]>([
    {
      language: "FR",
      content: "",
    },
    {
      language: "EN",
      content: "",
    },
    {
      language: "DE",
      content: "",
    },
  ]);
  const [conditions, setConditions] = useState<IDataIntervalValues[]>([
    {
      language: "FR",
      content: "",
    },
    {
      language: "EN",
      content: "",
    },
    {
      language: "DE",
      content: "",
    },
  ]);
  const [rgpd, setRgpd] = useState<IDataIntervalValues[]>([
    {
      language: "FR",
      content: "",
    },
    {
      language: "EN",
      content: "",
    },
    {
      language: "DE",
      content: "",
    },
  ]);

  const setHtml = (value: string, index: number, page: string) => {
    switch (page) {
      case "about":
        setAbout((prev) => {
          const newLangs = [...prev];
          newLangs[index].content = value;
          return newLangs;
        });
        break;
      case "conditions":
        setConditions((prev) => {
          const newLangs = [...prev];
          newLangs[index].content = value;
          return newLangs;
        });
        break;

      default:
        setRgpd((prev) => {
          const newLangs = [...prev];
          newLangs[index].content = value;
          return newLangs;
        });
        break;
    }
  };

  const sendAboutTextes = () => {
    setisLoading(true);
    PutInternalPagesByType("about", about)
      .then((data) => {
        setisLoading(false);
        getInternalInformations();
        displaySuccessToast(t("DataSaved"));
      })
      .catch((error) => {
        setisLoading(false);
        displayErrorToast(t("DataNotSaved"));
      });
  };

  const sendConditionsTextes = () => {
    setisLoading(true);
    PutInternalPagesByType("conditions", conditions)
      .then(() => {
        setisLoading(false);
        getInternalInformations();
        displaySuccessToast(t("DataSaved"));
      })
      .catch(() => {
        setisLoading(false);
        displayErrorToast(t("DataNotSaved"));
      });
  };

  const sendRgpdTextes = () => {
    setisLoading(true);
    PutInternalPagesByType("gdpr", rgpd)
      .then(() => {
        setisLoading(false);
        getInternalInformations();
        displaySuccessToast(t("DataSaved"));
      })
      .catch(() => {
        setisLoading(false);
        displayErrorToast(t("DataNotSaved"));
      });
  };

  const getInternalInformations = () => {
    setisLoading(true);
    GetInternalPages()
      .then((data) => {
        setisLoading(false);

        const about = data.filter((item) => item.page === "about");
        const conditions = data.filter((item) => item.page === "conditions");
        const gdpr = data.filter((item) => item.page === "gdpr");

        setAbout(about);
        setConditions(conditions);
        setRgpd(gdpr);
      })
      .catch((error) => {
        setisLoading(false);
      });
  };

  const getCurrentLang = (lang: string): string => {
    switch (lang) {
      case "FR":
        return "FR";
      case "EN":
        return "GB";
      default:
        return "DE";
    }
  };

  useEffect(() => {
    getInternalInformations();
  }, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <div style={{ width: "100%", padding: "20px 10px 20px 0px" }}>
        <TabPanel
          id="tabPanel"
          style={{ margin: 0, border: 0, flex: 1, Height: "100%" }}
          activeStateEnabled={false}
          selectedIndex={selectedTab}
          onSelectedIndexChange={(e) => setselectedTab(e)}
        >
          <Item title={t("AboutProject")!} icon="info">
            <FormWizard>
              <Wysiwyg>
                {about.map((item, index) => (
                  <WysiwygContainer key={index}>
                    <LangSelector
                      selected={getCurrentLang(item.language)}
                      onSelect={() => null}
                      countries={[getCurrentLang(item.language)]}
                      customLabels={{ GB: "EN", FR: "FR", DE: "DE" }}
                      showSelectedLabel={true}
                      showOptionLabel={true}
                      fullWidth={false}
                      className="react-flags-internal"
                      disabled={true}
                      selectButtonClassName="no-caret"
                    />
                    <HtmlEditor
                      valueType={"html"}
                      value={item.content}
                      toolbar={toolBarHtmlEditor!}
                      onValueChanged={(e) => setHtml(e.value, index, "about")}
                    ></HtmlEditor>
                  </WysiwygContainer>
                ))}
                <Button
                  variant="primary"
                  style={{ alignSelf: "end" }}
                  onClick={() => sendAboutTextes()}
                >
                  <FontAwesomeIcon
                    icon={["fas", "paper-plane"]}
                    style={{ color: "#141B4D" }}
                  />
                  <span style={{ margin: "0  8px" }}>{t("Submit")}</span>
                </Button>
              </Wysiwyg>
            </FormWizard>
          </Item>
          <Item title={t("GeneralCondition")!} icon="edit">
            <FormWizard>
              <Wysiwyg>
                {conditions.map((item, index) => (
                  <WysiwygContainer key={index}>
                    <LangSelector
                      selected={getCurrentLang(item.language)}
                      onSelect={() => null}
                      countries={[getCurrentLang(item.language)]}
                      customLabels={{ GB: "EN", FR: "FR", DE: "DE" }}
                      showSelectedLabel={true}
                      showOptionLabel={true}
                      fullWidth={false}
                      className="react-flags-internal"
                      disabled={true}
                      selectButtonClassName="no-caret"
                    />
                    <HtmlEditor
                      valueType={"html"}
                      value={item.content}
                      toolbar={toolBarHtmlEditor!}
                      onValueChanged={(e) =>
                        setHtml(e.value, index, "conditions")
                      }
                    ></HtmlEditor>
                  </WysiwygContainer>
                ))}
                <Button
                  variant="primary"
                  style={{ alignSelf: "end" }}
                  onClick={() => sendConditionsTextes()}
                >
                  <FontAwesomeIcon
                    icon={["fas", "paper-plane"]}
                    style={{ color: "#141B4D" }}
                  />
                  <span style={{ margin: "0 8px" }}>{t("Submit")}</span>
                </Button>
              </Wysiwyg>
            </FormWizard>
          </Item>
          <Item title={t("RGPD")!} icon="lock">
            <FormWizard>
              <Wysiwyg>
                {rgpd.map((item, index) => (
                  <WysiwygContainer key={index}>
                    <LangSelector
                      selected={getCurrentLang(item.language)}
                      onSelect={() => null}
                      countries={[getCurrentLang(item.language)]}
                      customLabels={{ GB: "EN", FR: "FR", DE: "DE" }}
                      showSelectedLabel={true}
                      showOptionLabel={true}
                      fullWidth={false}
                      className="react-flags-internal"
                      disabled={true}
                      selectButtonClassName="no-caret"
                    />
                    <HtmlEditor
                      valueType={"html"}
                      value={item.content}
                      toolbar={toolBarHtmlEditor!}
                      onValueChanged={(e) => setHtml(e.value, index, "rgpd")}
                    ></HtmlEditor>
                  </WysiwygContainer>
                ))}
                <Button
                  variant="primary"
                  style={{ alignSelf: "end" }}
                  onClick={() => sendRgpdTextes()}
                  disabled={isLoading}
                >
                  <FontAwesomeIcon
                    icon={["fas", "paper-plane"]}
                    style={{ color: "#141B4D" }}
                  />
                  <span style={{ margin: "0 8px" }}>{t("Submit")}</span>
                </Button>
              </Wysiwyg>
            </FormWizard>
          </Item>
        </TabPanel>
        <LoadPanel
          visible={isLoading}
          showIndicator={true}
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          showPane={false}
          position={{ of: "#main-container-wrapper" }}
          message={t("LoadingData")!}
        />
      </div>
    </ScrollView>
  );
};
