import styled from "styled-components";
import CategoriesTreeList from "../../components/CategoriesTreeList";
import React from "react";

const JurisprudenceContainer = styled.div``;

function FrontEndJurisprudence() {
  return (
    <JurisprudenceContainer>
      <CategoriesTreeList categoryCode={"jurisprudences"} />
    </JurisprudenceContainer>
  );
}
export default FrontEndJurisprudence;
