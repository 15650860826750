import { createGlobalStyle } from "styled-components";
import React from "react";
const StyledGlobalStyle = createGlobalStyle`
    :root {
      --base-font-size: 15px;
      --base-font-family: 'Trebuchet MS', Helvetica, sans-serif;
      --base-content-margin: 50px;
      font-size: var(--base-font-size);
    }

    .dx-viewport .dx-widget {
      font-size: var(--base-font-size) !important;
      font-family: var(--base-font-family) !important;
    }
      
    * {
        font-size: var(--base-font-size);
        font-family: var(--base-font-family);
        color: black;
    } 
        
    body {
        font-size: var(--base-font-size);
        font-family: var(--base-font-family);
        background-color: white;
        margin: 0; 
        overflow-x: hidden; 
        padding-bottom: 30px;
    }

    h1 {
      font-size: calc(var(--base-font-size) + 10px) !important;
    }

    html, body, #root {
      height: 100%
    }

    #root {
      white-space: pre-line;
    }

    header {
      height: 74px;
    }

    .sidebar-wrap{
        height: calc(100vh - (74px));
        width: 100vw;
        overflow: auto;
        overflow-x: hidden;
    }

    .col-menu-nologged{
      padding:0px;
      max-width: 0%
    }

    .col-menu-loggedin-menu-not-collapsed{
      padding-top:35px;
      max-width: 20em;
    }

    .col-menu-loggedin-menu-collapsed{
      padding-top:35px;
      max-width: 8em;
    }

    .col-container-nologged{
      max-width: 100%
      display: flex !important;
      justify-content: center;
    }

    .col-container-loggedin-menu-not-collapsed{
      margin-left: var(--base-content-margin);
      overflow: auto;
      padding-left: 0px;
    }

    .col-container-loggedin-menu-collapsed{
      margin-left: var(--base-content-margin);
      overflow: auto;
      padding-left: 0px;
    }

    .mySidebar:hover{
        background-color:#cccccc
    }

    .treelist-noborder{
      border-top:none;
    }
      
    input[type="file"] {
        position: relative;
        width: 270px;
        overflow: hidden
      }
      
      input[type="file"]::file-selector-button {
        width: 270px;
        color: transparent;
        overflow: hidden
      }
      
      /* Faked label styles and icon */
      input[type="file"]::before {
        position: absolute;
        pointer-events: none;
        top: 10px;
        left: 16px;
        height: 20px;
        width: 20px;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
      }
      
      input[type="file"]::after {
        position: absolute;
        pointer-events: none;
        top: 11px;
        left: 40px;
        color: #0964b0;
      }
      
      /* ------- From Step 1 ------- */
      
      /* file upload button */
      input[type="file"]::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 40px;
        cursor: pointer;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
        transition: background-color 200ms;
      }
      
      /* file upload button hover state */
      input[type="file"]::file-selector-button:hover {
        background-color: #f3f4f6;
      }
      
      /* file upload button active state */
      input[type="file"]::file-selector-button:active {
        background-color: #e5e7eb;
      }

      .dx-popup-content{
        overflow: auto !important;
      }

      .dx-switch-off, .dx-switch-on {
        font-size: 15px;
        font-weight: 500;
      }

      .react-flags-internal *,
      .react-flags-header *{
        color: white;
        border-color: white;
        background-color: #141B4D;
      }

      #rfs-btn::after {
        border-top: 5px solid #fff;
      }

      .dx-datagrid {
        background-color: #F8F8F8;
      }


      .dx-treelist-container {
        background-color: #F8F8F8;
      }

      .dropdown>a::after {
        color: white;
      }

      #summaryButton .dx-button-text {
        color: white !important;
      }

      #summaryClearButton .dx-button-text {
        color: white !important;
      } 

      #categoryButton .dx-button-text {
        color: white !important;
      } 

      .icon-color-white {
       * {
          color: white !important;
        }
      }

      button {
        * {
            color: white !important;
          }
      }

      .accordion-button{
        * {
            color: black !important;
          }
      }

      .btn-link{
        * {
            color: black !important;
          }
      }

      .side-bar-item-image {
          filter: invert(1);
      }

      .favorite-button-treelist {
        color: white !important;
        background-color: #0d6efd;
        width: 40px !important;
        height: 20px !important;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
      }

      .link-to-article-button-treelist {
        color: white !important;
        background-color: rgb(73, 191, 189);
        width: 40px !important;
        height: 20px !important;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
      }
        .langContainer button {
        border: none;
        background-color: transparent;}
        .langContainer button::after {
        display:none;}
      .unfavorite-button-treelist {
        color: black !important;
        background-color: #0d6efd;
        width: 40px !important;
        height: 20px !important;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
      }

      .article-link-button-treelist {
        color: white !important;
        background-color: #0d6efd;
        width: 40px !important;
        height: 20px !important;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
      }

      .see-document-button {
        * {
          color: white !important;
        }
       &:focus { 
       border: 1 px solid black;
      }
      &:hover { 
        background-color: #0d6efd ;
      }

      .dx-switch-on, .dx-switch-off {  
        font-size: 18px;  
     }  

     .buttons-category-detail-second-file{
        color: gold !important;
     }
 
     .dx-button-default {
      background-color: #0d6efd !important;
      border-color: #0d6efd !important;
     }

     .dx-button-success, .btn-success {
      background-color: #5cb85c !important;
      border-color: #5cb85c !important;
     }

     .rpv-core__icon {
      filter: invert(1) !important;
     }

     .rpv-zoom__popover-target-scale, .rpv-zoom__popover-target-scale--ltr {
      filter: invert(1) !important;
     }

     .rpv-core__menu-item-label {
      color: black !important;
     }

     .rpv-core__tooltip-body {
      filter: invert(1) !important;
      * {
          color: lightgray !important;
        }
     }

     .global-search-text-box {
        border-color: white !important;
        * {
            color: white !important;
          }
     }

     .admin-datagrid-captions {
        border: none !important;
        background:  #141b4d !important;
        * {
            color: white !important;
            font-weight: 600 !important;
            font-size: large;
          }
     }

     .react-flags-data-grids {
       padding: 1px; 
       * {
         padding: 1px; 
         background: rgb(248, 248, 248); 
         color: black !important;
         border: none; !important;
         margin: 0px; !important;
       }
       *::after { display: none !important; }
     }

     .react-flags-popup-multi-language {
      padding: 1px; 
      * {
        padding: 1px; 
        background: rgb(248, 248, 248); 
        color: black !important;
        border: none !important;
        margin: 0px !important;
        font-size: 30px !important;
      }
      *::after { display: none !important; }
    }

    .dx-treelist-rowsview .dx-treelist-expanded span::before {
      content: "\f074";
      position: absolute;
      display: block;
      width: 18px;
      top: 50%;
      left: 0;
      margin-left: -5px;
      margin-top: -8px;
    }

    .dx-treelist-rowsview .dx-treelist-collapsed span::before {
      content: "\f00b";
      position: absolute;
      display: block;
      width: 18px;
      top: 50%;
      left: 0;
      margin-left: -6px;
      margin-top: -8px;
  }

  .dx-treelist-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link:focus {
    background-color: #5c95c5 !important;
  }

  .dx-list-item .dx-list-item-content{
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    font-size: smaller !important;
}

  .dx-widget {
    font-size: 16px;
  }

  .dx-treelist {
    max-height: 80vh;
  }

`;

function GlobalStyle() {
  return <StyledGlobalStyle />;
}

export default GlobalStyle;
