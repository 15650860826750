import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
//Import all translation files
import translations from "./translation/translations.json";

const resources = {
  GB: {
    translation: translations.GB,
  },
  DE: {
    translation: translations.DE,
  },
  FR: {
    translation: translations.FR,
  },
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    fallbackLng: "FR",
    lng: "FR",
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
