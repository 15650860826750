import { useLocation, Navigate } from "react-router-dom";
import AccessDenied from "../../pages/AccessDenied";
import { toast } from "react-toastify";
import { isMenuItemsEmpty } from "../functions/functionsUtils";
import { useTranslation } from "react-i18next";
import { getLocalStorageItems } from "../functions/functionsUtils";
import React from "react";

export const GuardedRoute = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  //Translation module
  const { t } = useTranslation();
  //*******************
  const location = useLocation();
  const menuItems = getLocalStorageItems("menus", true);
  const menuHasScreen = menuItems.length
    ? menuItems.find(
        (x: { href: string }) => x.href === location.pathname.toLowerCase()
      )
    : null;

  const auth = !(typeof menuHasScreen === "undefined");

  const redirectHome = () => {
    toast.error(t("NotLoggedInAndRedirect"), {
      position: "top-right",
    });

    return <Navigate to="/" />;
  };

  return auth && children ? (
    children
  ) : isMenuItemsEmpty() ? (
    redirectHome()
  ) : (
    <AccessDenied />
  );
};
