import styled from "styled-components";
import CategoriesTreeList from "../../components/CategoriesTreeList";
import React from "react";

const PublicationContainer = styled.div``;

function FrontEndPublication() {
  return (
    <PublicationContainer>
      <CategoriesTreeList categoryCode={"publications"} />
    </PublicationContainer>
  );
}
export default FrontEndPublication;
