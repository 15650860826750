import styled from "styled-components";
import React from "react";

const ScreenContainer = styled.div`
  text-align: start;
`;

const SpanTitle = styled.h1`
  color: white;
`;

function ScreenTitle(props: any) {
  return (
    <ScreenContainer>
      <SpanTitle>{props.title}</SpanTitle>
    </ScreenContainer>
  );
}

export default ScreenTitle;
