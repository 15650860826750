import ColorBox from "devextreme-react/color-box";
import React from "react";

function ColorPicker(cellData: any) {
  return cellData.data.value ? (
    <ColorBox disabled={true} value={cellData.data.value} />
  ) : (
    <span />
  );
}

export default ColorPicker;
