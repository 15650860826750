import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React from "react";

function StatusButton(rowdata: any) {
  //Translation module
  const { t } = useTranslation();
  //*******************
  if (rowdata.data && rowdata.data.data && rowdata.data.data.status) {
    if (rowdata.data.data.status.toLowerCase() === "created")
      return (
        <Button variant="dark" className="badge" size="sm" disabled={true}>
          {t("Created")}
        </Button>
      );
    if (rowdata.data.data.status.toLowerCase() === "approved")
      return (
        <Button variant="success" className="badge" size="sm" disabled={true}>
          {t("Approved")}
        </Button>
      );
    return (
      <Button variant="warning" className="badge" size="sm" disabled={true}>
        {t("Denied")}
      </Button>
    );
  } else {
    return <span></span>;
  }
}

export default StatusButton;
