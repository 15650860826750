import styled from "styled-components";
import UserForm from "../../components/UserForm";
import { DefaultUserInfo } from "../../models/user";
import { useTranslation } from "react-i18next";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import api_keys from "../../utils/constants/api_keys.json";
import React, { useState, useCallback } from "react";
import { ScrollView } from "devextreme-react";

const SignUpContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 40px;
  padding: 20px;
`;

function SignUp() {
  const user = DefaultUserInfo;
  const [refreshReCaptcha] = useState(false);
  //Translation module
  const { t } = useTranslation();
  //*******************
  const setTokenFunc = useCallback((token: any) => {}, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <SignUpContainer>
        <GoogleReCaptchaProvider
          reCaptchaKey={api_keys.recaptchaKey}
          useEnterprise={true}
        >
          <UserForm
            action="edit"
            data={user}
            title={t("SubscriptionRequest")!}
          />
          <GoogleReCaptcha
            onVerify={setTokenFunc}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider>
      </SignUpContainer>
    </ScrollView>
  );
}

export default SignUp;
