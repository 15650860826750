import React, { useEffect, useState, useRef, useMemo } from "react";
import { Row, Col, Spinner, Button as Btn } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import { Category } from "../../models/category";
import { FileUploadModel } from "../../models/fileUploadModel";
import { useTranslation } from "react-i18next";
import LanguageFlags from "../../components/LanguageFlags";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import {
  DataGrid,
  Column,
  Selection,
  Lookup,
  Editing,
  Paging,
  Button,
  Pager,
  Texts,
  SearchPanel,
  Popup as PopupGrid,
} from "devextreme-react/data-grid";
import { Button as BtnCustom } from "devextreme-react/button";
import {
  Form,
  GroupItem,
  Item as FormItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import { TreeList, Column as TreeListColumn } from "devextreme-react/tree-list";
import "whatwg-fetch";
import { loggedUser, isUserAdmin } from "../../utils/functions/functionsUtils";
import { DocumentRevision } from "../../models/documentRevision";
import { Language } from "../../models/language";
import { Parameter } from "../../models/parameter";
import { Summary } from "../../models/summary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  Popup as MainPopup,
  ToolbarItem as ItemPopup,
} from "devextreme-react/popup";
import { custom } from "devextreme/ui/dialog";
import { ButtonTypes, Button as MainButton } from "devextreme-react/button";
import HtmlEditor from "devextreme-react/html-editor";
import "devextreme/ui/html_editor/converters/markdown";
import { LoadPanel } from "devextreme-react/load-panel";
import { RadioGroup, ScrollView } from "devextreme-react";
import { dxButtonOptions } from "devextreme/ui/button";

const MyProfileContainer = styled.div`
  width: 100%;
  padding: 20px 10px 20px 0px;
`;

const CategoryImage = styled.img`
  padding: 5px;
  height: 40px;
`;

const DivCategory = styled.div<{ color: string }>`
  background-color: ${(prop) => prop.color};
  border-radius: 4px;
`;

const SpanCategory = styled.span`
  padding: 10px;
  font-size: 20px;
  color: white;
`;

const FileUploaderDiv = styled.div<{ content: string }>`
  min-width: 350px;
  padding-bottom: 5px;
  input[type="file"]::after {
    content: "${(prop) => prop.content}";
  }
`;

const HtmlTextArea = styled.div`
  min-width: 350px;
`;

const ButtonText = styled.span`
  padding-left: 5px;
`;

const FileText = styled.span`
  padding-left: 15px;
`;

const ButtonsContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: space-around;
`;

const SaveButtonsContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: end;
`;

const SwitchContainer = styled.div`
  padding-left: 30px;
  display: flex;
`;

const CreatingOrUpdatingContainer = styled.div`
  padding-left: 30px;
  display: flex;
  align-items: center;
`;

const CreatingOrUpdatingText = styled.span`
  padding-left: 5px;
  font-size: larger;
`;

function CategoryDetails() {
  //Translation module
  const { t } = useTranslation();
  //*******************

  const [selectedDetailCategory, setSelectedDetailCategory] =
    useState<Category>({} as Category);
  const [categoriesListTest, setCategoriesListTest] = useState<Category[]>([]);
  const [rowDataToUpload, setRowDataToUpload] = useState<FileUploadModel>(
    {} as FileUploadModel
  );
  const [formInfoData, setFormInfoData] = useState({});
  const [radioButtonOption, setRadioButtonOption] = useState("");
  const [file, setFile] = useState(undefined);
  const [fileName, setFileName] = useState();
  const [secondFile, setSecondFile] = useState(undefined);
  const [secondFileName, setSecondFileName] = useState();
  const [htmlValue, sethtmlValue] = useState("");
  const [languages, setLanguages] = useState<Language[]>([]);
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [documentsRevision, setDocumentsRevision] = useState<
    DocumentRevision[]
  >([]);
  const [formInvalid, setFormInvalid] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
  const [categoryDocumentId, setCategoryDocumentId] = useState(0);
  const [firstRevision, setFirstRevision] = useState(true);
  const [popupSummaryVisible, setPopupSummaryVisible] = useState(false);
  const [popupCategoryVisible, setPopupCategoryVisible] = useState(false);
  const [showAdminGroupItem, setShowAdminGroupItem] = useState(false);
  const [summaries, setSummaries] = useState([]);
  const [selectedSummaries, setSelectedSummaries] = useState([]);
  const [savedSummaries, setSavedSummaries] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<Category[]>([]);
  const [savedCategory, setSavedCategory] = useState<Category[]>([]);
  const [focusedTreeViewRowKeys, setFocusedTreeViewRowKeys] = useState<
    number[]
  >([]);
  const [updatedRow, setUpdatedRow] = useState<Summary>({} as Summary);
  const [colCountForm, setColCountForm] = useState(1);
  const [colCountGroupItemFields, setColCountGroupItemFields] = useState(1);
  const [colCountGroupItemAdmin, setColCountGroupItemAdmin] = useState(1);
  const [loadingData, setLoadingData] = useState(false);

  const inputFile = useRef<HTMLInputElement | null>(null);
  const inputSecondFile = useRef<HTMLInputElement | null>(null);
  const { categoryCode, catDocId } = useParams();
  const navigate = useNavigate();
  const categoryName = categoryCode
    ? categoryCode?.charAt(0).toUpperCase() + categoryCode?.slice(1)
    : "";

  const allowedRevisionsPageSizes = [5];
  let dataGrid: any;
  let summaryDataGrid: any;
  let categoriesTreeList: any;
  const readOnlyOptions = {
    readOnly: true,
  };
  const position = { of: "#main-container-wrapper" };
  const dateTimeOptions = {
    readOnly: true,
    displayFormat: "dd/MM/yyyy",
  };

  useEffect(() => {
    setCategoryDocumentId(parseInt(catDocId!));
    getCategoryByCode();
    getLanguages();
    getDocumentsByCategoryDocumentId(parseInt(catDocId!));
    getSummaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategoryByCode = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategoryByCode}?categoryCode=${categoryCode}`
      )
      .then((response) => {
        setSelectedDetailCategory(response.data.payload);
        getParametersByCategoryId(response.data.payload.id);
        getCategoriesListByCategoryId(response.data.payload.id);
        setRadioButtonOption(
          categoryCode === "legislations" ? "Html" : "Document"
        );
        return response.data.payload;
      });
  };

  const showNotifications = (
    data: any,
    showOnSuccess: boolean,
    message: string
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t(message), {
          position: "top-right",
        });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const deleteFile = (rowData: any) => {
    const payload = rowData;
    payload.categoryCode = categoryCode;
    payload.updatedBy = loggedUser().userName;

    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.deleteCategoryDocument}`,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesDeleteFile");
        getDocumentsByCategoryDocumentId(
          response.data.payload.categoryDocumentId
        );
      });
  };

  const onDeleteFile = (options: any) => {
    const rowData = options.row.data;
    rowData.filePosition = 1;
    return deleteFile(rowData);
  };

  const onDeleteSecondFile = (options: any) => {
    const rowData = options.row.data;
    rowData.filePosition = 2;
    return deleteFile(rowData);
  };

  const onDeleteCategoryDocumentRevision = (options: any) => {
    const payload = options.row.data;
    payload.categoryCode = categoryCode;
    payload.updatedBy = loggedUser().userName;

    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.deleteCategoryDocumentRevision}`,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesDeleteFile");
        getDocumentsByCategoryDocumentId(
          response.data.payload.categoryDocumentId
        );
      });
  };

  const isDownloadOptionVisible = (e: any) => {
    return e.row.data.blobFileName !== null && e.row.data.blobFileName !== "";
  };

  const isSecondDownloadOptionVisible = (e: any) => {
    return (
      e.row.data.blobSecondFileName !== null &&
      e.row.data.blobSecondFileName !== "" &&
      categoryCode === "models"
    );
  };

  const isUploadOptionVisible = (e: any) => {
    return !e.row.data.blobFileName;
  };

  const isSecondUploadOptionVisible = (e: any) => {
    return !e.row.data.blobSecondFileName && categoryCode === "models";
  };

  const onDownloadFile = async (e: any) => {
    return downloadFile(e.row.data.blobFileName);
  };

  const onDownloadSecondFile = async (e: any) => {
    return downloadFile(e.row.data.blobSecondFileName);
  };

  const downloadFile = async (fileName: string) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.downloadCategoryFile}?fileName=${fileName}&categoryCode=${categoryCode} `,
        { responseType: "blob" }
      )
      .then((response: any) => {
        var url = window.URL.createObjectURL(response.data);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const onUploadSecondClick = (e: any) => {
    let payload = e.row.data;
    payload.filePosition = 2;
    payload.categoryId = selectedDetailCategory.id;
    payload.categoryDocumentId = categoryDocumentId;
    payload.updatedBy = loggedUser().userName;
    setRowDataToUpload(payload);
    if (inputSecondFile.current !== null) {
      inputSecondFile.current.click();
    }
  };

  const onUploadClick = (e: any) => {
    let payload = e.row.data;
    payload.filePosition = 1;
    payload.categoryId = selectedDetailCategory.id;
    payload.categoryDocumentId = categoryDocumentId;
    payload.updatedBy = loggedUser().userName;
    setRowDataToUpload(payload);
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };

  const languageOptions = {
    dataSource: languages,
    readOnly: isEditMode,
    valueExpr: "languageId",
    displayExpr: "language",
    onInitialized: function (e: any) {
      if (!e.component.option("value")) {
        e.component.option("value", 1);
      }
    },
  };

  const revisionDateOptions = {
    displayFormat: "dd/MM/yyyy",
    dateSerializationFormat: "yyyy-MM-dd",
    onInitialized: function (e: any) {
      var currentDate = new Date();
      var currentDateWithoutTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      if (!e.component.option("value")) {
        e.component.option("value", currentDateWithoutTime);
      }
    },
  };

  const status = [
    {
      id: 1,
      name: "Brouillon",
    },
    {
      id: 2,
      name: "Publié",
    },
    {
      id: 3,
      name: "Archivé",
    },
  ];

  const statusOptions = {
    dataSource: status,
    valueExpr: "name",
    displayExpr: "name",
  };

  const sources = [
    {
      id: 1,
      name: "CSL.lu",
    },
    {
      id: 2,
      name: "Lexnow.lu",
    },
    {
      id: 3,
      name: "Curia.eu",
    },
    {
      id: 4,
      name: "Justice.lu",
    },
  ];

  const sourceOptions = {
    dataSource: sources,
    valueExpr: "name",
    displayExpr: "name",
  };

  const buttonCategoryAttributes = {
    id: "categoryButton",
    class: "button-category",
  };

  const buttonSummaryClearAttributes = {
    id: "summaryClearButton",
  };

  const getSummaries = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getSummaries}`
      )
      .then((response) => {
        setSummaries(response.data.payload);
      });
  };

  const hideSummaryPopup = () => {
    setPopupSummaryVisible(false);
  };

  const showSummaryPopup = () => {
    setSelectedRows();
    setPopupSummaryVisible(true);
  };

  const hideCategoryPopup = () => {
    setPopupCategoryVisible(false);
  };

  const showCategoryPopup = () => {
    setSelectedCategoryRows();
    setPopupCategoryVisible(true);
  };

  // HTML Box variables
  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];
  const headerValues = [false, 1, 2, 3, 4, 5];
  const fontSizeOptions = {
    inputAttr: {
      "aria-label": "Font size",
    },
  };
  const fontFamilyOptions = {
    inputAttr: {
      "aria-label": "Font family",
    },
  };
  const headerOptions = {
    inputAttr: {
      "aria-label": "Font family",
    },
  };

  const summaryOptions: dxButtonOptions = {
    text:
      t("PopupCategoriesSummary") +
      " " +
      t("PopupCategoriesSelected") +
      " (" +
      savedSummaries.length +
      ")",
    icon: "checklist",
    type: "default",
  };

  const resumeOptions = {
    height: 120,
  };

  const decisionDateOptions = {
    displayFormat: "dd/MM/yyyy",
    dateSerializationFormat: "yyyy-MM-dd",
    onInitialized: function (e: any) {
      var currentDate = new Date();
      var currentDateWithoutTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      if (!e.component.option("value")) {
        e.component.option("value", currentDateWithoutTime);
      }
    },
  };

  //Summary grid buttons
  const saveSummaryButtonOptions = {
    text: t("PopupSave"),
    icon: "save",
    elementAttr: {
      class: "see-document-button",
    },
    type: "success",
    onClick: (e: any) => {
      summaryDataGrid.instance.saveEditData();
    },
  };

  const cancelSummaryButtonOptions = {
    text: t("PopupCancel"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
    onClick: (e: any) => {
      summaryDataGrid.instance.cancelEditData();
    },
  };

  const closeSummaryPopupButtonOptions = {
    onClick: () => {
      hideSummaryPopup();
    },
    text: t("PopupClose"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
  };

  const onSummaryRowInserted = (options: any) => {
    const payload: Summary = options.data;
    payload.id = 0;
    payload.createdBy = loggedUser().userName;
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.createSummary}`,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CagtegoryDetailsCreateSummary");
        getSummaries();
      });
  };

  const onSummaryRowUpdating = (options: any) => {
    options.newData = Object.assign(options.oldData, options.newData);
    setUpdatedRow(options.newData);
  };

  const onSummaryRowUpdated = (options: any) => {
    updatedRow.updatedBy = loggedUser().userName;
    return jwtInterceptor
      .put(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.updateSummary}`,
        updatedRow
      )
      .then((response) => {
        showNotifications(response.data, true, "CagtegoryDetailsUpdateSummary");
        getSummaries();
        return response.data.payload;
      });
  };

  const deleteSummaryData = (e: any) => {
    const summaryData = e.row.data;
    summaryData.updatedBy = loggedUser().userName;
    let confirmDialog = custom({
      title: `${t("DeleteSummary")} : ${summaryData.name} ?`,
      messageHtml: `<b>${t("DeleteSummaryConfirm")}</b>`,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Cancel")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    confirmDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        return jwtInterceptor
          .post(
            `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.deleteSummary}`,
            summaryData
          )
          .then((response) => {
            showNotifications(
              response.data,
              true,
              "CagtegoryDetailsDeleteSummary"
            );
            getSummaries();
            return response.data.payload;
          });
      }
    });
  };

  //Summary popup end

  const closeCategoryPopupButtonOptions = {
    onClick: hideCategoryPopup,
    text: t("PopupClose"),
    icon: "close",
    elementAttr: {
      class: "see-document-button",
    },
    type: "danger",
  };

  const saveSelection = () => {
    setSavedSummaries(selectedSummaries);
    setFormInvalid(false);
    hideSummaryPopup();
  };

  const saveSummaryPopupButtonOptions = {
    onClick: saveSelection,
    text: t("PopupSave"),
    icon: "save",
    elementAttr: {
      class: "see-document-button",
    },
    type: "success",
  };

  const saveCategorySelection = () => {
    setSavedCategory(selectedCategory);
    setFormInvalid(false);
    hideCategoryPopup();
  };

  const saveCategoryPopupButtonOptions = {
    onClick: saveCategorySelection,
    text: t("PopupSave"),
    icon: "save",
    elementAttr: {
      class: "see-document-button",
    },
    type: "success",
  };

  const validateForm = React.useCallback((e: any) => {
    e.component.validate();
  }, []);

  const insertData = (paramts: Parameter[]) => {
    var language = Object.getOwnPropertyDescriptor(formInfoData, "languageId");
    var order = Object.getOwnPropertyDescriptor(formInfoData, "order");

    const htmlText = radioButtonOption === "Html" ? htmlValue : "";

    const payload = {
      categoryId: selectedDetailCategory.id,
      childCategoryId:
        savedCategory.length > 0
          ? savedCategory[0].id
          : selectedDetailCategory.id,
      order: order?.value,
      categoryCode: selectedDetailCategory.code,
      categoryDocumentId: categoryDocumentId,
      documentLanguageId: 0,
      createdBy: loggedUser().userName,
      languageId: language?.value,
      isCreate: isEditMode ? 0 : 1,
      isMasterCreate: firstRevision ? 1 : 0,
      fileName: fileName!,
      isHtml: radioButtonOption === "Html" ? 1 : 0,
      htmlContent: htmlText,
      parameters: paramts,
      summaries: savedSummaries,
    };

    const formData = new FormData();
    formData.append("File", radioButtonOption === "Html" ? "" : file!);
    formData.append(
      "secondFile",
      radioButtonOption === "Html" ? "" : secondFile!
    );
    formData.append("Payload", JSON.stringify(payload!));

    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.uploadCategoryFile} `,
        formData
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesUploadFile");
        setCategoryDocumentId(response.data.payload.categoryDocumentId);
        setSaving(false);
        getDocumentsByCategoryDocumentId(
          response.data.payload.categoryDocumentId
        );
        setLoadingData(false);

        return response.data.payload;
      })
      .finally(() => {
        setSaving(false);
        setLoadingData(false);
      });
  };

  const updateData = (paramts: Parameter[]) => {
    let docLangId = Object.getOwnPropertyDescriptor(
      formInfoData,
      "documentLanguageId"
    );

    const htmlText = radioButtonOption === "Html" ? htmlValue : "";

    const payload = {
      documentLanguageId: docLangId?.value,
      categoryDocumentId: categoryDocumentId,
      childCategoryId: savedCategory[0].id,
      updatedBy: loggedUser().userName,
      isHtml: radioButtonOption === "Html" ? 1 : 0,
      htmlContent: htmlText,
      parameters: paramts,
      summaries: savedSummaries,
    };

    return jwtInterceptor
      .put(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.updateDocumentParameters} `,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesUploadFile");
        setSaving(false);
        getDocumentsByCategoryDocumentId(
          response.data.payload.categoryDocumentId
        );
        setLoadingData(false);

        return response.data.payload;
      })
      .finally(() => {
        setSaving(false);
        setLoadingData(false);
      });
  };

  const saveData = () => {
    setSaving(true);
    setLoadingData(true);
    let parames: Parameter[] = [];
    for (const [key, value] of Object.entries(formInfoData)) {
      if (key !== "languageId") {
        const pr = parameters.filter(function (ft) {
          return ft.name.toLowerCase() === key;
        });
        if (pr.length > 0) {
          let param: Parameter = {
            parameterId: pr.length > 0 ? pr[0].parameterId : 0,
            name: key,
            value: value?.toString() ?? "",
          };
          parames.push(param);
        }
      }
    }

    if (isEditMode) {
      updateData(parames);
    } else {
      insertData(parames);
    }
  };

  const onInitialized = (e: any) => {};

  const checkIncorrectFile = (fileType: string, shouldContain: string) => {
    if (!fileType.includes(shouldContain)) {
      toast.warning(t("IncorrectFileType"), {
        position: "top-right",
      });
      return false;
    }
    return true;
  };

  const saveFileSelected = (file: any) => {
    if (file.target) {
      if (checkIncorrectFile(file.target.files[0].type, "pdf")) {
        setFile(file.target.files[0]);
        setFileName(file.target.files[0].name);
      } else {
        file.target.value = "";
      }
    }
  };

  const saveSecondFileSelected = (secondFile: any) => {
    if (secondFile.target) {
      if (checkIncorrectFile(secondFile.target.files[0].type, "doc")) {
        setSecondFile(secondFile.target.files[0]);
        setSecondFileName(secondFile.target.files[0].name);
      } else {
        secondFile.target.value = "";
      }
    }
  };

  const onFieldDataChanged = async (e: any) => {
    setFormInfoData(e.component.option("formData"));
    let res = e.component.validate();

    setFormInvalid(res.brokenRules.length > 0);
  };

  const handleRadioGroupValueChange = (e: any) => {
    setRadioButtonOption(e.value);
  };

  const itemsRadioButton = ["Html", "Document"];

  const getLanguages = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getLanguages}`
      )
      .then((response) => {
        setLanguages(response.data.payload);
        return response.data.payload;
      });
  };

  const getParametersByCategoryId = (categoryId: number) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getParametersByCategoryId}?categoryId=${categoryId}&isVisibleOnFrontEnd=1`
      )
      .then((response) => {
        setParameters(response.data.payload);
        return response.data.payload;
      });
  };

  const getCategoriesListByCategoryId = (categoryId: number) => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategoriesListByCategoryId}?categoryId=${categoryId}`
      )
      .then((response) => {
        setCategoriesListTest(response.data.payload);
        return response.data.payload;
      });
  };

  const refreshGrid = () => {
    dataGrid.instance.beginCustomLoading();
    getDocumentsByCategoryDocumentId(categoryDocumentId);
    dataGrid.instance.endCustomLoading();
  };

  const getDocumentsByCategoryDocumentId = (catDoc: number) => {
    setLoadingData(true);

    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getDocumentsByCategoryDocumentId}?categoryDocumentId=${catDoc}`
      )
      .then((response) => {
        setDocumentsRevision(response.data.payload);
        setFirstRevision(response.data.payload.length <= 0);
        setLoadingData(false);

        return response.data.payload;
      });
  };

  const backToParent = () => {
    navigate(`/${categoryCode}`);
  };

  const onFocusedRowChanged = (e: any) => {
    clearSelection();
    setSelectedSummaries([]);

    if (e.row === undefined) {
      return;
    }

    setFocusedRowIndex(e.rowIndex);

    let newForm = e.row.data;
    let newArray: any[] = [];

    const newParameters = parameters.map(
      ({ parameterId, name, value, ...props }) => {
        let paramValue = e.row.data.parameters.filter(function (ft: any) {
          return ft.name.toLowerCase() === name.toLowerCase();
        });

        return {
          parameterId: parameterId,
          name: name,
          value: paramValue.length ? paramValue[0].value : "",
          ...props,
        };
      }
    );

    setSavedSummaries(e.row.data.summaries);

    let catToArray: Category[] = [];
    catToArray.push(e.row.data.childCategory);
    setSavedCategory(catToArray);

    newParameters.forEach((element: any) => {
      const obj = [element.name.toLowerCase(), element.value];
      newArray.push(obj);
    });

    if (e.row.data.isHtml === 1) {
      sethtmlValue(e.row.data.htmlContent);
    }
    setParameters(newParameters);
    setFormInfoData(newForm);
  };

  const resetGridStatus = () => {
    dataGrid.instance.clearSelection();
    dataGrid.instance.option("focusedRowIndex", -1);
    setFocusedRowIndex(-1);
  };

  const onSwitchValueChanged = () => {
    resetGridStatus();
    setIsEditMode(!isEditMode);
  };

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "refresh",
        type: "success",
        elementAttr: {
          id: "refreshCategory",
          class: "see-document-button",
        },
        onClick: refreshGrid,
      },
    });
  };

  const onSummarySelectionChanged = ({ selectedRowsData }: any) => {
    setSelectedSummaries(selectedRowsData);
  };

  const onCategorySelectionChanged = (e: any) => {
    const arr: Category[] = [];
    arr.push(e.row.data);
    setSelectedCategory(arr);
  };

  const clearSelection = () => {
    summaryDataGrid.instance.clearSelection();
  };

  const clearSelectionOptions = {
    text: t("PopupCategoriesSummaryClearSelection"),
    icon: "clearformat",
    type: "default",
    disabled: selectedSummaries.length <= 0,
    elementAttr: buttonSummaryClearAttributes,
    onClick: clearSelection,
  };

  const getCategorySelection = () => {
    return selectedCategory.length
      ? selectedCategory.map((a: any) => a.name)
      : t("PopupCategoriesCategoryNoSelection");
  };

  const setSelectedRows = () => {
    summaryDataGrid.instance.selectRows(savedSummaries, true);
  };

  const setSelectedCategoryRows = () => {
    let catToHighlight: number[] = [];
    catToHighlight.push(savedCategory.length ? savedCategory[0].id : -1);
    setFocusedTreeViewRowKeys(catToHighlight);
    categoriesTreeList.instance.option(
      "focusedRowKey",
      catToHighlight.length ? catToHighlight[0] : -1
    );
  };

  const showHideAdminGroupItem = () => {
    setShowAdminGroupItem(!showAdminGroupItem);
  };

  const uploadDocumentRevision = async (e: any) => {
    const payload = rowDataToUpload;

    if (
      payload.filePosition === 1 &&
      !checkIncorrectFile(e.target.files[0].type, "pdf")
    )
      return;

    if (
      payload.filePosition === 2 &&
      !checkIncorrectFile(e.target.files[0].type, "doc")
    )
      return;

    setLoadingData(true);

    const formData = new FormData();
    formData.append("File", e.target.files[0]);
    formData.append("FileName", e.target.files[0].name);
    formData.append("Payload", JSON.stringify(payload!));
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.uploadDocumentRevision} `,
        formData
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesUploadFile");
        getDocumentsByCategoryDocumentId(
          response.data.payload.categoryDocumentId
        );
        setLoadingData(false);

        return response.data.payload;
      });
  };

  const screenByWidth = (width: any) => {
    if (width < 1200) {
      setColCountForm(1);
      setColCountGroupItemFields(1);
      setColCountGroupItemAdmin(1);
    } else {
      if (showAdminGroupItem) {
        setColCountForm(2);
        setColCountGroupItemFields(2);
        setColCountGroupItemAdmin(2);
      } else {
        setColCountForm(1);
        setColCountGroupItemFields(3);
        setColCountGroupItemAdmin(2);
      }
    }
  };

  const toolBarHtmlEditor = useMemo(() => {
    return {
      multiline: true,
      items: [
        {
          name: "header",
          acceptedValues: headerValues,
          options: headerOptions,
        },
        {
          name: "size",
          acceptedValues: sizeValues,
          options: fontSizeOptions,
        },
        {
          name: "font",
          acceptedValues: fontValues,
          options: fontFamilyOptions,
        },
        { name: "undo" },
        { name: "redo" },
        { name: "separator" },
        { name: "bold" },
        { name: "italic" },
        { name: "strike" },
        { name: "underline" },
        { name: "separator" },
        { name: "alignLeft" },
        { name: "alignCenter" },
        { name: "alignRight" },
        { name: "alignJustify" },
        { name: "separator" },
        { name: "orderedList" },
        { name: "bulletList" },
        { name: "separator" },
        { name: "color" },
        { name: "background" },
        { name: "separator" },
        { name: "link" },
        { name: "separator" },
        { name: "clear" },
        { name: "codeBlock" },
        { name: "blockquote" },
        { name: "separator" },
        { name: "insertTable" },
        { name: "deleteTable" },
        { name: "insertRowAbove" },
        { name: "insertRowBelow" },
        { name: "deleteRow" },
        { name: "insertColumnLeft" },
        { name: "insertColumnRight" },
        { name: "deleteColumn" },
        { name: "subscript" },
        { name: "superscript" },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <MyProfileContainer id="CategoryDetailsContainerId">
        <LoadPanel
          visible={loadingData}
          showIndicator={true}
          shading={true}
          shadingColor="rgba(0,0,0,0.4)"
          showPane={false}
          position={position}
          message={t("LoadingData")!}
        />
        <Row className="mb-4">
          <Col className="d-flex">
            <DivCategory color={selectedDetailCategory.color}>
              <CategoryImage src={selectedDetailCategory.base64Image} />
              <SpanCategory color={selectedDetailCategory.color}>
                {t(categoryName)}
              </SpanCategory>
            </DivCategory>
            <ButtonsContainer>
              <Btn
                variant="secondary"
                style={{ minWidth: "100px" }}
                onClick={backToParent}
              >
                <FontAwesomeIcon
                  icon={["fas", "arrow-left"]}
                  style={{ color: "#141B4D" }}
                />
                <ButtonText>{t("BackButton")}</ButtonText>
              </Btn>
            </ButtonsContainer>
            <ButtonsContainer hidden={showAdminGroupItem || !isUserAdmin()}>
              <Btn
                variant="primary"
                style={{ minWidth: "40px" }}
                onClick={showHideAdminGroupItem}
              >
                <FontAwesomeIcon
                  icon={["fas", "eye"]}
                  style={{ color: "#141B4D" }}
                />
              </Btn>
            </ButtonsContainer>
            <ButtonsContainer hidden={!showAdminGroupItem || !isUserAdmin()}>
              <Btn
                variant="primary"
                style={{ minWidth: "40px" }}
                onClick={showHideAdminGroupItem}
              >
                <FontAwesomeIcon
                  icon={["fas", "eye-slash"]}
                  style={{ color: "#141B4D" }}
                />
              </Btn>
            </ButtonsContainer>
            <SwitchContainer hidden={isEditMode}>
              <Btn
                variant="primary"
                disabled={firstRevision}
                onClick={onSwitchValueChanged}
              >
                <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
                <ButtonText> {t("CategoriesSwitchOn")}</ButtonText>
              </Btn>
              {!firstRevision && (
                <CreatingOrUpdatingContainer>
                  <FontAwesomeIcon icon={["fas", "triangle-exclamation"]} />
                  <CreatingOrUpdatingText>
                    {t("IsCreating")}
                  </CreatingOrUpdatingText>
                </CreatingOrUpdatingContainer>
              )}
            </SwitchContainer>
            <SwitchContainer hidden={!isEditMode}>
              <Btn
                variant="success"
                disabled={firstRevision}
                onClick={onSwitchValueChanged}
              >
                <FontAwesomeIcon icon={["fas", "plus"]} />
                <ButtonText> {t("CategoriesSwitchOff")}</ButtonText>
              </Btn>
              {!firstRevision && (
                <CreatingOrUpdatingContainer>
                  <FontAwesomeIcon icon={["fas", "triangle-exclamation"]} />
                  <CreatingOrUpdatingText>
                    {t("IsEditing")}
                  </CreatingOrUpdatingText>
                </CreatingOrUpdatingContainer>
              )}
            </SwitchContainer>
            <input
              type="file"
              id="file"
              ref={inputFile}
              accept=".pdf"
              style={{ display: "none" }}
              onChange={uploadDocumentRevision}
            />

            <input
              type="file"
              id="secondFile"
              accept=".doc,.docx"
              ref={inputSecondFile}
              style={{ display: "none" }}
              onChange={uploadDocumentRevision}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Form
              id="form"
              formData={formInfoData}
              colCount={colCountForm}
              screenByWidth={screenByWidth}
              onContentReady={validateForm}
              onFieldDataChanged={onFieldDataChanged}
              readOnly={false}
              onInitialized={onInitialized}
            >
              <GroupItem
                caption={t(`PopupCategoriesAdd${selectedDetailCategory.code}`)!}
                colCount={colCountGroupItemFields}
              >
                {parameters &&
                  parameters.map((item: any, key: any) =>
                    item.editorType !== "dxButton" ? (
                      <FormItem
                        dataField={item.name.toLowerCase()}
                        editorType={item.editorType}
                        editorOptions={
                          item.name === "Status"
                            ? statusOptions
                            : item.name === "Source"
                            ? sourceOptions
                            : item.name === "Summary"
                            ? summaryOptions
                            : item.name === "DecisionDate"
                            ? decisionDateOptions
                            : item.name === "RevisionDate"
                            ? revisionDateOptions
                            : item.name === "Resume"
                            ? resumeOptions
                            : {}
                        }
                      >
                        <Label text={t(`PopupCategories${item.name}`)!} />
                        {item.isRequired === 1 && (
                          <RequiredRule
                            message={t(`PopupCategories${item.name}Required`)!}
                          />
                        )}
                      </FormItem>
                    ) : (
                      <FormItem>
                        <Label
                          text={t(`PopupCategories${item.name}`)!}
                          location="left"
                          alignment="right"
                          visible
                        />
                        <BtnCustom
                          elementAttr={summaryOptions}
                          bindingOptions={summaryOptions}
                          text={t(`PopupCategories${item.name}`)!}
                          onClick={showSummaryPopup}
                          id="summaryButton"
                          className="button-summary"
                        ></BtnCustom>

                        {item.isRequired === 1 && (
                          <RequiredRule
                            message={t(`PopupCategories${item.name}Required`)!}
                          />
                        )}
                      </FormItem>
                    )
                  )}

                <FormItem
                  dataField="languageId"
                  editorType="dxSelectBox"
                  editorOptions={languageOptions}
                >
                  <Label text={t("Language")!} />
                  <RequiredRule
                    message={t("PopupCategoriesLanguageRequired")!}
                  />
                </FormItem>
              </GroupItem>

              <GroupItem
                caption={t("UserinfoAdmin")!}
                colCount={colCountGroupItemAdmin}
                visible={showAdminGroupItem}
              >
                <FormItem
                  dataField="id"
                  editorOptions={readOnlyOptions}
                ></FormItem>
                <FormItem
                  dataField="documentLanguageId"
                  editorOptions={readOnlyOptions}
                ></FormItem>
                <FormItem
                  dataField="categoryDocumentId"
                  editorOptions={readOnlyOptions}
                ></FormItem>
                <FormItem dataField="order" editorOptions={readOnlyOptions}>
                  <Label text={t("Order")!} />
                </FormItem>
                <FormItem
                  dataField="blobFileName"
                  editorOptions={readOnlyOptions}
                ></FormItem>
                <FormItem
                  dataField="blobSecondFileName"
                  editorOptions={readOnlyOptions}
                  visible={categoryCode === "models"}
                ></FormItem>
                <FormItem
                  dataField="blobFilePath"
                  editorOptions={readOnlyOptions}
                ></FormItem>
                <FormItem
                  dataField="blobSecondFilePath"
                  editorOptions={readOnlyOptions}
                  visible={categoryCode === "models"}
                ></FormItem>
                <FormItem
                  dataField="createdOn"
                  editorOptions={dateTimeOptions}
                  visible={false}
                ></FormItem>
                <FormItem
                  dataField="createdBy"
                  editorOptions={readOnlyOptions}
                  visible={false}
                ></FormItem>
                <FormItem
                  dataField="updatedOn"
                  editorOptions={dateTimeOptions}
                  visible={false}
                ></FormItem>
                <FormItem
                  dataField="updatedBy"
                  editorOptions={readOnlyOptions}
                  visible={false}
                ></FormItem>
              </GroupItem>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col className="pt-1">
            <div className="buttons-column">
              <div className="column-header">{t("CategoryName")}</div>
              <div className="pt-1">
                <MainButton
                  text={
                    t("CategoryName") +
                    " " +
                    t("PopupCategoriesSelected") +
                    " (" +
                    savedCategory.length +
                    ")"
                  }
                  type="success"
                  stylingMode="contained"
                  icon="checklist"
                  onClick={showCategoryPopup}
                  elementAttr={buttonCategoryAttributes}
                />
              </div>
              <div className="mt-1">
                <Badge bg="info" style={{ fontSize: 12 }}>
                  {savedCategory.length > 0
                    ? savedCategory[0].name
                    : t("PopupCategoriesCategoryNoSelection")!}
                </Badge>
              </div>
            </div>
          </Col>

          <Col>
            <div className="dx-field">
              <div className="dx-field-label">{t("PopupCategoriesIsHtml")}</div>
              <div className="dx-field-value">
                <RadioGroup
                  items={itemsRadioButton}
                  disabled={isEditMode}
                  value={radioButtonOption}
                  layout="horizontal"
                  onValueChanged={handleRadioGroupValueChange}
                />
              </div>
            </div>
            <FileUploaderDiv
              hidden={radioButtonOption === "Html"}
              content={t("FileUpload1")}
            >
              <input
                type="file"
                disabled={isEditMode}
                onChange={saveFileSelected}
                accept="application/pdf"
              />
              <FileText>{fileName}</FileText>
            </FileUploaderDiv>

            <FileUploaderDiv
              hidden={radioButtonOption === "Html" || categoryCode !== "models"}
              content={t("FileUpload2")}
            >
              <input
                type="file"
                disabled={isEditMode}
                onChange={saveSecondFileSelected}
                accept=".doc,.docx"
              />
              <FileText>{secondFileName}</FileText>
            </FileUploaderDiv>

            <HtmlTextArea hidden={radioButtonOption !== "Html"}>
              <div className="widget-container">
                <HtmlEditor
                  valueType={"markdown"}
                  value={htmlValue}
                  toolbar={toolBarHtmlEditor!}
                  onValueChanged={(e) => sethtmlValue(e.value)}
                ></HtmlEditor>
              </div>
            </HtmlTextArea>
          </Col>
        </Row>

        <Row className="pt-2">
          <Col>
            <SaveButtonsContainer>
              <Btn
                variant="success"
                style={{ minWidth: "120px" }}
                onClick={saveData}
                disabled={
                  isEditMode
                    ? formInvalid
                    : formInvalid ||
                      (!file && !secondFile && radioButtonOption !== "Html")
                }
              >
                {isSaving && (
                  <Spinner
                    style={{ marginRight: "10px" }}
                    animation="border"
                    role="status"
                    size="sm"
                  >
                    <span className="visually-hidden">{t("Saving")}</span>
                  </Spinner>
                )}
                <FontAwesomeIcon
                  icon={["fas", "save"]}
                  style={{ color: "#141B4D" }}
                />
                <ButtonText>{t("PopupSave")}</ButtonText>
              </Btn>
            </SaveButtonsContainer>
          </Col>
        </Row>

        <Row className="pt-2">
          <Col>
            <DataGrid
              id="revisionGrids"
              keyExpr="id"
              dataSource={documentsRevision}
              columnAutoWidth={true}
              showBorders={true}
              focusedRowEnabled={true}
              focusedRowIndex={focusedRowIndex}
              noDataText={t("NoDataCategoryFiles")!}
              rowAlternationEnabled={true}
              onFocusedRowChanged={onFocusedRowChanged}
              onToolbarPreparing={onToolbarPreparing}
              ref={(ref) => {
                dataGrid = ref;
              }}
            >
              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />
              <Selection mode="single" />
              <Paging defaultPageSize={5} defaultPageIndex={0} enabled={true} />
              <Pager
                visible={true}
                allowedPageSizes={allowedRevisionsPageSizes}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Column
                type="buttons"
                caption={t("Actions")!}
                width={categoryCode === "models" ? 220 : 140}
              >
                <Button
                  hint={t("HintDelete")!}
                  icon="remove"
                  onClick={onDeleteCategoryDocumentRevision}
                  visible={isDownloadOptionVisible}
                />
                <Button
                  hint={t("HintDeleteFile")!}
                  icon="clearformat"
                  onClick={onDeleteFile}
                  visible={isDownloadOptionVisible}
                />
                <Button
                  hint={t("HintDownload")!}
                  icon="download"
                  onClick={onDownloadFile}
                  visible={isDownloadOptionVisible}
                />
                <Button
                  hint={t("HintUpload")!}
                  icon="upload"
                  onClick={onUploadClick}
                  visible={isUploadOptionVisible}
                />

                <Button
                  hint={t("HintSecondDeleteFile")!}
                  icon="clearformat"
                  cssClass="buttons-category-detail-second-file"
                  onClick={onDeleteSecondFile}
                  visible={isSecondDownloadOptionVisible}
                />
                <Button
                  hint={t("HintDownloadSecondFile")!}
                  icon="download"
                  cssClass="buttons-category-detail-second-file"
                  onClick={onDownloadSecondFile}
                  visible={isSecondDownloadOptionVisible}
                />
                <Button
                  hint={t("HintUploadSecondFile")!}
                  icon="upload"
                  cssClass="buttons-category-detail-second-file"
                  onClick={onUploadSecondClick}
                  visible={isSecondUploadOptionVisible}
                />
              </Column>
              <Column
                dataField="id"
                allowEditing={false}
                visible={false}
              ></Column>
              <Column
                dataField="documentLanguageId"
                allowEditing={false}
                visible={false}
              ></Column>
              <Column
                dataField="childCategoryId"
                allowEditing={false}
                visible={false}
              ></Column>
              <Column
                dataField="childCategory"
                allowEditing={false}
                visible={false}
              ></Column>
              <Column
                dataField="number"
                caption={t("PopupCategoriesRevision")!}
                allowEditing={false}
                alignment={"center"}
              ></Column>
              <Column
                dataField="revisionDate"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("PopupCategoriesRevisionDate")!}
                allowEditing={false}
                alignment={"center"}
              ></Column>
              <Column
                dataField="status"
                caption={t("PopupCategoriesStatus")!}
                allowEditing={false}
                alignment={"center"}
              ></Column>
              <Column
                dataField="languageId"
                caption={t("Language")!}
                allowHeaderFiltering={false}
                visible={false}
              >
                <Lookup
                  dataSource={languages}
                  displayExpr="language"
                  valueExpr="languageId"
                />
              </Column>
              <Column
                dataField="language"
                caption={t("Language")!}
                allowHeaderFiltering={false}
                cellRender={(data) => <LanguageFlags data={data} />}
                alignment={"center"}
              ></Column>
              <Column
                dataField="blobFileName"
                caption={t("CategoryFileName")!}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="blobSecondFileName"
                caption={t("CategorySecondFileName")!}
                allowHeaderFiltering={false}
                visible={categoryCode === "models"}
              />
              <Column
                dataField="blobFilePath"
                visible={false}
                caption={t("CategoryFilePath")!}
                width={400}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="blobSecondFilePath"
                visible={false}
                caption={t("CategorySecondFilePath")!}
                width={400}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="createdBy"
                caption={t("CreatedBy")!}
                alignment={"center"}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="createdOn"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("CreatedOn")!}
                alignment={"center"}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="updatedBy"
                caption={t("UpdatedBy")!}
                alignment={"center"}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="updatedOn"
                dataType="date"
                alignment={"center"}
                format="dd/MM/yyyy"
                caption={t("UpdatedOn")!}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="parameters"
                allowEditing={false}
                visible={false}
              ></Column>
              <Column
                dataField="summaries"
                allowEditing={false}
                visible={false}
              ></Column>
              <Column
                dataField="isHtml"
                allowEditing={false}
                visible={false}
              ></Column>
              <Column
                dataField="htmlContent"
                allowEditing={false}
                visible={false}
              ></Column>
            </DataGrid>
          </Col>
        </Row>

        <MainPopup
          id="summaryPopup"
          visible={popupSummaryVisible}
          onHiding={hideSummaryPopup}
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          title={t("PopupCategoriesSummary")!}
          container=".dx-viewport"
        >
          <ItemPopup
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeSummaryPopupButtonOptions}
          />
          <ItemPopup
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={saveSummaryPopupButtonOptions}
          />
          <ItemPopup
            toolbar="top"
            location="after"
            widget="dxButton"
            options={clearSelectionOptions}
          />
          <DataGrid
            id="gridSummaryPopup"
            dataSource={summaries}
            showRowLines={true}
            showColumnLines={false}
            onSelectionChanged={onSummarySelectionChanged}
            onRowUpdating={onSummaryRowUpdating}
            onRowUpdated={onSummaryRowUpdated}
            onRowInserted={onSummaryRowInserted}
            noDataText={t("DashboardSummaryNoResults")!}
            showBorders={true}
            rowAlternationEnabled={true}
            ref={(ref) => {
              summaryDataGrid = ref;
            }}
          >
            <Paging defaultPageSize={10} />
            <SearchPanel
              visible={true}
              width={240}
              placeholder={t("Search")!}
            />
            <Selection
              mode="multiple"
              selectAllMode="allPages"
              showCheckBoxesMode="onClick"
            />

            <Editing
              refreshMode="reshape"
              mode="popup"
              useIcons={true}
              allowAdding={isUserAdmin()}
              allowUpdating={isUserAdmin()}
              allowDeleting={isUserAdmin()}
            >
              <Texts confirmDeleteMessage={t("PopupDeleteMessage")!} />
              <PopupGrid
                title={t("PopupCategoriesSummary")!}
                showTitle={false}
                width={"60vw"}
                height={"60vh"}
              >
                <ItemPopup
                  toolbar="bottom"
                  location="after"
                  widget="dxButton"
                  options={saveSummaryButtonOptions}
                />
                <ItemPopup
                  toolbar="bottom"
                  location="after"
                  widget="dxButton"
                  options={cancelSummaryButtonOptions}
                />
              </PopupGrid>
            </Editing>

            <Column dataField="id" width={40} allowEditing={false} />
            <Column dataField="name" />
            <Column
              dataField="createdBy"
              caption={t("CreatedBy")!}
              allowEditing={false}
              alignment={"center"}
              allowHeaderFiltering={false}
            />
            <Column
              dataField="createdOn"
              dataType="date"
              format="dd/MM/yyyy"
              caption={t("CreatedOn")!}
              alignment={"center"}
              allowEditing={false}
              allowHeaderFiltering={false}
            />
            <Column
              dataField="updatedBy"
              caption={t("UpdatedBy")!}
              allowEditing={false}
              alignment={"center"}
              allowHeaderFiltering={false}
            />
            <Column
              dataField="updatedOn"
              dataType="date"
              alignment={"center"}
              allowEditing={false}
              format="dd/MM/yyyy"
              caption={t("UpdatedOn")!}
              allowHeaderFiltering={false}
            />

            <Column
              type="buttons"
              caption={t("Actions")!}
              width={150}
              visible={isUserAdmin()}
            >
              <Button name="edit" hint={t("HintEdit")!} />
              <Button
                hint={t("HintInnactivateUser")!}
                icon="trash"
                onClick={deleteSummaryData}
                visible={isUserAdmin()}
              />
            </Column>
          </DataGrid>
          <div className="selected-data">
            <span className="caption">
              {t("PopupCategoriesSummaryTotalSelection") +
                " (" +
                selectedSummaries.length +
                ") " +
                ": "}{" "}
            </span>

            {selectedSummaries.length ? (
              selectedSummaries.map((a: any) => (
                <span className="p-1">
                  <Badge bg="info" style={{ fontSize: 12 }}>
                    {a.name}
                  </Badge>
                </span>
              ))
            ) : (
              <Badge bg="info" style={{ fontSize: 12 }}>
                {t("PopupCategoriesSummaryNoSelection")}
              </Badge>
            )}
          </div>
        </MainPopup>

        <MainPopup
          id="categoryPopup"
          visible={popupCategoryVisible}
          onHiding={hideCategoryPopup}
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          title={t("CategoryName")!}
          container=".dx-viewport"
        >
          <ItemPopup
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeCategoryPopupButtonOptions}
          />
          <ItemPopup
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={saveCategoryPopupButtonOptions}
          />

          <TreeList
            id="treeviewCategories"
            dataSource={categoriesListTest}
            showColumnHeaders={true}
            columnAutoWidth={true}
            autoExpandAll={true}
            autoNavigateToFocusedRow={true}
            keyExpr="id"
            parentIdExpr="parentId"
            allowColumnReordering={false}
            allowColumnResizing={false}
            showRowLines={true}
            showBorders={true}
            noDataText={t("NoCategory")!}
            focusedRowEnabled={true}
            selectedRowKeys={focusedTreeViewRowKeys}
            onFocusedRowChanged={onCategorySelectionChanged}
            ref={(ref) => {
              categoriesTreeList = ref;
            }}
          >
            <SearchPanel
              visible={true}
              width={240}
              placeholder={t("Search")!}
            />
            <TreeListColumn dataField="id" visible={false} />
            <TreeListColumn dataField="name" caption={t("CategoryName")!} />
          </TreeList>
          <div className="mt-3">
            <span className="caption">
              {t("PopupCategoriesSummaryTotalSelection") +
                " (" +
                selectedCategory.length +
                ") " +
                ": "}{" "}
            </span>
            <Badge bg="info">{getCategorySelection()}</Badge>
          </div>
        </MainPopup>
      </MyProfileContainer>
    </ScrollView>
  );
}

export default CategoryDetails;
