import React, { useState } from "react";
import {
  Form,
  GroupItem,
  Item,
  Label,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import { Row, Col, Button as Btn, Spinner } from "react-bootstrap";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../../models/user";
import { useTranslation } from "react-i18next";
import { FieldDataChangedEvent, InitializedEvent } from "devextreme/ui/form";
import { ScrollView } from "devextreme-react";

const RequestResetPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 40px;
  padding: 20px;
`;

const UserFormContainer = styled.div`
  padding-top: 20px;
  min-width: 500px;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ButtonText = styled.span`
  padding-left: 5px;
`;

function RequestResetPassword() {
  const [isFormFullFilled, setIsFormFullFilled] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [userData, setUserData] = useState<UserInfo>({} as UserInfo);
  const navigate = useNavigate();

  //Translation module
  const { t } = useTranslation();
  //*******************

  let onInitialized = (e: InitializedEvent) => {};

  const onFieldDataChanged = async (e: FieldDataChangedEvent) => {
    setUserData(e.component.option("formData"));

    let res = e.component.validate();

    if (res?.brokenRules?.length && res?.brokenRules?.length >= 1) {
      setIsFormFullFilled(false);
    } else {
      setIsFormFullFilled(true);
    }
  };

  const backToHome = async () => {
    navigate("/");
  };

  const handleSavingState = (st: boolean) => {
    setSaving(st);
  };

  const showNotifications = (data: any, showOnSuccess: boolean) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t("ResetPasswordSuccess"), { position: "top-right" });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const changeUserInfo = async () => {
    handleSavingState(true);
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.resetUserPassword_URL}`,
        userData
      )
      .then((response) => {
        showNotifications(response.data, true);
        handleSavingState(false);
      });
  };

  const validateForm = React.useCallback((e: any) => {
    e.component.validate();
  }, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <RequestResetPasswordContainer>
        <UserFormContainer>
          <Row>
            <Col>
              <Form
                formData={userData}
                colCount={1}
                onContentReady={validateForm}
                onFieldDataChanged={onFieldDataChanged}
                onInitialized={onInitialized}
              >
                <GroupItem caption={t("PwdReset")!} colCount={1}>
                  <Item dataField="email">
                    <Label>{t("Email")!}</Label>
                    <RequiredRule message={t("EmailRequired")!} />
                    <EmailRule message={t("EmailInvalid")!} />
                  </Item>
                </GroupItem>
              </Form>
              <Row>
                <Col>
                  <ButtonsContainer>
                    <Btn
                      variant="success"
                      style={{ minWidth: "120px" }}
                      onClick={changeUserInfo}
                      disabled={isFormFullFilled === false ? true : false}
                    >
                      {isSaving && (
                        <Spinner
                          style={{ marginRight: "10px" }}
                          animation="border"
                          role="status"
                          size="sm"
                        >
                          <span className="visually-hidden">{t("Saving")}</span>
                        </Spinner>
                      )}
                      <FontAwesomeIcon
                        icon={["fas", "floppy-disk"]}
                        style={{ color: "#141B4D" }}
                      />
                      <ButtonText>{t("Confirm")}</ButtonText>
                    </Btn>
                    <Btn
                      variant="primary"
                      style={{ minWidth: "120px" }}
                      onClick={backToHome}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "home"]}
                        style={{ color: "#141B4D" }}
                      />
                      <ButtonText>{t("HomeButton")}</ButtonText>
                    </Btn>
                  </ButtonsContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </UserFormContainer>
      </RequestResetPasswordContainer>
    </ScrollView>
  );
}

export default RequestResetPassword;
