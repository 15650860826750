import React, { useCallback } from "react";
import SelectBox from "devextreme-react/select-box";
import TextBox from "devextreme-react/text-box";
import { useTheme } from "../../utils/context/ThemeContext";
import "./ThemeSelector.scss";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import { useTranslation } from "react-i18next";

const TextBoxInputAttr = { class: "dx-theme-text-color theme-textbox" };
const DropDownOptions = { wrapperAttr: { class: "selectboxDropdown" } };

const fieldRender = (data: { text: string }) => {
  return (
    <div className={"custom-item"}>
      <TextBox
        style={{ padding: 10 }}
        inputAttr={TextBoxInputAttr}
        value={data.text}
        readOnly={true}
      />
    </div>
  );
};

const itemRender = (data: { text: string }) => {
  return (
    <div className={"custom-item"}>
      <div className={"theme-text"}>{data.text}</div>
    </div>
  );
};

export function ThemeSelector(): JSX.Element {
  const { getTheme, getThemeData, setTheme } = useTheme();
  const { t } = useTranslation();
  const themes = getThemeData();
  const currentTheme = getTheme();
  let onValueChanged = useCallback(
    (e: ValueChangedEvent) => {
      setTheme(e.value);
    },
    [setTheme]
  );

  return (
    <SelectBox
      className={"theme-selector-item"}
      items={themes.map((item: { text: string; value: string }) => {
        item.text = t(item.text);
        return item;
      })}
      value={currentTheme}
      valueExpr="value"
      displayExpr="text"
      label={t("themeConfigurator")!}
      // @ts-ignore
      labelMode="outside"
      onValueChanged={onValueChanged}
      stylingMode="outlined"
      fieldRender={fieldRender}
      itemRender={itemRender}
      deferRendering={false}
      dropDownOptions={DropDownOptions}
    ></SelectBox>
  );
}
