import React, { useEffect, useState } from "react";
import { Row, Col, Badge } from "react-bootstrap";
import styled from "styled-components";
import jwtInterceptor from "../shared/jwtInterceptor";
import { Category } from "../../models/category";
import { useTranslation } from "react-i18next";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import {
  DataGrid,
  Column,
  SearchPanel,
  Selection,
  HeaderFilter,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import "whatwg-fetch";
import { useParams } from "react-router";
import { LoadPanel } from "devextreme-react/load-panel";
import { ScrollView } from "devextreme-react";

//Styled
const DocumentGridContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

const CategoryImage = styled.img`
  padding: 5px;
  height: 40px;
`;

const DivCategory = styled.div<{ color: string }>`
  background-color: ${(prop) => prop.color};
  border-radius: 4px;
`;

const SpanCategory = styled.span`
  padding: 10px;
  font-size: 20px;
  color: white;
`;
//*******************

function DocumentStatusGrid() {
  //States
  const [selectedCategory, setSelectedCategory] = useState<Category>(
    {} as Category
  );
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedRowKeysState, setSelectedRowKeysState] = useState<any[]>([]);
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  //*******************
  const position = { of: "#main-container-wrapper" };

  const status = [
    {
      value: "Brouillon",
      text: "Brouillon",
    },
    {
      value: "Publié",
      text: "Publié",
    },
    {
      // name: "Archivé",
      text: "Archivé",
      value: "Archivé",
    },
  ];

  let grid: any;
  //*******************

  //Hooks
  const { categoryCode } = useParams();
  //*******************

  //Functions
  const getCategoriesFiles = () => {
    setLoadingData(true);

    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategoryFilesByStatus}?categoryCode=${categoryCode}`
      )
      .then((response) => {
        setCategories(response.data.payload.data);
        return {
          data: response.data.payload.data,
          totalCount: response.data.payload.totalCount,
        };
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const getCategoryByCode = () => {
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategoryByCode}?categoryCode=${categoryCode}`
      )
      .then((response) => {
        setSelectedCategory(response.data.payload);
        getCategoriesFiles();
        return response.data.payload;
      });
  };

  const showNotifications = (
    data: any,
    showOnSuccess: boolean,
    message: string
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t(message), {
          position: "top-right",
        });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const setUpgradeButtonStatus = (tab: any[]) => {
    //get selected item
    let firstObject = tab[0];
    if (tab.length > 0) {
      if (firstObject.lastDocumentStatus === "Archivé") {
        setDisableUpdateButton(true);
      } else {
        setDisableUpdateButton(false);
      }
    } else {
      setDisableUpdateButton(true);
    }
  };

  const onSelectionChanged = (e: any) => {
    //Job to do
    if (e.selectedRowsData.length > 1) {
      if (
        e.selectedRowsData[e.selectedRowsData.length - 1].lastDocumentStatus !==
        e.selectedRowsData[0].lastDocumentStatus
      ) {
        //Notif
        toast.error(t("AvoidDiffStatusDoc"), {
          position: "top-right",
        });
        //Deselect the row :
        e.component.deselectRows(
          e.selectedRowKeys[e.selectedRowKeys.length - 1]
        );
      } else {
        setSelectedRowKeysState(e.selectedRowsData);
        setUpgradeButtonStatus(e.selectedRowsData);
      }
    } else {
      setSelectedRowKeysState(e.selectedRowsData);
      setUpgradeButtonStatus(e.selectedRowsData);
      //call function to enabled/disable
    }
    // *******************
  };

  const setDocStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case "Brouillon":
        return "Publié";
      case "Publié":
        return "Archivé";
    }
  };

  const preparePayload = (p_status: string) => {
    //Get selected ids
    const ids = selectedRowKeysState.map((x: any) => x.id);
    //Calculate the payload
    let payload = {
      status: setDocStatus(p_status),
      documentLanguageIds: ids,
    };

    return payload;
  };

  const updgradeSelectedFiles = (e: any) => {
    let firstObject = selectedRowKeysState[0];
    let payload = preparePayload(firstObject.lastDocumentStatus);
    return jwtInterceptor
      .put(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.updateDocumentsStatus} `,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "SuccessUpdateStatusDoc");
        grid.instance.refresh(true);
        grid.instance.clearSelection();
        return response.data.payload;
      });
  };

  const clearSelection = (e: any) => {
    grid.instance.clearSelection();
    setDisableUpdateButton(true);
  };

  const refreshGrid = (e: any) => {
    getCategoriesFiles();
  };

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "arrowup",
          type: "success",
          onClick: updgradeSelectedFiles,
          disabled: disableUpdateButton,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          type: "success",
          elementAttr: {
            id: "refreshDocumentStatusDataGrid",
            class: "see-document-button",
          },
          onClick: refreshGrid,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "clearformat",
          type: "default",
          onClick: clearSelection,
        },
      }
    );
  };
  //*******************

  //Translation module
  const { t } = useTranslation();
  //*******************

  //UseEffect
  useEffect(() => {
    getCategoryByCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //*******************

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <DocumentGridContainer>
        <Row>
          <Col>
            <DivCategory color={selectedCategory.color}>
              <CategoryImage src={selectedCategory.base64Image} />
              <SpanCategory color={selectedCategory.color}>
                {t(
                  categoryCode?.charAt(0).toUpperCase() +
                    categoryCode?.slice(1)!
                )}
              </SpanCategory>
            </DivCategory>
          </Col>
        </Row>
        <Row className="pt-2">
          <LoadPanel
            visible={loadingData}
            showIndicator={true}
            shading={true}
            shadingColor="rgba(0,0,0,0.4)"
            showPane={false}
            position={position}
            message={t("LoadingData")!}
          />
          <Col className="d-flex">
            <DataGrid
              id="DocStatusDatagrid"
              keyExpr="id"
              dataSource={categories}
              focusedRowEnabled={true}
              autoNavigateToFocusedRow={false}
              showRowLines={true}
              noDataText={t("NoDataCategoryFiles")!}
              onToolbarPreparing={onToolbarPreparing}
              showBorders={true}
              rowAlternationEnabled={true}
              onSelectionChanged={onSelectionChanged}
              columnAutoWidth={true}
              ref={(ref) => {
                grid = ref;
              }}
            >
              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />
              <Selection
                mode="multiple"
                allowSelectAll={false}
                showCheckBoxesMode="onClick"
              />
              <Paging defaultPageSize={10} />
              <Pager
                visible={true}
                allowedPageSizes={[10, 20, 30]}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <HeaderFilter visible={true} />
              <Column
                dataField="childCategory"
                caption={t("CategoryName")!}
                visible={true}
                dataType="number"
                allowHeaderFiltering={false}
              />
              <Column
                dataField="lastDocumentReference"
                caption={t("PopupCategoriesReference")!}
                visible={true}
                dataType="string"
                allowHeaderFiltering={false}
              />
              <Column
                dataField="lastDocumentStatus"
                caption={t("PopupCategoriesStatus")!}
                visible={true}
                dataType="string"
              >
                <HeaderFilter dataSource={status} />
              </Column>
              <Column
                dataField="createdBy"
                caption={t("CreatedBy")!}
                dataType="string"
                allowHeaderFiltering={false}
              />
              <Column
                dataField="createdOn"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("CreatedOn")!}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="updatedBy"
                caption={t("UpdatedBy")!}
                dataType="string"
                allowHeaderFiltering={false}
              />
              <Column
                dataField="updatedOn"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("UpdatedOn")!}
                allowHeaderFiltering={false}
              />
            </DataGrid>
          </Col>
        </Row>
        <Row>
          <div className="selected-data">
            <span className="caption">
              {t("TotalSelectedFiles") +
                " (" +
                selectedRowKeysState.length +
                ") " +
                ": "}{" "}
            </span>

            {selectedRowKeysState.length
              ? selectedRowKeysState.map((a: any) => (
                  <span className="p-1">
                    <Badge pill bg="info">
                      {a.lastDocumentReference}
                    </Badge>
                  </span>
                ))
              : null}
          </div>
        </Row>
      </DocumentGridContainer>
    </ScrollView>
  );
}

export default DocumentStatusGrid;
