import { Accordion } from "react-bootstrap";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import SidebarItem from "../SidebarItem";
import { useTranslation } from "react-i18next";
import {
  getLocalStorageItems,
  loggedUser,
} from "../../utils/functions/functionsUtils";
import { AccordionSelectCallback } from "react-bootstrap/esm/AccordionContext";

//Styled components
//Sidebar Wrapper
const SidebarWrapper = styled.div<{ open: boolean }>`
  top: 15%;
  left: 0;
  width: ${(prop) => (prop.open ? "250" : "90px")};
  transform: translateY(-50%);
  transition: width 1s;
`;

//Link
const SidebarLink = styled(Link)`
  text-decoration: none;
  color: black;
`;
const MenuItemNoArrow = styled(Accordion.Item)`
  .accordion-button::after {
    display: none !important;
  }

  .accordion-button:hover {
    background-color: #cccccc;
  }
`;

const MenuItemArrow = styled(Accordion.Item)`
  .accordion-button:hover {
    background-color: #cccccc;
  }

  span {
    display: block;
  }

  a {
    display: flex;
    flex: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    span {
      flex: 1;
    }
  }
`;

const SidebarAccordion = styled(Accordion)<{ open: boolean }>`
  position: fixed;
  width: ${(prop) => (prop.open ? "250" : "90px")};
`;

const MenuIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  margin-left: 10px;
`;

function Sidebar({ handleFullScreen }: any) {
  //Sidebar toggling state
  const [isOpen, setIsOpen] = useState(true);
  const [activeIndex, setactiveIndex] = useState<undefined | string>(undefined);
  const showSidebar = () => {
    setIsOpen(!isOpen);
    handleFullScreen(!isOpen);
  };
  const location = useLocation();
  const { pathname } = location;

  //Translation module
  const { t } = useTranslation();
  //*******************

  //Handle local storage
  const menuItems = getLocalStorageItems("menus", true);
  const loginString = loggedUser();

  const mainItems =
    Object.keys(menuItems).length > 0 &&
    menuItems.filter(
      ({ href, parentMenuId }: any) =>
        (href === "#" && parentMenuId === 0) || parentMenuId === 0
    );
  useEffect(() => {
    if (
      pathname.toLowerCase() === "/dashboard" ||
      pathname.toLowerCase() === "/services"
    ) {
      setactiveIndex("0");
    }
    if (
      pathname.toLowerCase() === "/agreements" ||
      pathname.toLowerCase() === "/legislations" ||
      pathname.toLowerCase() === "/obligations" ||
      pathname.toLowerCase() === "/models" ||
      pathname.toLowerCase() === "/jurisprudences" ||
      pathname.toLowerCase() === "/publications"
    ) {
      setactiveIndex("1");
    }

    if (
      pathname.toLowerCase() === "/internal" ||
      pathname.toLowerCase() === "/categories"
    ) {
      setactiveIndex("2");
    }

    if (
      pathname.toLowerCase() === "/usermanagement" ||
      pathname.toLowerCase() === "/subscriptions" ||
      pathname.toLowerCase() === "/subscriptionsmodification" ||
      pathname.toLowerCase() === "/subscriptionsdeletion" ||
      pathname.toLowerCase() === "/adminscreen"
    ) {
      setactiveIndex("3");
    }

    if (
      pathname.toLowerCase() === "/userslegislations" ||
      pathname.toLowerCase() === "/usersagreements" ||
      pathname.toLowerCase() === "/usersobligations" ||
      pathname.toLowerCase() === "/usersmodels" ||
      pathname.toLowerCase() === "/usersjurisprudences" ||
      pathname.toLowerCase() === "/userspublications" ||
      pathname.toLowerCase() === "/allCategories"
    ) {
      setactiveIndex("4");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return Object.keys(menuItems).length <= 0 ? null : (
    <>
      <SidebarWrapper open={isOpen} className="transform">
        {loginString ? (
          <MenuIcon
            icon={["fas", isOpen ? "times" : "bars"]}
            onClick={showSidebar}
          />
        ) : null}
        <div className="side-menu">
          <SidebarAccordion
            open={isOpen}
            activeKey={activeIndex}
            onSelect={(e: AccordionSelectCallback) => {
              if (e) setactiveIndex(e?.toString());
            }}
            key={
              `accordion-${Object.keys(mainItems).toString()}` +
              Math.random().toString(36).substring(7)
            }
          >
            {mainItems &&
              mainItems.map((item: any, key: number) =>
                item.href === "#" ? (
                  <MenuItemArrow
                    eventKey={key.toString()}
                    key={
                      `${key}-${item.id}-${item.translationKey}` +
                      Math.random().toString(36).substring(7)
                    }
                  >
                    <Accordion.Header
                      onClick={() => {
                        if (activeIndex === key.toString()) {
                          setactiveIndex(undefined);
                        } else {
                          setactiveIndex(key.toString());
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={["fas", item.iconName]} />
                      <span className="ms-3">
                        {isOpen ? t(item.translationKey) : ""}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {menuItems &&
                        menuItems.map((subItem: any, key_i: any) =>
                          subItem.parentMenuId === item.id ? (
                            <SidebarItem
                              key={
                                `sidebar - subItem - ${key_i} -${subItem.id}-` +
                                Math.random().toString(36).substring(7)
                              }
                              subItem={subItem}
                              isOpen={isOpen}
                              onClick={() => {
                                setactiveIndex(key.toString());
                              }}
                            >
                              {isOpen ? subItem.translationKey : ""}
                            </SidebarItem>
                          ) : null
                        )}
                    </Accordion.Body>
                  </MenuItemArrow>
                ) : (
                  <>
                    <MenuItemNoArrow
                      eventKey={key.toString()}
                      key={
                        `${key}-${item.id}-${item.translationKey}` +
                        Math.random().toString(36).substring(7)
                      }
                    >
                      <Accordion.Header>
                        <SidebarLink to={item.href}>
                          <FontAwesomeIcon icon={["fas", item.iconName]} />
                          <span className="ms-3">
                            {isOpen ? t(item.translationKey) : ""}
                          </span>
                        </SidebarLink>
                      </Accordion.Header>
                    </MenuItemNoArrow>
                  </>
                )
              )}
          </SidebarAccordion>
        </div>
      </SidebarWrapper>
    </>
  );
}

export default Sidebar;
