import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import { Category } from "../../models/category";
import { useTranslation } from "react-i18next";
import ColorPicker from "../../components/ColorPicker";
import api_keys from "../../utils/constants/api_keys.json";
import { custom } from "devextreme/ui/dialog";
import { toast } from "react-toastify";
import {
  TreeList,
  Column,
  SearchPanel,
  Editing,
  RowDragging,
  Texts,
  Button,
  Popup as PopUpTreeList,
  Form,
} from "devextreme-react/tree-list";
import { LoadPanel } from "devextreme-react/load-panel";
import "whatwg-fetch";
import { Item } from "devextreme-react/form";
import { ToolbarItem } from "devextreme-react/popup";
import {
  loggedUser,
  isUserAdmin,
  isUserEditor,
} from "../../utils/functions/functionsUtils";
import { UserInfo } from "../../models/user";
import { getLocalStorageItems } from "../../utils/functions/functionsUtils";
import { ScrollView } from "devextreme-react";

const MyProfileContainer = styled.div`
  width: 100%;
  padding: 20px 10px 20px 0px;
`;

const SpanTitle = styled.span`
  font-size: 20px;
  color: #141b4d;
`;

interface IDragDropPayload {
  idItem: number;
  sourceCategoryCode: string;
  targetCategoryCode: string;
  sourceFileName: string;
  newParentId: number;
  newOrder: number;
  updatedBy: string;
}

function Categories() {
  const [currentCategories, setCurrentCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category>(
    {} as Category
  );
  const [expandedRowKeys] = useState([1]);
  const [focusedRowKeys, setFocusedRowKeys] = useState(1);
  const [changeColorDisabled, setChangeColorDisabled] = useState(true);
  const [loadingTreeList, setLoadingTreeList] = useState(false);
  const [userDetails, setUserDetails] = useState<UserInfo>({} as UserInfo);
  const i18nextLng = getLocalStorageItems("i18nextLng", false);
  const [currentLang, setcurrentLang] = useState<string | undefined>(
    i18nextLng
  );
  //Translation module
  const { t, i18n } = useTranslation();
  //*******************

  i18n.on("languageChanged", (data) => {
    setcurrentLang(data);
  });
  let treeView: any;
  let previousNode: any;

  const saveButtonOptions = {
    text: t("PopupSave"),
    icon: "save",
    elementAttr: {
      class: "see-document-button",
    },
    type: "success",
    onClick: (e: any) => {
      treeView.instance.saveEditData();
    },
  };

  const cancelButtonOptions = {
    text: t("PopupCancel"),
    icon: "close",
    type: "danger",
    elementAttr: {
      class: "see-document-button",
    },
    onClick: (e: any) => {
      treeView.instance.cancelEditData();
    },
  };

  const readOnlyOptions = {
    readOnly: true,
  };

  const hrefOptions = {
    readOnly: !isUserAdmin(),
  };

  const colorPickerOptions = {
    disabled: changeColorDisabled,
    defaultValue: "#fcfcfc",
  };

  const onFocusedRowChanged = (e: any) => {
    previousNode = null;
    const rootParentNode = getRootParentNode(e.row.data.id);
    e.row.data.code = rootParentNode.code;
    setChangeColorDisabled(e.row.data.parentId > 0);

    e.row.data.isPopupOpen = true;

    setSelectedCategory(e.row.data);
    setFocusedRowKeys(e.row.data.id);
  };

  const onDragChange = (e: any) => {
    let visibleRows = e.component.getVisibleRows(),
      sourceNode = e.component.getNodeByKey(e.itemData.id),
      targetNode = visibleRows[e.toIndex].node;

    while (targetNode && targetNode.data) {
      if (targetNode.data.id === sourceNode.data.id) {
        e.cancel = true;
        break;
      }
      targetNode = targetNode.parent;
    }
  };

  const onDragEnd = (e: any) => {
    let visibleRows = e.component.getVisibleRows(),
      sourceNode = e.component.getNodeByKey(e.itemData.id),
      targetNode = visibleRows[e.toIndex].node;

    const payload: IDragDropPayload = {
      idItem: sourceNode.data.id,
      sourceCategoryCode:
        sourceNode.data.code == null
          ? selectedCategory.code
          : sourceNode.data.code,
      targetCategoryCode: targetNode.data.code,
      sourceFileName: sourceNode.data.blobFileName,
      newParentId: targetNode.parent?.data?.id || 0,
      updatedBy: userDetails?.userName,
      newOrder: targetNode.data.order,
    };
    if (sourceNode.data.id === targetNode.data.id) return;
    setLoadingTreeList(true);
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.dragAndDropCategory}`,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesUpdate");
        GetCategories();
      });
  };

  const onReorder = (e: any) => {
    let visibleRows = e.component.getVisibleRows(),
      sourceData = e.itemData,
      targetData = visibleRows[e.toIndex].data,
      categoriesReordered = currentCategories,
      sourceIndex = categoriesReordered.indexOf(sourceData),
      targetIndex = categoriesReordered.indexOf(targetData);
    if (e.dropInsideItem) {
      sourceData = { ...sourceData, parentId: targetData.id };
      categoriesReordered = [
        ...categoriesReordered.slice(0, sourceIndex),
        sourceData,
        ...categoriesReordered.slice(sourceIndex + 1),
      ];
    } else {
      if (sourceData.parentId !== targetData.parentId) {
        sourceData = { ...sourceData, parentId: targetData.parentId };
        if (e.toIndex > e.fromIndex) {
          targetIndex++;
        }
      }
      categoriesReordered = [
        ...categoriesReordered.slice(0, sourceIndex),
        ...categoriesReordered.slice(sourceIndex + 1),
      ];
      categoriesReordered = [
        ...categoriesReordered.slice(0, targetIndex),
        sourceData,
        ...categoriesReordered.slice(targetIndex),
      ];
    }

    setCurrentCategories(categoriesReordered);
  };

  useEffect(() => {
    GetCategories();
    setUserDetails(loggedUser());

    return () => {
      i18n.off("languageChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetCategories = () => {
    setLoadingTreeList(true);
    return jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategories}`
      )
      .then((response) => {
        showNotifications(response.data, false, "CategoriesGet");
        const currentCategories = response.data.payload;

        setCurrentCategories(currentCategories);
        setLoadingTreeList(false);
      });
  };

  const showNotifications = (
    data: any,
    showOnSuccess: boolean,
    message: string
  ) => {
    //Notifications
    if (data.statusCode === 200) {
      if (showOnSuccess)
        toast.success(t(message), {
          position: "top-right",
        });
    } else {
      toast.error(data.message, { position: "top-right" });
    }
  };

  const onRowInserted = (options: any) => {
    setLoadingTreeList(true);
    const payload: Category = options.data;
    payload.id = 0;
    payload.color = payload.parentId > 0 ? "" : payload.color;
    payload.createdBy = userDetails?.userName;
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.createCategories}`,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesCreate");
        GetCategories();
      });
  };

  const onRowRemoved = (options: any) => {
    setLoadingTreeList(true);
    const payload: Category = options.data;
    return jwtInterceptor
      .post(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.deleteCategories}?categoryId=${payload.id}`,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesRemove");
        GetCategories();
      });
  };

  const onRowUpdated = (options: any) => {
    setLoadingTreeList(true);
    const payload: Category = options.data;
    payload.color = payload.parentId > 0 ? "" : payload.color;
    payload.updatedBy = userDetails?.userName;

    return jwtInterceptor
      .put(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.editCategories}`,
        payload
      )
      .then((response) => {
        showNotifications(response.data, true, "CategoriesUpdate");
        GetCategories();
      });
  };

  const onCellPrepared = (e: any) => {
    if (e.rowType === "data") {
      //e.cellElement.style.color = e.data.color;
      e.cellElement.style.verticalAlign = "middle";
    }
  };

  const getRootParentNode = (id: number): any => {
    const node = currentCategories.filter((e) => e.id === id);
    if (node.length === 0) {
      return previousNode;
    }
    previousNode = node[0];
    if (node[0].parentId > 0) {
      getRootParentNode(node[0].parentId);
    }
    return previousNode;
  };

  const isDeletionRequestOptionVisible = (e: any) => {
    return isUserEditor();
  };

  const isDeletionRequestOptionDisabled = (e: any) => {
    return e.row.data.isDeletionRequested === 1;
  };

  const onDeletionRequest = (options: any) => {
    const payload = options.row.data;
    payload.entity = "Category";
    payload.entityId = options.row.data.id;
    payload.createdBy = loggedUser().userName;
    payload.description = `User : ${
      loggedUser().userName
    } has requested the deletion for Category Name : ${options.row.data.name}`;

    let confirmDialog = custom({
      title: `${t("HintRequestDeletion")} : ${options.row.data.name} ?`,
      messageHtml: `<b>${t("AskDeleteRequestCat")}</b>`,
      buttons: [
        {
          text: t("Confirm")!,
          icon: "check",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("Cancel")!,
          icon: "close",
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    confirmDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === t("Confirm")) {
        return jwtInterceptor
          .post(
            `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.createDeletionRequest}`,
            payload
          )
          .then((response) => {
            showNotifications(response.data, true, "CategoryRequestDeletion");
            GetCategories();
            return response.data.payload;
          });
      }
    });
  };

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <MyProfileContainer>
        <Row>
          <Col className="d-flex">
            <SpanTitle>{t("Categories")}</SpanTitle>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <LoadPanel
              visible={loadingTreeList}
              showIndicator={true}
              shading={true}
              showPane={false}
            />
            <TreeList
              id="categories"
              dataSource={currentCategories}
              showRowLines={true}
              showBorders={true}
              columnAutoWidth={true}
              keyExpr="id"
              parentIdExpr="parentId"
              defaultExpandedRowKeys={expandedRowKeys}
              autoExpandAll={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              noDataText={t("NoCategory")!}
              onFocusedRowChanged={onFocusedRowChanged}
              focusedRowKey={focusedRowKeys}
              onRowInserted={onRowInserted}
              onRowUpdated={onRowUpdated}
              onRowRemoved={onRowRemoved}
              onCellPrepared={onCellPrepared}
              ref={(ref) => {
                treeView = ref;
              }}
            >
              <SearchPanel
                visible={true}
                width={240}
                placeholder={t("Search")!}
              />
              <Editing
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={true}
                useIcons={true}
                mode="popup"
              >
                <Texts confirmDeleteMessage={t("PopupDeleteMessage")!} />

                <PopUpTreeList
                  title="User Information"
                  showTitle={false}
                  width={850}
                  height={600}
                >
                  <ToolbarItem
                    toolbar="bottom"
                    location="after"
                    widget="dxButton"
                    options={saveButtonOptions}
                  />
                  <ToolbarItem
                    toolbar="bottom"
                    location="after"
                    widget="dxButton"
                    options={cancelButtonOptions}
                  />
                </PopUpTreeList>
                <Form>
                  <Item
                    itemType="group"
                    caption={t("CategoriesPagePopupText")!}
                    colCount={2}
                    colSpan={2}
                  >
                    <Item caption={t("CategoryName")!} itemType="group">
                      <Item
                        dataField="name"
                        icon="pen"
                        label={{ showColon: false, text: t("fr")! }}
                      />
                      <Item
                        dataField="nameEn"
                        icon="pen"
                        label={{ showColon: false, text: t("en")! }}
                      />
                      <Item
                        dataField="nameDe"
                        icon="pen"
                        label={{ showColon: false, text: t("de")! }}
                      />
                    </Item>
                    <Item itemType="group">
                      <Item
                        dataField="color"
                        editorType="dxColorBox"
                        editorOptions={colorPickerOptions}
                      ></Item>
                      <Item dataField="base64Image" />
                    </Item>
                  </Item>
                  <Item
                    itemType="group"
                    caption={t("UserinfoAdmin")!}
                    colCount={2}
                    colSpan={2}
                  >
                    <Item dataField="id" editorOptions={readOnlyOptions}></Item>
                    <Item
                      dataField="parentId"
                      editorOptions={readOnlyOptions}
                    ></Item>
                    <Item
                      dataField="code"
                      editorOptions={readOnlyOptions}
                    ></Item>
                    <Item dataField="href" editorOptions={hrefOptions}></Item>
                    <Item
                      dataField="blobFileName"
                      editorOptions={readOnlyOptions}
                    ></Item>
                    <Item
                      dataField="blobFilePath"
                      editorOptions={readOnlyOptions}
                    ></Item>
                    <Item
                      dataField="createdOn"
                      editorOptions={readOnlyOptions}
                    ></Item>
                    <Item
                      dataField="createdBy"
                      editorOptions={readOnlyOptions}
                    ></Item>
                    <Item
                      dataField="updatedOn"
                      editorOptions={readOnlyOptions}
                    ></Item>
                    <Item
                      dataField="updatedBy"
                      editorOptions={readOnlyOptions}
                    ></Item>
                  </Item>
                </Form>
              </Editing>

              <RowDragging
                onDragChange={onDragChange}
                onDragEnd={onDragEnd}
                onReorder={onReorder}
                allowDropInsideItem={false}
                allowReordering={true}
                showDragIcons={true}
                dropFeedbackMode={"push"}
              />

              <Column dataField="id" visible={false} />
              <Column dataField="parentId" visible={false} />
              <Column dataField="revision" visible={false} />
              <Column
                dataField="name"
                caption={t("CategoryName")!}
                visible={currentLang === "FR"}
              />
              <Column
                dataField="nameDe"
                caption={t("CategoryName")!}
                visible={currentLang === "DE"}
              />
              <Column
                dataField="nameEn"
                caption={t("CategoryName")!}
                visible={currentLang === "GB"}
              />
              <Column dataField="order" caption={t("order")!} />
              <Column
                dataField="code"
                caption="Blob Container"
                visible={false}
              />
              <Column
                dataField="color"
                width={170}
                alignment={"center"}
                caption={t("CategoryColor")!}
                allowHeaderFiltering={false}
                cellRender={(data) => <ColorPicker data={data} />}
              ></Column>
              <Column
                dataField="href"
                caption={t("CategoryHREF")!}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="blobFileName"
                caption={t("CategoryFileName")!}
                allowHeaderFiltering={false}
                visible={false}
              />
              <Column
                dataField="blobFilePath"
                caption={t("CategoryFilePath")!}
                visible={false}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="base64Image"
                caption={t("CategoryBase64File")!}
                width={200}
                visible={true}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="createdBy"
                caption={t("CreatedBy")!}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="createdOn"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("CreatedOn")!}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="updatedBy"
                caption={t("UpdatedBy")!}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="updatedOn"
                dataType="date"
                format="dd/MM/yyyy"
                caption={t("UpdatedOn")!}
                allowHeaderFiltering={false}
              />
              <Column type="buttons" caption={t("Actions")!} width={150}>
                <Button name="edit" hint={t("HintEdit")!} />
                <Button
                  name="delete"
                  hint={t("HintDelete")!}
                  visible={isUserAdmin()}
                />
                <Button
                  hint={t("HintRequestDeletion")!}
                  icon="deleterow"
                  onClick={onDeletionRequest}
                  visible={isDeletionRequestOptionVisible}
                  disabled={isDeletionRequestOptionDisabled}
                />
                <Button name="add" hint={t("HintAdd")!} />
              </Column>
            </TreeList>
          </Col>
        </Row>
      </MyProfileContainer>
    </ScrollView>
  );
}

export default Categories;
