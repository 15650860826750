import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ServicesCard from "../../components/ServicesCard";
import jwtInterceptor from "../../components/shared/jwtInterceptor";
import api_keys from "../../utils/constants/api_keys.json";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  loggedUser,
  isUserAdmin,
  isUserEditor,
  getLocalStorageItems,
} from "../../utils/functions/functionsUtils";
import { ScrollView } from "devextreme-react";

const MyProfileContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

function Services() {
  const [categories, setcategories] = useState([]);
  //Translation module
  const { t, i18n } = useTranslation();
  //*******************

  const i18nextLng = getLocalStorageItems("i18nextLng", false);
  const [currentLang, setcurrentLang] = useState<string | undefined>(
    i18nextLng
  );

  i18n.on("languageChanged", (data) => {
    setcurrentLang(data);
  });

  const getRightCategoryName = (
    frName: string,
    deName: string,
    enName: string
  ) => {
    switch (currentLang) {
      case "FR":
        return frName;
      case "GB":
        return enName;

      default:
        return deName;
    }
  };

  const GetCategories = () => {
    jwtInterceptor
      .get(
        `${api_keys.apiManagement_URL}${api_keys.apiManagementAPIName_URL}${api_keys.getCategories}`
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (isUserAdmin() || isUserEditor()) {
            toast.success(t(response.data.message), {
              position: "top-right",
            });
          }
        } else {
          toast.error(response.data.message, {
            position: "top-right",
          });
        }
        setcategories(
          response.data.payload.filter(function (x: any) {
            return x.parentId <= 0;
          })
        );
      });
  };

  useEffect(() => {
    GetCategories();

    return () => {
      i18n.off("languageChanged");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollView height={window.innerHeight - 150} direction="vertical">
      <MyProfileContainer>
        <Row>
          {categories &&
            categories.map((item: any, key: any) => (
              // <Col key={key} className="col-4">
              <Col key={key} sm={12} md={12} lg={6} xl={4} xxl={4}>
                <ServicesCard
                  title={getRightCategoryName(
                    item.name,
                    item.nameDe,
                    item.nameEn
                  )}
                  href={
                    loggedUser()?.role === "User"
                      ? `Users${item.href.slice(1)}`
                      : item.href
                  }
                  color={item.color}
                  base64Image={item.base64Image}
                />
              </Col>
            ))}
        </Row>
      </MyProfileContainer>
    </ScrollView>
  );
}

export default Services;
